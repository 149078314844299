import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Divider, Grid, Icon, Image, Layer, RadioButtons, DatePicker, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Spacer, Text, TextArea, TextField, Underline } from './Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Map } from './Components'
import { FiMenu } from 'react-icons/fi'
import { c_businessName, c_routes } from '../Constants'
import { useLocation, useNavigate } from 'react-router'

export default function Overview() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    useEffect(() => {
        window.scrollTo(0, 0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                <Heading1 text={`Overview`} classes={``} />

                <Grid orientation="column" template="1fr" gap="2em" classes={``}>
                    {
                        c_routes.map((page, i) => {
                            if (page.Route != "test") {
                                return (
                                    <Box width="" height="" radius="10px" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                                        <Box width="" height="" radius="" backgroundColor="#818AFA" paddingV="0.8em" paddingH="" id="" classes={`all-caps`}>
                                            <Text text={`${page.Name}`} fontSize="" weight="500" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                        <iframe style={{ width: "100%", height: "90vh", border: "2px solid #818AFA" }} src={`/${page.Route}`}>
                                        </iframe>
                                    </Box>
                                )
                            }
                        })
                    }
                </Grid>
            </Box>
        </div>
    )
}