import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'
import { c_routes } from './Constants';
import Overview from './COMPONENTS/Overview';
// 

function App() {

  useEffect(() => {
  }, [])



  return (
    <div className=''>
      <BrowserRouter>
        {/* THOSE THAT WILL RUN NO MATTER WHAT */}

        <Routes>
          {
            c_routes.map((page, i) => {
              return (
                <Route exact path={`/${page.Route}`} element={page.Element} key={i} />
              )
            })
          }

          <Route exact path='/overview' element={<Overview />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
