import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Text, TextArea, TextField, Underline, Spacer } from '../../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '../../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessDesc, c_businessName, c_footerLeftRoutes, c_footerRightRoutes, c_routes, c_textFont } from '../../../Constants'
import { useLocation, useNavigate } from 'react-router'

export default function Component() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    const leftHeading = "Links"
    const rightHeading = "More"

    useEffect(() => {
        // closeNav()
    }, [])

    return (
        <div className='roboto dark'>
            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                        <Text text={`${c_businessDesc}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                        <Grid orientation="row" template="1fr" gap="1.5em" classes="">
                            <div>
                                <Spacer height={`4vh`} />
                                <Text text={`${leftHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </div>
                            <div>
                                {
                                    c_footerLeftRoutes.map((route, i) => {
                                        return (
                                            <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                <Underline size="1px" color="" >
                                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                        <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                    </Button>
                                                </Underline>
                                            </Box>
                                        )
                                    })
                                }
                            </div>

                            <div>
                                <Spacer height={`4vh`} />
                                <Text text={`${rightHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </div>
                            <div>
                                {
                                    c_footerRightRoutes.map((route, i) => {
                                        return (
                                            <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                <Underline size="1px" color="" >
                                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                        <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                    </Button>
                                                </Underline>
                                            </Box>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                        <Spacer height={`5vh`} />
                        <Text text={`${c_businessName}`} fontSize="10vh" weight="900" color="" spacing="" lineHeight="1.2em" classes={`all-caps ${c_accentFont} `} />

                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                        <Box width="80vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Text text={`${c_businessDesc}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                        </Box>
                        <Grid orientation="row" template="1fr" gap="1.5em" classes="">
                            <div>
                                <Spacer height={`4vh`} />
                                <Text text={`${leftHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </div>
                            <div>
                                {
                                    c_footerLeftRoutes.map((route, i) => {
                                        return (
                                            <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                <Underline size="1px" color="" >
                                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                        <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                    </Button>
                                                </Underline>
                                            </Box>
                                        )
                                    })
                                }
                            </div>

                            <div>
                                <Spacer height={`4vh`} />
                                <Text text={`${rightHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </div>
                            <div>
                                {
                                    c_footerRightRoutes.map((route, i) => {
                                        return (
                                            <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                <Underline size="1px" color="" >
                                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                        <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                    </Button>
                                                </Underline>
                                            </Box>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                        <Spacer height={`5vh`} />
                        <Text text={`${c_businessName}`} fontSize="10vh" weight="900" color="" spacing="" lineHeight="1.2em" classes={`all-caps ${c_accentFont} `} />

                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                        <Grid orientation="column" template="2fr 1fr 1fr" gap="2em" classes="">
                            <Text text={`${c_businessDesc}`} fontSize="1.1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            <Grid orientation="row" template="1fr" gap="1em" classes="">
                                <Text text={`${leftHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <div>
                                    {
                                        c_footerLeftRoutes.map((route, i) => {
                                            return (
                                                <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                    <Underline size="1px" color="" >
                                                        <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                            <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                        </Button>
                                                    </Underline>
                                                </Box>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                            <Grid orientation="row" template="1fr" gap="" classes="">
                                <Text text={`${rightHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <div>
                                    {
                                        c_footerRightRoutes.map((route, i) => {
                                            return (
                                                <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                    <Underline size="1px" color="" >
                                                        <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                            <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                        </Button>
                                                    </Underline>
                                                </Box>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <Spacer height={`5vh`} />
                        <Text text={`${c_businessName}`} fontSize="10vh" weight="900" color="" spacing="" lineHeight="1.2em" classes={`all-caps  ${c_accentFont} `} />
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                        <Grid orientation="column" template="2fr 1fr 1fr" gap="2em" classes="">
                            <Box width="75%" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`${c_businessDesc}`} fontSize="1.1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                            </Box>
                            <Grid orientation="row" template="1fr" gap="1em" classes="">
                                <Text text={`${leftHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <div>
                                    {
                                        c_footerLeftRoutes.map((route, i) => {
                                            return (
                                                <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                    <Underline size="1px" color="" >
                                                        <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                            <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                        </Button>
                                                    </Underline>
                                                </Box>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                            <Grid orientation="row" template="1fr" gap="" classes="">
                                <Text text={`${rightHeading}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <div>
                                    {
                                        c_footerRightRoutes.map((route, i) => {
                                            return (
                                                <Box width="fit-content" height="" radius="" paddingV="" paddingH="" classes="">
                                                    <Underline size="1px" color="" >
                                                        <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${route.Route}`) }} classes="">
                                                            <Text text={`${route.Name}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                        </Button>
                                                    </Underline>
                                                </Box>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Box width="" height="5vh" radius="" paddingV="" paddingH="" classes="">

                        </Box>
                        <Text text={`${c_businessName}`} fontSize="12vh" weight="900" color="" spacing="" lineHeight="1.2em" classes={`${c_accentFont} all-caps `} />

                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>
            <Spacer height={`6vh`} />
        </div>
    )
}