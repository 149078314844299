// #region IMPORTS
import { Box, Image, Spacer, Text } from "./COMPONENTS/Elements"
import blog1img1 from './PHOTOS/stock1.jpg'
import blog2img1 from './PHOTOS/stock3.jpg'
import blog3img1 from './PHOTOS/stock5.jpg'
// 
import couponImg1 from './PHOTOS/stock1.jpg'
// 
import eventsImg1 from './PHOTOS/stock1.jpg'
// 
import galleryImg1 from './PHOTOS/stock1.jpg'
import galleryImg2 from './PHOTOS/stock2.jpg'
import galleryImg3 from './PHOTOS/stock3.jpg'
import galleryImg4 from './PHOTOS/stock4.jpg'
import galleryImg5 from './PHOTOS/stock5.jpg'
import galleryImg6 from './PHOTOS/stock6.jpg'
import galleryImg7 from './PHOTOS/stock7.jpg'
import galleryImg8 from './PHOTOS/stock8.jpg'
import galleryImg9 from './PHOTOS/stock9.jpg'
import galleryImg10 from './PHOTOS/stock10.jpg'
// 
import partnerImg1 from './PHOTOS/stock1.jpg'
// 
import portfolioImg1 from './PHOTOS/stock1.jpg'
// 
import productsImg1 from './PHOTOS/PRODUCTS/product1.jpg'
import productsImg2 from './PHOTOS/PRODUCTS/product2.jpg'
import productsImg3 from './PHOTOS/PRODUCTS/product3.jpg'
import productsImg4 from './PHOTOS/PRODUCTS/product4.jpg'
import productsImg5 from './PHOTOS/PRODUCTS/product5.jpg'
import productsImg6 from './PHOTOS/PRODUCTS/product6.jpg'
import productsImg7 from './PHOTOS/PRODUCTS/product7.jpg'
import productsImg8 from './PHOTOS/PRODUCTS/product8.jpg'
import productsImg9 from './PHOTOS/PRODUCTS/product9.jpg'
import productsImg10 from './PHOTOS/PRODUCTS/product10.jpg'
import productsImg11 from './PHOTOS/PRODUCTS/product11.jpg'
// 
import servicesImg1 from './PHOTOS/stock1.jpg'
import servicesImg2 from './PHOTOS/stock2.jpg'
import servicesImg3 from './PHOTOS/stock3.jpg'
import servicesImg4 from './PHOTOS/stock4.jpg'
import servicesImg5 from './PHOTOS/stock5.jpg'
import servicesImg6 from './PHOTOS/stock6.jpg'
// 
import teamImg1 from './PHOTOS/stock1.jpg'
import teamImg2 from './PHOTOS/stock2.jpg'
import teamImg3 from './PHOTOS/stock3.jpg'
import teamImg4 from './PHOTOS/stock4.jpg'
// #endregion

// GLOBALS
const c_businessName = "Jalisco Cafe"
const c_businessType = "Jalisco"
const c_textFont = "roboto"

// #region DECLARATIONS
// HOME 1
export var home1_heading1 = ""
export var home1_caption1 = ""
export var home1_buttonText1 = ""
// HOME 2
export var home2_caption = ""
export var home2_heading1 = ""
export var home2_text1 = ""
export var home2_buttonText1 = ""
export var home2_buttonNav1 = ""
export var home2_heading2 = ""
export var home2_text2 = ""
export var home2_buttonText2 = ""
export var home2_buttonNav2 = ""
// HOME 3
export var home3_heading1 = ""
export var home3_caption1 = ""
export var home3_buttonText1 = ""
export var home3_buttonNav1 = ""
export var home3_subBlocks = []
// HOME 4
export var home4_heading1 = ""
export var home4_caption1 = ""
export var home4_buttonText1 = ""
export var home4_buttonNav1 = ""
export var home4_heading2 = ""
export var home4_caption2 = ""
export var home4_subBlocks = []
// HOME 5
export var home5_heading1 = ""
export var home5_caption1 = ""
export var home5_buttonText1 = ""
export var home5_buttonNav1 = ""
export var home5_heading2 = ""
export var home5_caption2 = ""
export var home5_subBlocks = []
// ABOUT 1
export var about1_pageTitle = ""
export var about1_heading1 = ""
export var about1_text1 = ""
export var about1_heading2 = ""
export var about1_text2 = ""
// AWARDS 1
export var awards1_pageTitle = ""
export var awards1_caption = ""
export var awards1_awards = []
// BIO 1
export var bio1_pageTitle = ""
export var bio1_heading1 = ""
export var bio1_text1 = ""
export var bio1_heading2 = ""
export var bio1_text2 = ""
export var bio1_heading3 = ""
export var bio1_text3 = ""
// BLOG 1
export var blog1_pageTitle = ""
export var blog1_blogs = []
// CAREERS 1
export var careers1_pageTitle = ""
export var careers1_caption1 = ""
export var careers1_positions = []
// CONTACT 1
export var contact1_pageTitle = ""
export var contact1_mapSearchString = ""
// COUPONS 1
export var coupons1_pageTitle = ""
export var coupons1_caption = ""
export var coupons1_coupons = []
// EVENTS 1
export var events1_pageTitle = ""
export var events1_events = []
// FEATURES 1
export var features1_pageTitle = ""
export var features1_features = []
// GALLERY 1
export var gallery1_pageTitle = ""
export var gallery1_images = []
// HISTORY 1
export var history1_pageTitle = ""
export var history1_caption = ""
export var history1_heading1 = ""
export var history1_text1 = ""
export var history1_heading2 = ""
export var history1_text2 = ""
// LOCATIONS 1
export var locations1_pageTitle = ""
export var locations1_locations = []
// MAILING LIST 1
export var mailingList1_pageTitle = ""
export var mailingList1_caption = ""
// MENU 1
export var menu1_pageTitle = ""
export var menu1_menu = []
// PARTNERS 1
export var partners1_pageTitle = ""
export var partners1_caption = ""
export var partners1_partners = []
// PORTFOLIO 1
export var portfolio1_pageTitle = ""
export var portfolio1_caption = ""
export var portfolio1_projects = []
// PRICING 1
export var pricing1_pageTitle = ""
export var pricing1_caption = ""
export var pricing1_prices = []
// PRODUCTS 1
export var products1_pageTitle = ""
export var products1_caption = ""
export var products1_products = []
// QUOTE 1
export var quote1_pageTitle = ""
export var quote1_caption = ""
export var quote1_heading1 = ""
export var quote1_heading2 = ""
export var quote1_services = []
// REVIEWS 1
export var reviews1_pageTitle = ""
export var reviews1_reviews = []
// SCHEDULE 1
export var schedule1_pageTitle = ""
export var schedule1_heading1 = ""
export var schedule1_heading2 = ""
export var schedule1_heading3 = ""
export var schedule1_buttonText = ""
export var schedule1_formHeading = ""
export var schedule1_formCaption = ""
// SERVICES 1
export var services1_pageTitle = ""
export var services1_caption = ""
export var services1_services = []
// TEAM 1
export var team1_pageTitle = ""
export var team1_caption = ""
export var team1_teamMembers = []
// #endregion

if (c_businessType == "Barbershop") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Look with Premier Haircuts and Shaving in San Diego. Book Now!`
    home1_caption1 = `From precise scissor work to rejuvenating hot towel shaves, our barbers provide an oasis of relaxation and style, ensuring you leave not just well-groomed, but revitalized.`
    home1_buttonText1 = `Book Now`
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Look with Premier Haircuts and Shaving in San Diego. Book Now!`
    home2_heading1 = `Elevate your style at ${c_businessName} - where classic cuts meet modern sophistication`
    home2_text1 = `Step into the World of Distinctive Grooming Excellence, Where Our Skilled Barbers Are Artisans of Personalization. With a Keen Eye for Detail and a Passion for Unveiling Your Unique Charm, We Transform Haircuts and Shaving into Personalized Masterpieces. Our Craftsmanship Reflects Your Uniqueness, Ensuring You Emerge Polished, Refined, and Infinitely Confident. Trust Us to Sculpt Your Signature Look, Redefining Your Style with Precision and Panache.`
    home2_buttonText1 = `Schedule Now`
    home2_buttonNav1 = `schedule`
    home2_heading2 = `Experience grooming redefined at ${c_businessName}`
    home2_text2 = `Experience the Epitome of Precision and Pampering: From Intricate Scissor Craftsmanship to Rejuvenating Hot Towel Shaves, Our Barbers Curate an Oasis Where Relaxation and Style Converge. Every Moment in Our Care Is a Journey to Revitalization. We Go Beyond Mere Grooming, Ensuring You Depart Not Only Impeccably Groomed, but Renewed and Reinvigorated. Trust Us to Elevate Your Grooming Experience Beyond the Ordinary.`
    home2_buttonText2 = `Learn More`
    home2_buttonNav2 = `blog`
    // #endregion
    // #region HOME 3
    home3_heading1 = `Elevate Your Grooming Experience at Our Barbershop!`;
    home3_caption1 = `Indulge in precision haircuts and luxurious grooming treatments that will leave you feeling confident and refreshed. Our skilled barbers are dedicated to providing top-notch service. Book your appointment today!`;
    home3_buttonText1 = `Book Now`;
    home3_buttonNav1 = `schedule`;
    home3_subBlocks = [
        {
            Title: "Classic Cuts",
            Text: "Experience the artistry of our barbers who craft classic cuts tailored to your style and preferences. Whether you seek a timeless look or a modern twist, we've got you covered.",
            ButtonText: "Explore Services",
            ButtonNav: "services"
        },
        {
            Title: "Grooming Treatments",
            Text: "Pamper yourself with our range of grooming treatments designed to rejuvenate and invigorate. From hot towel shaves to beard trims, we offer the ultimate grooming indulgence.",
            ButtonText: "Explore Services",
            ButtonNav: "services"
        },
        {
            Title: "Expert Barbers",
            Text: "Meet our team of expert barbers who blend traditional techniques with contemporary trends. Experience professionalism and artistry in every snip and style.",
            ButtonText: "Meet the Barbers",
            ButtonNav: "team"
        }
    ];
    // #endregion
    // #region HOME 4
    home4_heading1 = "Elevate Your Look with Our Expert Barbers."
    home4_caption1 = "Experience top-notch grooming and style at our barbershop. Our commitment is to provide you with exceptional services and a look that suits your personality."
    home4_buttonText1 = "Book Now"
    home4_buttonNav1 = "schedule"
    home4_heading2 = "Crafting Unique Hairstyles for Every Individual."
    home4_caption2 = "Explore the world of personalized hairstyles and grooming at our barbershop. Our skilled barbers are dedicated to bringing your unique vision to life."
    home4_subBlocks = [
        {
            Heading: "Tailored Grooming Services for Men.",
            Caption: "Our barbershop offers a range of grooming services designed to suit the modern man's needs. From classic cuts to contemporary styles, we've got you covered."
        },
        {
            Heading: "Blending Tradition and Modernity.",
            Caption: "Experience the perfect fusion of traditional barbering techniques and contemporary trends. Our barbers stay updated to provide you with the latest looks."
        },
        {
            Heading: "Relaxation and Renewal.",
            Caption: "Indulge in a rejuvenating grooming session that not only enhances your appearance but also provides a moment of relaxation in your busy schedule."
        },
        {
            Heading: "Confidence Through Style.",
            Caption: "Step out with confidence after getting a haircut that complements your features and reflects your unique personality. Your satisfaction is our priority."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Elevate Your Look with Premier Haircuts and Shaving at The Classic Cut Barbershop. Book Now!`
    home5_caption1 = `From precise scissor work to rejuvenating hot towel shaves, our skilled barbers provide an oasis of relaxation and style, ensuring you leave not just well-groomed, but revitalized.`
    home5_buttonText1 = `Book Now`
    home5_buttonNav1 = `schedule`
    home5_heading2 = `Experience Grooming Redefined at The Classic Cut Barbershop`
    home5_caption2 = `Experience the Epitome of Precision and Pampering: From Intricate Scissor Craftsmanship to Rejuvenating Hot Towel Shaves, Our Barbers Curate an Oasis Where Relaxation and Style Converge.`
    home5_subBlocks = [
        {
            Heading: "Classic Cuts",
            Caption: "Experience the artistry of our barbers who craft classic cuts tailored to your style and preferences. Whether you seek a timeless look or a modern twist, we've got you covered."
        },
        {
            Heading: "Expert Shaves",
            Caption: "Indulge in the luxury of rejuvenating hot towel shaves that leave your skin smooth and revitalized. Our skilled barbers create a shaving experience like no other."
        },
        {
            Heading: "Modern Styles",
            Caption: "Stay ahead of trends with our barbers' expertise in crafting modern and contemporary styles that showcase your individuality and keep you looking your best."
        },
        {
            Heading: "Relaxing Atmosphere",
            Caption: "Immerse yourself in a welcoming ambiance where the blend of expert grooming and a relaxed environment ensures your visit is a revitalizing experience."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Us`
    about1_heading1 = `The Artistry of Grooming: Crafting Your Signature Look at ${c_businessName}`
    about1_text1 = `At ${c_businessName}, our story is one of passion, craftsmanship, and a commitment to redefining grooming experiences. Founded with the vision of creating more than just a barbershop, we've cultivated an environment where tradition and innovation coexist harmoniously. Our journey began with the belief that grooming is an art, and our skilled barbers are the artists. With each scissor cut and razor shave, we sculpt unique expressions of style that reflect the essence of our clients. The fusion of timeless barbering techniques with contemporary aesthetics is what sets us apart. From the moment you step through our doors, you're not just a client – you're a canvas waiting to be transformed into a masterpiece of self-expression. Welcome to ${c_businessName}, where grooming is an experience, and your story becomes a part of ours.`
    about1_heading2 = `Beyond the Mirror: Discovering Refinement and Relaxation at ${c_businessName}`
    about1_text2 = `At ${c_businessName}, our services are more than just haircuts; they are experiences meticulously designed to rejuvenate your senses and elevate your style. Our team of highly skilled barbers brings decades of expertise to every appointment, ensuring that every snip and stroke is an artful endeavor. From classic cuts that pay homage to time-honored traditions, to contemporary fades that push the boundaries of modern style, our offerings cater to diverse tastes and preferences. Indulge in the luxury of a hot towel shave, a ritual that combines meticulous attention to detail with soothing relaxation. We take pride in curating an atmosphere where you can unwind, connect, and leave not only impeccably groomed but also invigorated. Step into a world where grooming becomes a statement, and each visit to ${c_businessName} is an opportunity to reinvent yourself.`
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Awards`
    awards1_caption = `Embrace a Tapestry of Excellence as Our Barbershop Earns its Stripes: Each Award a Testament to Our Dedication. From Precision Cuts to Rejuvenating Shaves, We Proudly Stand as a Beacon of Grooming Mastery. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Your Style and Confidence. Join us on this Journey of Achievement as We Continue to Define the Art of Grooming.`
    awards1_awards = [
        {
            Award: "Exemplary Grooming Excellence Award",
            Year: "2023",
            Location: "San Diego, CA",
            Desc: "Recognizing the barbershop's dedication to exceptional grooming services that elevate clients' looks and confidence."
        },
        {
            Award: "Barbershop Innovation Award",
            Year: "2023",
            Location: "San Diego, CA",
            Desc: "Celebrating the barbershop's innovative approach to blending traditional techniques with modern trends for remarkable styling."
        },
        {
            Award: "Ultimate Relaxation Haven Award",
            Year: "2023",
            Location: "San Diego, CA",
            Desc: "Honoring the barbershop's commitment to providing a haven of relaxation where clients experience rejuvenating grooming and pampering."
        },
        {
            Award: "Masterful Shaving Artistry Award",
            Year: "2023",
            Location: "San Diego, CA",
            Desc: "Commending the barbershop's mastery in the art of shaving, ensuring precision and comfort with every shave."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of ${c_businessName}`
    bio1_heading1 = `Experience Timeless Grooming Artistry at ${c_businessName}`
    bio1_text1 = `Immerse yourself in the unparalleled world of grooming excellence at ${c_businessName}. Our skilled barbers are dedicated to creating signature styles that enhance your charm and confidence. With precision techniques and a flair for modern trends, we craft masterpieces that reflect your unique personality. Whether it's a classic cut or a rejuvenating shave, your satisfaction is our top priority. Join us for a grooming experience that transcends the ordinary.`
    bio1_heading2 = `A Legacy of Craftsmanship and Care at ${c_businessName}`
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable craftsmanship and client-centric care. From the moment you step in, you're greeted with a blend of tradition and contemporary style. Our barbers are not just experts; they are artists who sculpt hair and facial hair with precision and passion. Beyond grooming, we create an ambiance of comfort and relaxation, ensuring that every visit is a rejuvenating escape. Experience the fusion of tradition and innovation at ${c_businessName}.`
    bio1_heading3 = `Unveil Your Distinctive Charm at ${c_businessName}`
    bio1_text3 = `Step into a world where every detail matters – welcome to ${c_businessName}. Our dedicated team understands that grooming is more than just appearance; it's about expressing your individuality. With careful attention to your preferences and a touch of our expertise, we craft looks that tell your unique story. From meticulously crafted cuts to invigorating shaves, your journey to confidence begins here. Join us at ${c_businessName} and rediscover the art of grooming.`
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Blog`
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Unveiling the Artistry: The Craft of Barbering at " + c_businessName,
            Date: new Date(),
            Desc: "Explore the intricate world of barbering and the meticulous techniques that shape your style. Discover how the skilled barbers at " + c_businessName + " transform haircuts and shaves into personalized masterpieces, showcasing the fusion of tradition and modernity.",
            HTML: (
                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                    <Spacer height={`4vh`} />
                    <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                        <Image src={blog1img1} alt="" radius="" classes="fill" />
                    </Box>
                    <Spacer height={`4vh`} />
                    <Text text={`Unveiling the Artistry: The Craft of Barbering at ${c_businessName}`} fontSize="1.4em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Explore the intricate world of barbering and the meticulous techniques that shape your style. Discover how the skilled barbers at ${c_businessName} transform haircuts and shaves into personalized masterpieces, showcasing the fusion of tradition and modernity.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`From the precise handling of scissors to the artful use of razors, every snip and stroke is a testament to their expertise. Step behind the scenes and learn about the attention to detail that ensures each client walks out with a look that's as unique as they are.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Join us on a journey through the history of barbering and its evolution into a harmonious blend of classic and contemporary styles. Learn how innovation meets tradition at ${c_businessName}, where the mastery of technique meets the canvas of self-expression.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Experience the stories that haircuts tell – tales of individuality, transformation, and confidence. Witness how, at ${c_businessName}, every cut is a reflection of personality, offering clients a canvas to express themselves and showcase their unique narrative.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Delve into the intersection of skill and style, where the barbers of ${c_businessName} weave their artistry. Learn about the tools of the trade, the commitment to excellence, and the fusion of tradition with contemporary trends, resulting in timeless yet modern looks.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                </Box>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Beyond the Chair: Creating a Relaxing Haven at " + c_businessName,
            Date: new Date(),
            Desc: "Step into the tranquil environment of " + c_businessName + " and experience grooming as a rejuvenating escape. Dive into the details of the ambiance, hot towel shaves, and meticulous attention that turns every visit into a soothing journey of self-care.",
            HTML: (
                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                    <Spacer height={`4vh`} />
                    <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                        <Image src={blog2img1} alt="" radius="" classes="fill" />
                    </Box>
                    <Spacer height={`4vh`} />
                    <Text text={`Beyond the Chair: Creating a Relaxing Haven at ${c_businessName}`} fontSize="1.4em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Step into the tranquil environment of ${c_businessName} and experience grooming as a rejuvenating escape. Dive into the details of the ambiance, hot towel shaves, and meticulous attention that turns every visit into a soothing journey of self-care.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Explore the art of creating a space where grooming transcends mere routine. Discover how every element, from the comfortable seating to the invigorating scents, contributes to a haven of relaxation that rejuvenates both body and spirit.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Join us on a voyage that delves into the holistic experience of grooming at ${c_businessName}, where craftsmanship, atmosphere, and personal care merge to create a refuge of tranquility in the heart of the city.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Experience grooming as more than just a task, but a rejuvenating ritual that pampers both the body and the mind. Discover how ${c_businessName} elevates self-care to an art form, creating a haven where clients can unwind, refresh, and emerge with renewed vitality.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`From the moment you enter, ${c_businessName} envelops you in an ambiance of serenity, where relaxation becomes the cornerstone of the experience. Immerse yourself in an oasis designed to soothe the senses and elevate the grooming journey beyond expectations.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                </Box>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Signature Styles: Unleashing Your Unique Look at " + c_businessName,
            Date: new Date(),
            Desc: "Join us in celebrating the individuality that comes alive in every haircut and shave at " + c_businessName + ". Learn how our skilled barbers collaborate with clients to craft looks that reflect their personality, leaving a lasting impression with each unique style.",
            HTML: (
                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                    <Spacer height={`4vh`} />
                    <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                        <Image src={blog3img1} alt="" radius="" classes="fill" />
                    </Box>
                    <Spacer height={`4vh`} />
                    <Text text={`Signature Styles: Unleashing Your Unique Look at ${c_businessName}`} fontSize="1.4em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Join us in celebrating the individuality that comes alive in every haircut and shave at ${c_businessName}. Learn how our skilled barbers collaborate with clients to craft looks that reflect their personality, leaving a lasting impression with each unique style.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Discover the journey of transformation that unfolds in the barber's chair. Explore the consultations, insights, and techniques that shape each haircut into a statement of self-expression. Experience how ${c_businessName} empowers you to embrace and showcase your distinct identity.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`From classic elegance to contemporary flair, our barbers tailor each look to match your preferences and personality. Join us in celebrating the art of crafting signature styles that resonate with who you are, leaving you confident and ready to conquer the world.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Uncover the secrets behind the magic that happens within the walls of ${c_businessName}. Witness the transformation from a simple haircut to a personalized masterpiece that not only enhances your appearance but also captures your essence and distinct style.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                    <Spacer height={`2vh`} />
                    <Text text={`Experience the harmony between creativity and precision as our barbers work their magic. From precision scissor work to rejuvenating hot towel shaves, ${c_businessName} offers an oasis of relaxation, style, and self-expression, ensuring you leave revitalized and ready to conquer the world.`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                </Box>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `We're Hiring!`
    careers1_caption1 = `Take the time to look at these open positions and click on the button to apply. It has never been easier!`
    careers1_positions = [
        {
            Title: "Master Barber",
            Desc: "Are you a skilled barber with an eye for detail and a passion for creating unique styles? Join our team as a Master Barber and showcase your expertise in delivering top-notch haircuts and shaves to our valued clients. With a focus on precision and creativity, you'll play a pivotal role in enhancing our clients' confidence and leaving them looking and feeling their best."
        },
        {
            Title: "Barber Apprentice",
            Desc: "Ready to embark on a rewarding journey in the world of barbering? Our Barber Apprentice position offers a hands-on learning experience as you work alongside our experienced barbers. Develop your skills in scissor work, clipper techniques, and hot towel shaves while gaining insights into the art of crafting signature looks. Grow your craft under the guidance of our skilled team and make your mark in the industry."
        },
        {
            Title: "Client Experience Specialist",
            Desc: "At our barbershop, we believe in providing exceptional service from the moment our clients walk in. Join us as a Client Experience Specialist and be the friendly face that welcomes clients, assists with appointments, and ensures their overall visit is seamless and enjoyable. Your outstanding interpersonal skills and attention to detail will contribute to creating a welcoming and comfortable environment for all our valued clients."
        },
        {
            Title: "Grooming Consultant",
            Desc: "As a Grooming Consultant, you'll be the bridge between our clients' aspirations and our barbers' expertise. Guide clients in choosing the perfect haircut or shave that suits their style and preferences. With your understanding of grooming trends and product knowledge, you'll provide valuable insights to help clients achieve their desired looks while promoting our range of grooming products."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Keep in touch!`
    contact1_mapSearchString = `Imperial Palace Japan`
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Check out our new deals!`
    coupons1_caption = `Unlock unbeatable savings and transform your grooming routine with our exclusive barbershop coupons.`
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "30% off Haircut & Shave Combo",
            Details: "Experience the ultimate grooming session with a 30% discount on our premium Haircut & Shave combo. Treat yourself to precision styling and a rejuvenating shave, all while enjoying unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "$10 Off Any Signature Haircut",
            Details: "Elevate your look with $10 off any of our Signature Haircuts. Our skilled barbers will craft a style that suits your unique personality, leaving you looking and feeling your best."
        },
        {
            Image: couponImg1,
            Coupon: "Free Hot Towel Shave with Beard Trim",
            Details: "Complete your grooming routine with a free Hot Towel Shave when you book a Beard Trim. Indulge in the luxurious experience of a close shave and expert beard shaping."
        },
        {
            Image: couponImg1,
            Coupon: "20% off First Visit",
            Details: "Welcome to our barbershop! Enjoy 20% off your first visit with us. Discover the art of personalized grooming and start your journey to a polished and confident look."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Events`
    events1_events = [
        {
            Title: "Grooming Showcase and Styling Workshop",
            Desc: "Join us for an exclusive Grooming Showcase and Styling Workshop, where our expert barbers will demonstrate the latest trends in haircuts and grooming techniques. Learn how to achieve the perfect style for any occasion and enjoy live demonstrations and personalized tips from our skilled team.",
            Date: "Aug 15, 2023",
            Image: eventsImg1
        },
        {
            Title: "Beard Care Seminar and Trimming Session",
            Desc: "Discover the art of beard care at our Beard Care Seminar and Trimming Session. Our barbers will share insights into maintaining a healthy and stylish beard, followed by hands-on guidance in proper trimming techniques. Whether you're a seasoned beard enthusiast or just starting your beard journey, this event is for you.",
            Date: "Sep 10, 2023",
            Image: eventsImg1
        },
        {
            Title: "Barbershop Anniversary Celebration",
            Desc: "Celebrate our barbershop's anniversary with us in style! Enjoy a day filled with special offers, complimentary grooming sessions, and festive treats. It's our way of thanking you for being part of our journey. Join us for a day of fun, grooming, and community.",
            Date: "Oct 20, 2023",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Check out our latest features. 2023.`
    features1_features = [
        {
            Feature: "Precision Haircuts",
            Desc: "Our skilled barbers specialize in precision haircuts that enhance your unique style. From classic to contemporary, we tailor each cut to suit your preferences, ensuring you walk out looking sharp and confident.",
            Subject: "Haircare"
        },
        {
            Feature: "Relaxing Hot Towel Shaves",
            Desc: "Experience the luxury of a traditional hot towel shave that rejuvenates your skin and leaves you feeling refreshed. Our barbers combine skill and attention to detail to provide a close, comfortable shave that leaves your skin smooth and revitalized.",
            Subject: "Grooming"
        },
        {
            Feature: "Beard Styling Expertise",
            Desc: "Whether you're growing a beard or maintaining your existing one, our barbers are experts in beard styling. From shaping to trimming, we know the art of creating well-groomed and stylish beards that suit your facial features.",
            Subject: "Beard Care"
        },
        {
            Feature: "Personalized Consultations",
            Desc: "Every visit starts with a personalized consultation where our barbers take the time to understand your preferences and offer expert advice. We believe in delivering tailored services that cater to your individual style and grooming goals.",
            Subject: "Consultation"
        },
        {
            Feature: "Premium Men's Grooming Products",
            Desc: "Discover our range of premium men's grooming products that help you maintain your look between visits. From high-quality shampoos and conditioners to styling products, we offer the tools you need for a polished appearance.",
            Subject: "Products"
        },
        {
            Feature: "Inviting Barbershop Atmosphere",
            Desc: "Step into our barbershop and experience a welcoming and comfortable atmosphere where you can relax and enjoy your grooming experience. Our cozy setting is designed to make you feel at ease while we work our magic.",
            Subject: "Ambiance"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Take a closer look!`
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ]
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `It all started here..`
    history1_caption = `${c_businessName} - A Journey Through Time`;
    history1_heading1 = `Experience ${c_businessName}: Where modern grooming meets timeless tradition, redefining the barbershop journey.`;
    history1_text1 = `Steeped in history, ${c_businessName} has witnessed the evolution of men's grooming. From the sleek styles of the 20s to the rebellious trends of the 60s, our barbers have been the architects of transformation. We take pride in being the go-to destination for those who seek sophistication and authenticity in every aspect of their grooming routine. Our commitment to quality, precision, and personalized service has stood the test of time.`;
    history1_heading2 = `More than grooming, ${c_businessName} is a community cornerstone where stories are shared, connections are made, and traditions thrive.`;
    history1_text2 = `As we celebrate our journey so far, we look forward to continuing our legacy of excellence. With a team of dedicated barbers, a commitment to impeccable service, and an unwavering passion for the craft, ${c_businessName} remains the destination for those who appreciate the art of grooming. Join us in celebrating our history and shaping the future of grooming together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `We are here..`
    locations1_locations = [
        {
            Location: "Barbershop - City Heights",
            Address: "1 Infinite Loop Cupertino",
            Hours: "Mon - Fri: 8 AM - 5 PM\nSat: 10AM - 8PM\nSunday: 2PM-8PM"
        },
        {
            Location: "Barbershop - North Park",
            Address: "1 Infinite Loop Cupertino",
            Hours: "Mon - Fri: 8 AM - 5 PM\nSat: 10AM - 8PM\nSunday: 2PM-8PM"
        }
    ]
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join our mailing list!`
    mailingList1_caption = `There are many reasons to join out mailing list. Give the bagel a chance and enter your email to never miss a newsletter.`
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Menu`
    menu1_menu = [
        {
            Name: "Classic Haircut",
            Category: "Haircuts",
            Prices: [
                {
                    Option: "Standard",
                    Price: "$20"
                },
                {
                    Option: "Premium",
                    Price: "$25"
                }
            ],
            Desc: "Our signature haircut service tailored to your style."
        },
        {
            Name: "Beard Trim",
            Category: "Facial Grooming",
            Prices: [
                {
                    Option: "Basic",
                    Price: "$15"
                },
                {
                    Option: "Detailed",
                    Price: "$20"
                }
            ],
            Desc: "Precision grooming for a well-defined beard."
        },
        {
            Name: "Hot Towel Shave",
            Category: "Facial Grooming",
            Prices: [
                {
                    Option: "Traditional",
                    Price: "$30"
                },
                {
                    Option: "Luxury",
                    Price: "$40"
                }
            ],
            Desc: "Experience the ultimate in smoothness and relaxation."
        },
        {
            Name: "Shampoo & Style",
            Category: "Hair Treatments",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$15"
                },
                {
                    Option: "Deluxe",
                    Price: "$20"
                }
            ],
            Desc: "Revitalize your hair with a rejuvenating shampoo and styling."
        },
        {
            Name: "Head Massage",
            Category: "Relaxation",
            Prices: [
                {
                    Option: "10 minutes",
                    Price: "$10"
                },
                {
                    Option: "20 minutes",
                    Price: "$15"
                }
            ],
            Desc: "Unwind with a soothing head massage to melt away stress."
        },
        {
            Name: "Gift Cards",
            Category: "Gift Ideas",
            Prices: [
                {
                    Option: "Custom Amount",
                    Price: "Varies"
                }
            ],
            Desc: "Share the grooming experience with a personalized gift card."
        }
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `We only work with the best!`
    partners1_caption = `Our partners are what help us make it all possible. Truly!`
    partners1_partners = [
        {
            Partner: "Classic Cuts Barbershop",
            Desc: "Our trusted partner for years, offering high-quality grooming services that complement our standards.",
            Link: "https://classiccutsbarbershop.com",
            Img: partnerImg1
        },
        {
            Partner: "Sharp Edge Razors",
            Desc: "Proudly collaborating with Sharp Edge Razors, providing top-notch shaving products for the perfect shave.",
            Link: "https://sharpedgerazors.com",
            Img: partnerImg1
        },
        {
            Partner: "Gentlemen's Essentials",
            Desc: "Teaming up with Gentlemen's Essentials to offer premium grooming products that match our commitment to excellence.",
            Link: "https://gentlemensessentials.com",
            Img: partnerImg1
        },
        {
            Partner: "The Grooming Lounge",
            Desc: "In partnership with The Grooming Lounge, ensuring our patrons enjoy a complete grooming experience like no other.",
            Link: "https://thegroominglounge.com",
            Img: partnerImg1
        }
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Portfolio`
    portfolio1_caption = ``
    portfolio1_projects = [
        {
            Project: "Gentleman's Hairstyle Collection",
            Desc: "Explore our curated collection of the latest gentleman's hairstyles, from classic cuts to modern trends.",
            Link: "https://barbershopportfolio.com/hairstyles",
            Image: portfolioImg1
        },
        {
            Project: "Beard Grooming Guide",
            Desc: "Master the art of beard grooming with our comprehensive guide, covering everything from trimming to maintenance.",
            Link: "https://barbershopportfolio.com/beard-guide",
            Image: portfolioImg1
        },
        {
            Project: "Barbershop Events Recap",
            Desc: "Relive the excitement of our recent barbershop events through captivating photos and behind-the-scenes moments.",
            Link: "https://barbershopportfolio.com/events",
            Image: portfolioImg1
        },
        {
            Project: "Classic Shaves Showcase",
            Desc: "Witness the finesse of our classic shave technique as we showcase some of our finest grooming experiences.",
            Link: "https://barbershopportfolio.com/classic-shaves",
            Image: portfolioImg1
        }
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`
    pricing1_caption = `Refine Your Look: Discover Our Grooming Packages and Pricing at ${c_businessName}`
    pricing1_prices = [
        {
            Price: "40",
            Desc: "Enjoy a classic haircut that keeps you looking sharp and confident. Our experienced barbers will give you a precise trim that suits your style."
        },
        {
            Price: "80",
            Desc: "Elevate your facial hair game with our comprehensive beard grooming package. Get a professional trim, shaping, and conditioning treatment that enhances your beard's texture and appearance."
        },
        {
            Price: "120",
            Desc: "Treat yourself to a premium styling session that includes a precision haircut, beard grooming, and a relaxing hot towel treatment. Our skilled barbers will ensure you leave our shop feeling refreshed and revitalized."
        }
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`
    products1_caption = `Elevate Your Grooming Routine: Discover Our Premium Barbershop Products for the Modern Gentleman.`
    products1_products = [
        {
            Name: "Classic Pomade",
            Desc: "Achieve the perfect hairstyle with our Classic Pomade that offers a strong hold and natural shine.",
            Price: "18.00",
            Image: productsImg1
        },
        {
            Name: "Beard Oil",
            Desc: "Keep your beard well-groomed and nourished with our Beard Oil made from natural oils and enriched with vitamins.",
            Price: "12.00",
            Image: productsImg2
        },
        {
            Name: "Gentleman's Shave Kit",
            Desc: "Experience a luxurious shave with our Gentleman's Shave Kit, complete with a premium razor, brush, and shaving cream.",
            Price: "35.00",
            Image: productsImg3
        },
        {
            Name: "Aftershave Balm",
            Desc: "Soothe and hydrate your skin post-shave with our Aftershave Balm, designed to minimize irritation and redness.",
            Price: "15.00",
            Image: productsImg4
        },
        {
            Name: "Barber's Grooming Set",
            Desc: "Elevate your grooming routine with our comprehensive Barber's Grooming Set, including styling products, comb, and more.",
            Price: "50.00",
            Image: productsImg5
        },
        {
            Name: "Hair and Beard Trimmer",
            Desc: "Achieve precise cuts and trims with our professional Hair and Beard Trimmer, designed for both hair and facial hair.",
            Price: "55.00",
            Image: productsImg6
        },
        {
            Name: "Luxury Shampoo",
            Desc: "Revitalize your hair with our Luxury Shampoo, infused with natural ingredients to cleanse and nourish effectively.",
            Price: "22.00",
            Image: productsImg7
        },
        {
            Name: "Styling Clay",
            Desc: "Create versatile and textured styles with our Styling Clay, providing a medium hold and matte finish.",
            Price: "20.00",
            Image: productsImg8
        },
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get your quote today!`
    quote1_caption = `Where grooming becomes an art and style a statement. Step into our barbershop, where tradition meets trend, and each haircut is a masterpiece of self-expression and confidence.`
    quote1_heading1 = `Pick a service`
    quote1_heading2 = `Tell us a bit about yourself`
    quote1_services = [
        `Service 1`,
        `Service 2`,
        `Service 3`,
        `Service 4`,
    ]
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Reviews`
    reviews1_reviews = [
        {
            Reviewer: "John Smith",
            Review: "Absolutely love this barbershop! The atmosphere is fantastic, and the barbers are skilled professionals. I always leave with a great haircut and a smile.",
            Rating: "5.0",
            Date: "08/05/2023"
        },
        {
            Reviewer: "Emily Johnson",
            Review: "My go-to place for getting a fresh haircut. The attention to detail is incredible, and the staff is friendly. I highly recommend this barbershop!",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Davis",
            Review: "I've been a loyal customer for years, and I'm always impressed by the consistent quality of service. The barbers here truly understand the art of grooming.",
            Rating: "5.0",
            Date: "07/15/2023"
        },
        {
            Reviewer: "Sophia Martinez",
            Review: "Visited this barbershop for the first time and was blown away by the attention they give to each customer. The vibe is amazing, and I left with an awesome haircut!",
            Rating: "4.7",
            Date: "06/30/2023"
        },
        {
            Reviewer: "William Adams",
            Review: "Hands down the best barbershop in town! The team here is dedicated and skilled. I've never been disappointed with my haircuts. Keep up the great work!",
            Rating: "5.0",
            Date: "06/18/2023"
        }
    ]
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule`
    schedule1_heading1 = `Categories`
    schedule1_heading2 = `Services`
    schedule1_heading3 = `Pick a date`
    schedule1_buttonText = `Get Schedule`
    schedule1_formHeading = `Before we confirm your slot...`
    schedule1_formCaption = `You have selected the time slot of:`
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Services`
    services1_caption = `Experience top-tier grooming services that seamlessly blend classic techniques with contemporary styles at our barbershop.`
    services1_services = [
        {
            Name: "Haircut & Styling",
            Desc: "Revamp your look with precision haircuts and contemporary styling that suit your personality and preferences.",
            Image: servicesImg1
        },
        {
            Name: "Beard Grooming",
            Desc: "Maintain a well-groomed and stylish beard with our expert grooming services that cater to your beard's unique needs.",
            Image: servicesImg2
        },
        {
            Name: "Hot Towel Shave",
            Desc: "Experience the timeless luxury of a classic hot towel shave that leaves your skin smooth and rejuvenated.",
            Image: servicesImg3
        },
        {
            Name: "Facial Treatments",
            Desc: "Indulge in revitalizing facial treatments designed to cleanse, exfoliate, and rejuvenate your skin for a fresh and youthful appearance.",
            Image: servicesImg4
        },
        {
            Name: "Scalp Massage",
            Desc: "Relax and unwind with our soothing scalp massages that promote relaxation, stress relief, and improved blood circulation.",
            Image: servicesImg5
        },
        {
            Name: "Hair Coloring",
            Desc: "Explore a spectrum of hair colors and highlights that add vibrancy and dimension to your style, all tailored to your preferences.",
            Image: servicesImg6
        }
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet our barbers`
    team1_caption = ``
    team1_teamMembers = [
        {
            Name: "John Barber",
            Position: "Master Barber",
            Image: teamImg1
        },
        {
            Name: "Alexandra Styler",
            Position: "Hair Stylist",
            Image: teamImg2
        },
        {
            Name: "Emily Shaver",
            Position: "Beard Specialist",
            Image: teamImg3
        },
        {
            Name: "David Groom",
            Position: "Shave Expert",
            Image: teamImg4
        },
    ];
    // #endregion
} else if (c_businessType == "Nail Salon") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Nail Experience with Premium Services. Book Now!`;
    home1_caption1 = `From top-notch nail services to personalized care, our team provides an oasis of relaxation and style, ensuring you leave not just satisfied, but revitalized.`;
    home1_buttonText1 = `Book Now`;
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Nail Experience with Premium Services. Book Now!`;
    home2_heading1 = `Elevate your nail experience at ${c_businessName} - where excellence meets modern sophistication`;
    home2_text1 = `Step into the World of Nail Artistry, Where Our Skilled Professionals Are Masters of Personalization. With a Keen Eye for Detail and a Passion for Enhancing Your Unique Style, We Transform Nail Services into Personalized Masterpieces. Our Craftsmanship Reflects Your Individuality, Ensuring You Emerge Polished, Refined, and Infinitely Confident. Trust Us to Craft Your Signature Nail Experience, Redefining Your Journey with Precision and Panache.`;
    home2_buttonText1 = `Schedule Now`;
    home2_buttonNav1 = `schedule`;
    home2_heading2 = `Experience Nail Excellence Redefined at ${c_businessName}`;
    home2_text2 = `Experience the Epitome of Nail Care: From Intricate Nail Art to Rejuvenating Nail Techniques, Our Team Curates an Oasis Where Relaxation and Style Converge. Every Moment in Our Nail Studio Is a Journey to Nail Revitalization. We Go Beyond Ordinary Nail Services, Ensuring You Depart Not Only Satisfied but also Renewed and Reinvigorated. Trust Us to Elevate Your Nail Experience Beyond the Ordinary.`;
    home2_buttonText2 = `Learn More`;
    home2_buttonNav2 = `blog`;
    // #endregion
    // #region HOME 3
    home3_heading1 = `Discover Nail Beauty in the Palm of Your Hand!`;
    home3_caption1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.`;
    home3_buttonText1 = `Book Now`;
    home3_buttonNav1 = `schedule`;
    home3_subBlocks = [
        {
            Title: "Nail Services 1",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Nail Services 2",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Nail Services 3",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        }
    ];
    // #endregion
    // #region HOME 4
    home4_heading1 = "Nail Artistry Redefined at Our Nail Salon."
    home4_caption1 = "Experience the epitome of nail care and artistry at our salon. Our skilled nail technicians are dedicated to enhancing the beauty of your nails."
    home4_buttonText1 = "Book Appointment"
    home4_buttonNav1 = "schedule"
    home4_heading2 = "Elevate Your Nail Aesthetics."
    home4_caption2 = "Explore a world of personalized nail art and pampering at our salon. Our experts are passionate about transforming your nails into stunning works of art."
    home4_subBlocks = [
        {
            Heading: "Tailored Nail Services for Every Occasion.",
            Caption: "From classic manicures to intricate nail designs, our salon offers a variety of services to match your preferences and elevate your style."
        },
        {
            Heading: "Fusion of Creativity and Precision.",
            Caption: "Experience the perfect blend of creative nail artistry and precise techniques. Our nail technicians are up-to-date with the latest trends to provide you with exceptional designs."
        },
        {
            Heading: "Relaxation and Rejuvenation for Your Hands.",
            Caption: "Indulge in a pampering nail session that not only beautifies your nails but also offers relaxation and rejuvenation for your hands and mind."
        },
        {
            Heading: "Confidence Through Stunning Nails.",
            Caption: "Step out with confidence, showcasing nails that express your unique style. Our goal is to ensure your satisfaction and provide you with nails that turn heads."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Enhance Your Look with Exquisite Nail Art and Care at GlamourNails. Book Your Appointment Now!`
    home5_caption1 = `From stunning nail art to rejuvenating manicures and pedicures, our skilled nail technicians offer a haven of relaxation and style, ensuring you leave with beautiful nails and a refreshed spirit.`
    home5_buttonText1 = `Book Now`
    home5_buttonNav1 = `schedule`
    home5_heading2 = `Experience Nail Care Transformed at GlamourNails`
    home5_caption2 = `Experience the Epitome of Nail Artistry and Pampering: From Intricate Nail Designs to Rejuvenating Manicures and Pedicures, Our Nail Technicians Create a Haven Where Style and Renewal Intersect.`
    home5_subBlocks = [
        {
            Heading: "Exquisite Nail Art",
            Caption: "Experience the creativity of our nail technicians who craft exquisite nail art designs that reflect your personality and enhance the beauty of your nails."
        },
        {
            Heading: "Manicures & Pedicures",
            Caption: "Indulge in the luxury of rejuvenating manicures and pedicures that leave your nails looking stunning and your hands and feet feeling revitalized."
        },
        {
            Heading: "Unique Nail Styles",
            Caption: "Stay ahead of nail trends with our technicians' expertise in creating unique and stylish nail designs that match your individuality and elevate your overall look."
        },
        {
            Heading: "Relaxing Ambiance",
            Caption: "Immerse yourself in a soothing atmosphere where the combination of expert nail care and a calming environment ensures your visit is a refreshing experience."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Our Nail Salon`;
    about1_heading1 = `The Art of Nail Excellence: Crafting Memorable Experiences at ${c_businessName}`;
    about1_text1 = `At ${c_businessName}, our story is one of passion, dedication, and a commitment to redefining nail experiences. Founded with the vision of creating more than just a nail salon, we've cultivated an environment where tradition and innovation coexist harmoniously. Our journey began with the belief that nail care is an art, and our skilled nail artists are the creators. With each interaction, we curate unique expressions of nail excellence that reflect the essence of our clients. The fusion of timeless techniques with contemporary trends is what sets us apart. From the moment you engage with us, you're not just a client – you're part of an experience waiting to be transformed into a masterpiece of nail satisfaction. Welcome to ${c_businessName}, where nail excellence is an art, and your journey becomes a part of ours.`;
    about1_heading2 = `Beyond Nail Expectations: Discovering Refinement and Relaxation at ${c_businessName}`;
    about1_text2 = `At ${c_businessName}, our nail services are more than just appointments; they are experiences meticulously designed to rejuvenate your nails and elevate your satisfaction. Our team of highly skilled nail artists brings expertise to every interaction, ensuring that every step is an artful endeavor. From classic nail designs that pay homage to time-honored traditions, to contemporary innovations that push the boundaries of modern nail art, our offerings cater to diverse preferences. Indulge in the luxury of a soothing nail experience that combines meticulous attention to detail with relaxation. We take pride in curating an atmosphere where you can unwind, connect, and leave not only satisfied but also invigorated. Step into a world where nail excellence becomes a statement, and each visit to ${c_businessName} is an opportunity to experience something exceptional.`;
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Nail Salon Awards`;
    awards1_caption = `Embrace a Tapestry of Nail Excellence as Our Salon Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Nail Services to Customer Satisfaction, We Proudly Stand as a Beacon of Nail Excellence. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Nail Experiences. Join us on this Journey of Achievement as We Continue to Define the Art of Nail Service.`;
    awards1_awards = [
        {
            Award: "Exemplary Nail Excellence Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional nail services that elevate customer experiences."
        },
        {
            Award: "Innovation in Nail Service Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern trends for remarkable nail experiences."
        },
        {
            Award: "Ultimate Nail Haven Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to providing a haven of relaxation where clients experience rejuvenation and pampering for their nails."
        },
        {
            Award: "Masterful Nail Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in delivering nail services with precision and comfort."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of ${c_businessName}`;
    bio1_heading1 = `Experience Hair Care Excellence at ${c_businessName}`;
    bio1_text1 = `Indulge in the exceptional world of hair care experiences at ${c_businessName}. Our skilled professionals are committed to crafting memorable interactions that boost your satisfaction and confidence. With precision techniques and a modern touch, we curate experiences that align with your unique preferences. Whether it's a classic hairstyle or a rejuvenating treatment, your contentment is our highest priority. Join us for an experience that transcends expectations.`;
    bio1_heading2 = `A Legacy of Styling Artistry and Care at ${c_businessName}`;
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable styling craftsmanship and customer-focused care. From the moment you engage with us, you're embraced by a fusion of tradition and contemporary flair. Our professionals are more than experts; they are artists who provide services with precision and passion. Beyond the service, we create an ambiance of ease and relaxation, ensuring each interaction is a revitalizing escape. Experience the harmony of tradition and innovation at ${c_businessName}.`;
    bio1_heading3 = `Discover Personalized Hair Experiences at ${c_businessName}`;
    bio1_text3 = `Step into a world where every strand matters – welcome to ${c_businessName}. Our dedicated team understands that hair care is more than just a task; it's about expressing your style. With meticulous attention to your needs and a touch of our expertise, we design experiences that resonate with your unique story. From finely executed styles to invigorating treatments, your journey to hair satisfaction begins here. Join us at ${c_businessName} and rediscover the art of personalized hair care.`;
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Hair Care Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Exploring the Craft: The Art of Our Services",
            Date: new Date(),
            Desc: "Dive into the world of our exceptional services and the meticulous techniques that shape customer experiences. Discover how our skilled professionals transform interactions into personalized masterpieces, showcasing the blend of tradition and modernity.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Exploring the Craft: The Art of Our Services
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Dive into the world of our exceptional services and the meticulous techniques that shape customer experiences. Discover how our skilled professionals transform interactions into personalized masterpieces, showcasing the blend of tradition and modernity.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the precise handling of tools to the artful use of methods, every step is a testament to their expertise. Step behind the scenes and learn about the attention to detail that ensures each client walks out with an experience that's as unique as they are.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey through the history of our industry and its evolution into a harmonious blend of classic and contemporary approaches. Learn how innovation meets tradition at our establishment, where the mastery of technique meets the canvas of customer satisfaction.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the stories that services tell – tales of individuality, transformation, and satisfaction. Witness how, at our establishment, every service is a reflection of personality, offering customers an opportunity to express themselves and enjoy a unique experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Delve into the intersection of skill and style, where our professionals showcase their artistry. Learn about the tools of the trade, the commitment to excellence, and the fusion of tradition with contemporary trends, resulting in timeless yet modern experiences.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Creating a Tranquil Haven for Ultimate Relaxation",
            Date: new Date(),
            Desc: "Step into the calming environment of our establishment and experience our services as a rejuvenating escape. Explore the ambiance, relaxation techniques, and careful attention that turn every visit into a soothing journey of self-care.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Creating a Tranquil Haven for Ultimate Relaxation
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the calming environment of our establishment and experience our services as a rejuvenating escape. Explore the ambiance, relaxation techniques, and careful attention that turn every visit into a soothing journey of self-care.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the art of creating a space where services transcend mere routine. Discover how every element, from the comfortable surroundings to the soothing scents, contributes to a haven of relaxation that rejuvenates both body and spirit.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey that delves into the holistic experience of services at our establishment, where craftsmanship, ambiance, and personal care merge to create a refuge of tranquility.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience services as more than just a transaction, but a rejuvenating ritual that pampers both the body and the mind. Discover how our establishment elevates self-care to an art form, creating a haven where customers can unwind, refresh, and leave with renewed vitality.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the moment you enter, our establishment envelops you in an ambiance of serenity, where relaxation becomes the cornerstone of the experience. Immerse yourself in an oasis designed to soothe the senses and elevate the service journey beyond expectations.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Crafting Unique Experiences for Every Customer",
            Date: new Date(),
            Desc: "Celebrate the individuality that shines through every interaction with our business. Learn how our skilled professionals collaborate with customers to curate experiences that reflect their preferences, leaving a lasting impression with each unique encounter.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Crafting Unique Experiences for Every Customer
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Celebrate the individuality that shines through every interaction with our business. Learn how our skilled professionals collaborate with customers to curate experiences that reflect their preferences, leaving a lasting impression with each unique encounter.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the journey of transformation that unfolds in every customer interaction. Explore the consultations, insights, and techniques that shape each experience into a statement of individuality. Experience how our establishment empowers customers to embrace and showcase their distinct preferences.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From classic elegance to contemporary flair, our professionals tailor each encounter to match customer preferences. Join us in celebrating the art of crafting unique experiences that resonate with each individual, leaving them confident and ready to embrace their journey.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Uncover the secrets behind the magic that happens within the walls of our establishment. Witness the transformation from a simple interaction to a personalized masterpiece that not only enhances appearance but also captures the essence and distinct preferences of each customer.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the harmony between creativity and precision as our professionals deliver exceptional services. From precise techniques to rejuvenating experiences, our establishment offers a haven of relaxation, style, and self-expression, ensuring customers leave revitalized and ready to conquer the world.
                    </p>
                </div>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team!`;
    careers1_caption1 = `Explore exciting job opportunities and take the next step in your career. Apply now to be part of our talented team!`;
    careers1_positions = [
        {
            Title: "Nail Technician",
            Desc: "Are you a skilled nail technician with a keen eye for detail and a passion for delivering exceptional nail services? Join our team and showcase your expertise in providing top-notch nail care to our valued clients. With a focus on precision and creativity, you'll play a pivotal role in enhancing our clients' experiences and leaving them satisfied."
        },
        {
            Title: "Apprentice Nail Technician",
            Desc: "Ready to embark on a rewarding journey in the nail industry? Our apprentice position offers hands-on learning as you work alongside our experienced nail technicians. Develop your skills while gaining insights into the art of delivering exceptional nail services. Grow your craft under the guidance of our skilled team and make your mark in the field."
        },
        {
            Title: "Client Experience Specialist",
            Desc: "Join us as a Client Experience Specialist and be the friendly face that welcomes clients, assists with appointments, and ensures their overall interaction is seamless and enjoyable. Your outstanding interpersonal skills and attention to detail will contribute to creating a welcoming and comfortable environment for all our valued clients."
        },
        {
            Title: "Nail Care Consultant",
            Desc: "As a Nail Care Consultant, you'll be the bridge between our clients' nail care preferences and our professionals' expertise. Guide clients in choosing the perfect nail service that suits their style. With your understanding of nail care trends and product knowledge, you'll provide valuable insights to help clients achieve their desired results."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Get in Touch`;
    contact1_mapSearchString = `Nail Salon Location`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Discover Our Special Offers`;
    coupons1_caption = `Unlock unbeatable savings and elevate your nail salon experience with our exclusive deals.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Special Offer 1",
            Details: "Experience our premium nail services with a special discount. Treat yourself to a unique nail care experience while enjoying unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "Special Offer 2",
            Details: "Elevate your nail care experience with a discounted service. Our skilled nail technicians will deliver results that leave you satisfied."
        },
        {
            Image: couponImg1,
            Coupon: "Special Offer 3",
            Details: "Complete your nail care experience with a bonus offer. Indulge in the luxury of a complimentary addition to your nail service."
        },
        {
            Image: couponImg1,
            Coupon: "Welcome Offer",
            Details: "Welcome to our nail salon! Enjoy a special offer on your first visit. Discover the art of personalized nail care and start your journey with us."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Nail Art Showcase",
            Desc: "Join us for an exclusive nail art showcase and workshop, where our experts will demonstrate the latest nail art trends and techniques. Learn how to achieve the perfect nail art for any occasion and enjoy live demonstrations and personalized tips from our skilled team.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Nail Care Seminar",
            Desc: "Discover the art of nail care at our seminar and session. Our nail care professionals will share insights into maintaining and enhancing nails, followed by hands-on guidance. Whether you're a seasoned enthusiast or just starting your nail care journey, this event is for you.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Nail Salon Anniversary Celebration",
            Desc: "Celebrate with us in style! Enjoy a day filled with special offers, complimentary nail services, and festive treats. It's our way of thanking you for being part of our nail salon journey. Join us for a day of fun, nail care, and community.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Discover Our Nail Salon Features`;
    features1_features = [
        {
            Feature: "Expert Nail Care",
            Desc: "Our skilled nail technicians specialize in delivering exceptional results that enhance your unique nail care preferences. From classic to contemporary nail designs, we tailor each nail experience to suit your needs, ensuring you leave satisfied.",
            Subject: "Nail Services"
        },
        {
            Feature: "Luxurious Nail Treatments",
            Desc: "Experience the luxury of our rejuvenating nail treatments. Our experts combine skill and attention to detail to provide a nail care result that leaves you refreshed and revitalized.",
            Subject: "Nail Treatments"
        },
        {
            Feature: "Nail Maintenance and Styling",
            Desc: "Whether you're seeking nail maintenance or enhancement, our professionals are experts in delivering nail care results that suit your style. From shaping to nail styling, we know the art of creating well-groomed and stylish nails.",
            Subject: "Nail Styling"
        },
        {
            Feature: "Personalized Consultations",
            Desc: "Every nail experience starts with a personalized consultation where our nail professionals take the time to understand your nail care preferences. We believe in delivering tailored nail care experiences that cater to your individual needs and goals.",
            Subject: "Consultations"
        },
        {
            Feature: "Premium Nail Products",
            Desc: "Discover our range of premium nail care products that help you maintain your nails between salon visits. From high-quality nail products to nail styling tools, we offer the resources you need for polished and beautiful nails.",
            Subject: "Nail Products"
        },
        {
            Feature: "Relaxing Nail Salon Ambiance",
            Desc: "Step into our nail salon and experience a welcoming atmosphere where you can relax and enjoy your nail care experience. Our inviting setting is designed to make you feel at ease while we deliver beautiful nail results.",
            Subject: "Salon Ambiance"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Explore Our Nail Salon Gallery`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `Our Nail Salon Journey`;
    history1_caption = `A Journey Through Nail Care`;
    history1_heading1 = `Experience the journey: Where modern meets tradition, redefining the nail care experience.`;
    history1_text1 = `Steeped in history, we've witnessed the evolution of nail care. From timeless nail designs to contemporary nail art trends, we've been the architects of nail transformation. We take pride in being the destination for those who seek authenticity in every aspect of their nail care journey. Our commitment to quality, precision, and personalized nail services stands strong.`;
    history1_heading2 = `More than what we do, we're a community cornerstone where stories are shared and nail care traditions thrive.`;
    history1_text2 = `As we celebrate our nail salon journey, we look forward to continuing our legacy in nail care. With a team of dedicated nail professionals and a passion for the craft, we remain the destination for those who appreciate excellence in nail care. Join us in shaping the future of nail care together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Find Our Nail Salon Locations`;
    locations1_locations = [
        {
            Location: "Main Nail Salon",
            Address: "123 Nail Street, Cityville, Nailsland",
            Hours: "Mon - Fri: 9 AM - 7 PM\nSat: 10 AM - 8 PM\nSunday: Closed"
        },
        {
            Location: "Downtown Nail Studio",
            Address: "456 Nail Avenue, Townsville, Nailsland",
            Hours: "Mon - Fri: 10 AM - 8 PM\nSat: 9 AM - 6 PM\nSunday: 11 AM - 5 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join our mailing list!`;
    mailingList1_caption = `Stay updated and never miss a newsletter. Enter your email to be part of our community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Menu`;
    menu1_menu = [
        {
            Name: "Service 1",
            Category: "Category 1",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$20"
                },
                {
                    Option: "Option 2",
                    Price: "$25"
                }
            ],
            Desc: "Description for Service 1."
        },
        {
            Name: "Service 2",
            Category: "Category 2",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$15"
                },
                {
                    Option: "Option 2",
                    Price: "$20"
                }
            ],
            Desc: "Description for Service 2."
        },
        // Add more services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `We only work with the best!`;
    partners1_caption = `Our partners make it all possible.`;
    partners1_partners = [
        {
            Partner: "Partner 1",
            Desc: "Description for Partner 1.",
            Link: "https://partner1.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Portfolio`;
    portfolio1_caption = ``;
    portfolio1_projects = [
        {
            Project: "Collection 1",
            Desc: "Explore our curated collection of the latest designs, from classic to modern.",
            Link: "https://portfolio.com/collection1",
            Image: portfolioImg1
        },
        {
            Project: "Guide 1",
            Desc: "Master the art of something with our comprehensive guide, covering everything from basics to advanced techniques.",
            Link: "https://portfolio.com/guide1",
            Image: portfolioImg1
        },
        {
            Project: "Events Recap",
            Desc: "Relive the excitement of our recent events through captivating photos and highlights.",
            Link: "https://portfolio.com/events",
            Image: portfolioImg1
        },
        {
            Project: "Showcase 1",
            Desc: "Witness the creativity and craftsmanship as we showcase some of our finest work.",
            Link: "https://portfolio.com/showcase1",
            Image: portfolioImg1
        }
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Refine Your Experience: Discover Our Packages and Pricing`;
    pricing1_prices = [
        {
            Price: "40",
            Desc: "Get the best with our top-notch service. Our experienced professionals will deliver results that impress."
        },
        {
            Price: "80",
            Desc: "Upgrade your experience with our comprehensive package. Get more value and enhanced results."
        },
        {
            Price: "120",
            Desc: "Indulge in luxury with our premium offering. Experience the epitome of excellence and satisfaction."
        }
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`;
    products1_caption = `Elevate Your Experience: Discover Premium Products`;
    products1_products = [
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg1
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg2
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg3
        },
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg4
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg5
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg6
        },
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg7
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg8
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg9
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get your quote today!`;
    quote1_caption = `Where excellence meets style. Step into our world, where quality is paramount and every experience is remarkable.`;
    quote1_heading1 = `Pick an option`;
    quote1_heading2 = `Tell us about your preferences`;
    quote1_services = [
        `Option 1`,
        `Option 2`,
        `Option 3`,
        `Option 4`,
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "John Doe",
            Review: "Absolutely love this place! The atmosphere is fantastic, and the professionals are skilled. I always leave satisfied.",
            Rating: "5.0",
            Date: "08/05/2023"
        },
        {
            Reviewer: "Jane Smith",
            Review: "My go-to spot for quality. The attention to detail is incredible, and the staff is friendly. Highly recommend!",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Johnson",
            Review: "Outstanding service! The expertise of the team is unmatched. Every visit is a treat.",
            Rating: "4.9",
            Date: "09/15/2023"
        },
        {
            Reviewer: "Emily Adams",
            Review: "Exceptional experience every time. The attention to customer preferences is remarkable.",
            Rating: "5.0",
            Date: "10/03/2023"
        }
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Pick a date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Before we finalize your appointment...`;
    schedule1_formCaption = `You have selected the following time slot:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Services`;
    services1_caption = `Experience top-tier services that seamlessly blend expertise with personalized care.`;
    services1_services = [
        {
            Name: "Service 1",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 2",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 3",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        {
            Name: "Service 1",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 2",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 3",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet our team`;
    team1_caption = ``;
    team1_teamMembers = [
        {
            Name: "John Doe",
            Position: "Expert",
            Image: teamImg1
        },
        {
            Name: "Jane Smith",
            Position: "Professional",
            Image: teamImg2
        },
        {
            Name: "Michael Johnson",
            Position: "Master Barber",
            Image: teamImg3
        },
        {
            Name: "Emily Adams",
            Position: "Hair Stylist",
            Image: teamImg4
        },
        {
            Name: "Daniel Clark",
            Position: "Beard Specialist",
            Image: teamImg1
        }
    ];
    // #endregion

} else if (c_businessType == "Hair Salon") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Hair Experience with Premium Services. Book Now!`;
    home1_caption1 = `From top-notch hair services to personalized care, our team provides an oasis of relaxation and style, ensuring you leave not just satisfied, but revitalized.`;
    home1_buttonText1 = `Book Now`;
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Hair Experience with Premium Services. Book Now!`;
    home2_heading1 = `Elevate your hair experience at ${c_businessName} - where excellence meets modern sophistication`;
    home2_text1 = `Step into the World of Hair Artistry, Where Our Skilled Professionals Are Masters of Personalization. With a Keen Eye for Detail and a Passion for Enhancing Your Unique Style, We Transform Hair Services into Personalized Masterpieces. Our Craftsmanship Reflects Your Individuality, Ensuring You Emerge Polished, Refined, and Infinitely Confident. Trust Us to Craft Your Signature Hair Experience, Redefining Your Journey with Precision and Panache.`;
    home2_buttonText1 = `Schedule Now`;
    home2_buttonNav1 = `schedule`;
    home2_heading2 = `Experience Hair Excellence Redefined at ${c_businessName}`;
    home2_text2 = `Experience the Epitome of Hair Care: From Intricate Hair Styling to Rejuvenating Hair Techniques, Our Team Curates an Oasis Where Relaxation and Style Converge. Every Moment in Our Hair Studio Is a Journey to Hair Revitalization. We Go Beyond Ordinary Hair Services, Ensuring You Depart Not Only Satisfied but also Renewed and Reinvigorated. Trust Us to Elevate Your Hair Experience Beyond the Ordinary.`;
    home2_buttonText2 = `Learn More`;
    home2_buttonNav2 = `blog`;
    // #endregion
    // #region HOME 3
    home3_heading1 = `Discover Hair Beauty with a Personal Touch!`;
    home3_caption1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.`;
    home3_buttonText1 = `Book Now`;
    home3_buttonNav1 = `schedule`;
    home3_subBlocks = [
        {
            Title: "Hair Styling 1",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Hair Styling 2",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Hair Coloring",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Hair Treatments",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Bridal Hair Services",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Men's Hair Services",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        }
    ];
    // #endregion
    // #region HOME 4
    home4_heading1 = "Elevate Your Look at Our Premier Hair Salon.";
    home4_caption1 = "Experience the art of hair styling and transformation at our salon. Our dedicated team of stylists is committed to helping you achieve your desired hair look.";
    home4_buttonText1 = "Book Your Appointment";
    home4_buttonNav1 = "schedule";
    home4_heading2 = "Unleash Your Hair's Potential.";
    home4_caption2 = "Discover a world of personalized hair care and styling at our salon. Our skilled stylists are passionate about enhancing your hair's natural beauty.";
    home4_subBlocks = [
        {
            Heading: "Tailored Hair Services for Every Occasion.",
            Caption: "From classic cuts to trendy styles, our salon offers a range of services to match your preferences and elevate your hair game."
        },
        {
            Heading: "Crafting Personalized Hair Transformations.",
            Caption: "Experience the perfect blend of creativity and expertise. Our stylists stay updated with the latest trends to provide you with hair transformations that suit you."
        },
        {
            Heading: "Relaxation and Renewal for Your Hair.",
            Caption: "Indulge in a revitalizing hair session that not only enhances your hair's appearance but also provides a moment of relaxation and self-care."
        },
        {
            Heading: "Confidence Through Stylish Hair.",
            Caption: "Step out with confidence, flaunting a hairstyle that aligns with your personality. Your satisfaction is our priority, and we're here to make you look your best."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Transform Your Look with Cutting-Edge Hairstyling at RadiantLocks. Schedule Your Appointment Today!`
    home5_caption1 = `From precision haircuts to rejuvenating treatments, our skilled stylists offer an oasis of relaxation and style, ensuring you leave with gorgeous hair and renewed confidence.`
    home5_buttonText1 = `Schedule Now`
    home5_buttonNav1 = `schedule`
    home5_heading2 = `Experience Hair Styling Revolutionized at RadiantLocks`
    home5_caption2 = `Experience the Epitome of Hair Transformation: From Intricate Haircraft to Rejuvenating Treatments, Our Stylists Create an Oasis Where Style and Renewal Converge.`
    home5_subBlocks = [
        {
            Heading: "Cutting-Edge Hairstyling",
            Caption: "Experience the expertise of our stylists who craft cutting-edge hairstyles tailored to your preferences. Whether you desire a classic look or a modern twist, we've got you covered."
        },
        {
            Heading: "Revitalizing Treatments",
            Caption: "Indulge in luxurious treatments that leave your hair looking stunning and feeling revitalized. Our skilled stylists create a hair experience like no other."
        },
        {
            Heading: "Contemporary Trends",
            Caption: "Stay ahead of hair trends with our stylists' creativity in crafting modern and contemporary styles that showcase your personality and keep you looking fabulous."
        },
        {
            Heading: "Relaxing Atmosphere",
            Caption: "Immerse yourself in a serene environment where the fusion of expert hair styling and a tranquil ambiance ensures your visit is an uplifting experience."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Our Hair Salon`;
    about1_heading1 = `The Art of Hair Excellence: Crafting Memorable Experiences at ${c_businessName}`;
    about1_text1 = `At ${c_businessName}, our story is one of passion, dedication, and a commitment to redefining hair experiences. Founded with the vision of creating more than just a hair salon, we've cultivated an environment where tradition and innovation coexist harmoniously. Our journey began with the belief that hair care is an art, and our skilled hair artists are the creators. With each interaction, we curate unique expressions of hair excellence that reflect the essence of our clients. The fusion of timeless techniques with contemporary trends is what sets us apart. From the moment you engage with us, you're not just a client – you're part of an experience waiting to be transformed into a masterpiece of hair satisfaction. Welcome to ${c_businessName}, where hair excellence is an art, and your journey becomes a part of ours.`;
    about1_heading2 = `Beyond Hair Expectations: Discovering Refinement and Relaxation at ${c_businessName}`;
    about1_text2 = `At ${c_businessName}, our hair services are more than just appointments; they are experiences meticulously designed to rejuvenate your hair and elevate your satisfaction. Our team of highly skilled hair artists brings expertise to every interaction, ensuring that every step is an artful endeavor. From classic hair designs that pay homage to time-honored traditions, to contemporary innovations that push the boundaries of modern hair art, our offerings cater to diverse preferences. Indulge in the luxury of a soothing hair experience that combines meticulous attention to detail with relaxation. We take pride in curating an atmosphere where you can unwind, connect, and leave not only satisfied but also invigorated. Step into a world where hair excellence becomes a statement, and each visit to ${c_businessName} is an opportunity to experience something exceptional.`;
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Hair Salon Awards`;
    awards1_caption = `Embrace a Tapestry of Hair Excellence as Our Salon Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Hair Services to Customer Satisfaction, We Proudly Stand as a Beacon of Hair Excellence. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Hair Experiences. Join us on this Journey of Achievement as We Continue to Define the Art of Hair Service.`;
    awards1_awards = [
        {
            Award: "Exemplary Hair Excellence Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional hair services that elevate customer experiences"
        },
        {
            Award: "Creative Hair Innovation of the Year",
            Year: "2022",
            Location: "City, State",
            Desc: "Honoring innovative approaches in hair styling that redefine industry standards"
        },
        {
            Award: "Salon of the Year",
            Year: "2021",
            Location: "City, State",
            Desc: "Celebrating outstanding achievements in providing comprehensive hair services with unmatched quality"
        },
        {
            Award: "Bridal Hair Service Excellence Award",
            Year: "2020",
            Location: "City, State",
            Desc: "Highlighting specialization and dedication to bridal hair services, providing unique, personalized experiences"
        },
        {
            Award: "Best Hair Treatments Provider",
            Year: "2019",
            Location: "City, State",
            Desc: "Acknowledging expertise in providing rejuvenating hair treatments that deliver transformational results"
        },
        {
            Award: "Men's Hair Services Excellence Award",
            Year: "2018",
            Location: "City, State",
            Desc: "Celebrating commitment to delivering personalized men's hair services with the perfect blend of style and precision"
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of ${c_businessName}`;
    bio1_heading1 = `Experience Hair Care Excellence at ${c_businessName}`;
    bio1_text1 = `Indulge in the exceptional world of hair care experiences at ${c_businessName}. Our skilled professionals are committed to crafting memorable interactions that enhance your hair's health and elevate your style. With precision techniques and modern expertise, we create personalized looks that align with your unique preferences. Whether it's a classic cut or a trendsetting transformation, your satisfaction is our priority. Join us for an experience that redefines your hair care journey.`;
    bio1_heading2 = `A Legacy of Styling Artistry and Care at ${c_businessName}`;
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable styling artistry and customer-focused care. From the moment you step through our doors, you're welcomed into a space that blends timeless traditions with contemporary flair. Our stylists are not just experts; they're artists who bring passion and precision to every service. Beyond the styling chair, we cultivate an environment of relaxation and rejuvenation, ensuring each visit is an escape from the ordinary. Experience the harmony of classic techniques and innovative trends at ${c_businessName}.`;
    bio1_heading3 = `Discover Personalized Hair Experiences at ${c_businessName}`;
    bio1_text3 = `Step into a world where every strand tells a story – welcome to ${c_businessName}. Our dedicated team understands that hair care is a personal journey of self-expression. With meticulous attention to your needs and a touch of our expertise, we design experiences that celebrate your individuality. From expertly executed styles to revitalizing treatments, your path to hair confidence begins here. Join us at ${c_businessName} and rediscover the art of personalized hair care tailored just for you.`;
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Hair Salon Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Discovering the Art of Hair Styling",
            Date: new Date(),
            Desc: "Step into the world of hair styling and explore the techniques that our skilled professionals use to create stunning looks. From classic elegance to trendy transformations, uncover the secrets behind crafting the perfect hairstyle.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Discovering the Art of Hair Styling
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the world of hair styling and explore the techniques that our skilled professionals use to create stunning looks. From classic elegance to trendy transformations, uncover the secrets behind crafting the perfect hairstyle.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Dive into the precision of each cut, the artistry of color, and the creativity that goes into every hairstyle. Learn about the interplay of face shape, personality, and current trends that guide our experts in delivering personalized hair experiences.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey through the evolution of hair styling, where tradition meets innovation. Explore how our professionals balance technique with individuality to craft looks that make a statement and leave clients feeling confident and beautiful.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Whether you're seeking a timeless appearance or a bold new look, our establishment combines skill with artistry to bring your vision to life. Experience the blend of craftsmanship and creativity that defines our commitment to making every client's hair dreams a reality.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Nurturing Your Hair: Essential Care Tips",
            Date: new Date(),
            Desc: "Delve into the realm of hair care and learn essential tips for maintaining the health and beauty of your locks. From proper washing routines to choosing the right products, discover the secrets to achieving lustrous and vibrant hair.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Nurturing Your Hair: Essential Care Tips
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Delve into the realm of hair care and learn essential tips for maintaining the health and beauty of your locks. From proper washing routines to choosing the right products, discover the secrets to achieving lustrous and vibrant hair.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore how to care for different hair types, from curly to straight, and how to combat common issues like frizz and damage. Join us in creating a hair care routine that elevates your locks to their full potential.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the transformative power of nourishing treatments and the science behind maintaining healthy hair from root to tip. Uncover the steps to achieving hair that shines with vitality and exudes natural beauty.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Whether you're a hair care novice or an experienced enthusiast, our blog provides insights and guidance to help you achieve the gorgeous, healthy hair you've always desired.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Exploring Hair Color Trends: From Subtle to Bold",
            Date: new Date(),
            Desc: "Embark on a journey through the world of hair color trends. Discover the spectrum of options, from subtle highlights to bold statement shades. Uncover the expertise required to transform hair color while preserving its integrity and shine.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Exploring Hair Color Trends: From Subtle to Bold
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Embark on a journey through the world of hair color trends. Discover the spectrum of options, from subtle highlights to bold statement shades. Uncover the expertise required to transform hair color while preserving its integrity and shine.
                    </p>
                    {/* More content here */}
                </div>
            )
        },
        // ... (other blogs)
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team!`;
    careers1_caption1 = `Explore exciting job opportunities and take the next step in your career. Apply now to be part of our talented team!`;
    careers1_positions = [
        {
            Title: "Hair Stylist",
            Desc: "Are you a skilled hair stylist with a passion for creating stunning hair transformations? Join our team and showcase your expertise in providing top-notch hair styling services to our valued clients. From classic looks to cutting-edge trends, you'll play a pivotal role in enhancing our clients' appearances and boosting their confidence."
        },
        {
            Title: "Apprentice Hair Stylist",
            Desc: "Ready to embark on a rewarding journey in the hair industry? Our apprentice position offers hands-on learning as you work alongside experienced hair stylists. Develop your skills while gaining insights into the art of delivering exceptional hair services. Grow your craft under the guidance of our skilled team and make your mark in the field."
        },
        {
            Title: "Client Experience Specialist",
            Desc: "Join us as a Client Experience Specialist and be the friendly face that welcomes clients, assists with appointments, and ensures their overall interaction is seamless and enjoyable. Your outstanding interpersonal skills and attention to detail will contribute to creating a welcoming and comfortable environment for all our valued clients."
        },
        {
            Title: "Hair Care Consultant",
            Desc: "As a Hair Care Consultant, you'll be the bridge between our clients' hair care preferences and our professionals' expertise. Guide clients in choosing the perfect hair service that suits their style. With your understanding of hair care trends and product knowledge, you'll provide valuable insights to help clients achieve their desired results."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Get in Touch`;
    contact1_mapSearchString = `Hair Salon Location`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Discover Our Special Offers`;
    coupons1_caption = `Unlock unbeatable savings and elevate your hair salon experience with our exclusive deals.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Special Offer 1",
            Details: "Experience our premium hair services with a special discount. Treat yourself to a unique hair care experience while enjoying unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "Special Offer 2",
            Details: "Elevate your hair care experience with a discounted service. Our skilled hair stylists will deliver results that leave you satisfied."
        },
        {
            Image: couponImg1,
            Coupon: "Special Offer 3",
            Details: "Complete your hair care experience with a bonus offer. Indulge in the luxury of a complimentary addition to your hair service."
        },
        {
            Image: couponImg1,
            Coupon: "Welcome Offer",
            Details: "Welcome to our hair salon! Enjoy a special offer on your first visit. Discover the art of personalized hair care and start your journey with us."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Hair Styling Showcase",
            Desc: "Join us for an exclusive hair styling showcase and workshop, where our experts will demonstrate the latest hair styling trends and techniques. Learn how to achieve the perfect hairstyle for any occasion and enjoy live demonstrations and personalized tips from our skilled team.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Hair Care Seminar",
            Desc: "Discover the art of hair care at our seminar and session. Our hair care professionals will share insights into maintaining and enhancing hair, followed by hands-on guidance. Whether you're a seasoned enthusiast or just starting your hair care journey, this event is for you.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Hair Salon Anniversary Celebration",
            Desc: "Celebrate with us in style! Enjoy a day filled with special offers, complimentary hair services, and festive treats. It's our way of thanking you for being part of our hair salon journey. Join us for a day of fun, hair care, and community.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Discover Our Hair Salon Features`;
    features1_features = [
        {
            Feature: "Expert Hair Care",
            Desc: "Our skilled hair stylists specialize in delivering exceptional results that enhance your unique hair care preferences. From classic to contemporary hairstyles, we tailor each experience to suit your needs, ensuring you leave satisfied.",
            Subject: "Hair Services"
        },
        {
            Feature: "Luxurious Hair Treatments",
            Desc: "Experience the luxury of our rejuvenating hair treatments. Our experts combine skill and attention to detail to provide a hair care result that leaves you refreshed and revitalized.",
            Subject: "Hair Treatments"
        },
        {
            Feature: "Hair Maintenance and Styling",
            Desc: "Whether you're seeking hair maintenance or a complete transformation, our professionals are experts in delivering hair care results that suit your style. From cuts to styling, we know the art of creating well-groomed and stylish hair.",
            Subject: "Hair Styling"
        },
        {
            Feature: "Personalized Consultations",
            Desc: "Every hair experience starts with a personalized consultation where our stylists take the time to understand your preferences. We believe in delivering tailored hair care experiences that cater to your individual needs and goals.",
            Subject: "Consultations"
        },
        {
            Feature: "Premium Hair Products",
            Desc: "Discover our range of premium hair care products that help you maintain your hair between salon visits. From high-quality hair products to styling tools, we offer the resources you need for vibrant and beautiful hair.",
            Subject: "Hair Products"
        },
        {
            Feature: "Relaxing Salon Ambiance",
            Desc: "Step into our salon and experience a welcoming atmosphere where you can relax and enjoy your hair care experience. Our inviting setting is designed to make you feel at ease while we deliver beautiful hair results.",
            Subject: "Salon Ambiance"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Explore Our Hair Salon Gallery`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `Our Hair Salon Journey`;
    history1_caption = `A Journey Through Hair Care`;
    history1_heading1 = `Experience the journey: Where modern meets tradition, redefining the hair care experience.`;
    history1_text1 = `Steeped in history, we've witnessed the evolution of hair care. From timeless hairstyles to contemporary trends, we've been the architects of hair transformation. We take pride in being the destination for those who seek authenticity in every aspect of their hair care journey. Our commitment to quality, precision, and personalized services stands strong.`;
    history1_heading2 = `More than what we do, we're a community cornerstone where stories are shared and hair care traditions thrive.`;
    history1_text2 = `As we celebrate our hair salon journey, we look forward to continuing our legacy in hair care. With a team of dedicated stylists and a passion for the craft, we remain the destination for those who appreciate excellence in hair care. Join us in shaping the future of hair care together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Find Our Hair Salon Locations`;
    locations1_locations = [
        {
            Location: "Main Hair Salon",
            Address: "123 Hair Street, Cityville, Hairland",
            Hours: "Mon - Fri: 9 AM - 7 PM\nSat: 10 AM - 8 PM\nSunday: Closed"
        },
        {
            Location: "Downtown Hair Studio",
            Address: "456 Hair Avenue, Townsville, Hairland",
            Hours: "Mon - Fri: 10 AM - 8 PM\nSat: 9 AM - 6 PM\nSunday: 11 AM - 5 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join our mailing list!`;
    mailingList1_caption = `Stay updated and never miss a newsletter. Enter your email to be part of our community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Menu`;
    menu1_menu = [
        {
            Name: "Service 1",
            Category: "Category 1",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$20"
                },
                {
                    Option: "Option 2",
                    Price: "$25"
                }
            ],
            Desc: "Description for Service 1."
        },
        {
            Name: "Service 2",
            Category: "Category 2",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$15"
                },
                {
                    Option: "Option 2",
                    Price: "$20"
                }
            ],
            Desc: "Description for Service 2."
        },
        {
            Name: "Service 3",
            Category: "Category 1",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$30"
                },
                {
                    Option: "Option 2",
                    Price: "$35"
                }
            ],
            Desc: "Description for Service 3."
        },
        {
            Name: "Service 4",
            Category: "Category 2",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$25"
                },
                {
                    Option: "Option 2",
                    Price: "$30"
                }
            ],
            Desc: "Description for Service 4."
        },
        {
            Name: "Service 5",
            Category: "Category 1",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$40"
                },
                {
                    Option: "Option 2",
                    Price: "$45"
                }
            ],
            Desc: "Description for Service 5."
        },
        {
            Name: "Service 6",
            Category: "Category 2",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$30"
                },
                {
                    Option: "Option 2",
                    Price: "$35"
                }
            ],
            Desc: "Description for Service 6."
        },
        // Add more services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `We only work with the best!`;
    partners1_caption = `Our partners make it all possible.`;
    partners1_partners = [
        {
            Partner: "Partner 1",
            Desc: "Description for Partner 1.",
            Link: "https://partner1.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 3",
            Desc: "Description for Partner 3.",
            Link: "https://partner3.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 4",
            Desc: "Description for Partner 4.",
            Link: "https://partner4.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 5",
            Desc: "Description for Partner 5.",
            Link: "https://partner5.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 6",
            Desc: "Description for Partner 6.",
            Link: "https://partner6.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Portfolio`;
    portfolio1_caption = ``;
    portfolio1_projects = [
        {
            Project: "Collection 1",
            Desc: "Explore our curated collection of the latest designs, from classic to modern.",
            Link: "https://portfolio.com/collection1",
            Image: portfolioImg1
        },
        {
            Project: "Guide 1",
            Desc: "Master the art of something with our comprehensive guide, covering everything from basics to advanced techniques.",
            Link: "https://portfolio.com/guide1",
            Image: portfolioImg1
        },
        {
            Project: "Events Recap",
            Desc: "Relive the excitement of our recent events through captivating photos and highlights.",
            Link: "https://portfolio.com/events",
            Image: portfolioImg1
        },
        {
            Project: "Showcase 1",
            Desc: "Witness the creativity and craftsmanship as we showcase some of our finest work.",
            Link: "https://portfolio.com/showcase1",
            Image: portfolioImg1
        },
        {
            Project: "Project 5",
            Desc: "Discover our latest project that pushes the boundaries of innovation.",
            Link: "https://portfolio.com/project5",
            Image: portfolioImg1
        },
        {
            Project: "Project 6",
            Desc: "Experience our special project that celebrates artistic expression and creativity.",
            Link: "https://portfolio.com/project6",
            Image: portfolioImg1
        },
        // Add more projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Refine Your Experience: Discover Our Packages and Pricing`;
    pricing1_prices = [
        {
            Price: "40",
            Desc: "Get the best with our top-notch service. Our experienced professionals will deliver results that impress."
        },
        {
            Price: "80",
            Desc: "Upgrade your experience with our comprehensive package. Get more value and enhanced results."
        },
        {
            Price: "120",
            Desc: "Indulge in luxury with our premium offering. Experience the epitome of excellence and satisfaction."
        },
        {
            Price: "60",
            Desc: "Experience a tailored service that caters to your unique needs. Our experts will make you look and feel amazing."
        },
        {
            Price: "90",
            Desc: "Achieve the look you've always wanted with our specialized package. Our stylists are dedicated to delivering perfection."
        },
        {
            Price: "150",
            Desc: "Immerse yourself in the ultimate beauty experience with our deluxe package. Unleash your radiance and confidence."
        },
        // Add more pricing options here...
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`;
    products1_caption = `Elevate Your Experience: Discover Premium Products`;
    products1_products = [
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg1
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg2
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg3
        },
        {
            Name: "Product 4",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg4
        },
        {
            Name: "Product 5",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg5
        },
        {
            Name: "Product 6",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg6
        },
        {
            Name: "Product 7",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg7
        },
        {
            Name: "Product 8",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg8
        },
        {
            Name: "Product 9",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg9
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get your quote today!`;
    quote1_caption = `Where excellence meets style. Step into our world, where quality is paramount and every experience is remarkable.`;
    quote1_heading1 = `Pick an option`;
    quote1_heading2 = `Tell us about your preferences`;
    quote1_services = [
        `Option 1`,
        `Option 2`,
        `Option 3`,
        `Option 4`,
        `Option 5`,
        `Option 6`
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "John Doe",
            Review: "Absolutely love this place! The atmosphere is fantastic, and the professionals are skilled. I always leave satisfied.",
            Rating: "5.0",
            Date: "08/05/2023"
        },
        {
            Reviewer: "Jane Smith",
            Review: "My go-to spot for quality. The attention to detail is incredible, and the staff is friendly. Highly recommend!",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Johnson",
            Review: "Outstanding service! The expertise of the team is unmatched. Every visit is a treat.",
            Rating: "4.9",
            Date: "09/15/2023"
        },
        {
            Reviewer: "Emily Adams",
            Review: "Exceptional experience every time. The attention to customer preferences is remarkable.",
            Rating: "5.0",
            Date: "10/03/2023"
        },
        {
            Reviewer: "Sarah Brown",
            Review: "I'm always impressed with the professionalism and results. Best salon experience ever.",
            Rating: "4.7",
            Date: "11/19/2023"
        },
        {
            Reviewer: "Robert Williams",
            Review: "The staff is highly skilled and friendly. I leave with a smile on my face every time.",
            Rating: "4.9",
            Date: "12/05/2023"
        },
        // Add more reviews here...
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Pick a date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Before we finalize your appointment...`;
    schedule1_formCaption = `You have selected the following time slot:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Services`;
    services1_caption = `Experience top-tier services that seamlessly blend expertise with personalized care.`;
    services1_services = [
        {
            Name: "Service 1",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 2",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 3",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        {
            Name: "Service 4",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 5",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 6",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet our team`;
    team1_caption = ``;
    team1_teamMembers = [
        {
            Name: "John Doe",
            Position: "Expert",
            Image: teamImg1
        },
        {
            Name: "Jane Smith",
            Position: "Professional",
            Image: teamImg2
        },
        {
            Name: "Michael Johnson",
            Position: "Master Barber",
            Image: teamImg3
        },
        {
            Name: "Emily Adams",
            Position: "Hair Stylist",
            Image: teamImg4
        },
        {
            Name: "Daniel Clark",
            Position: "Beard Specialist",
            Image: teamImg1
        },
        {
            Name: "Laura Wilson",
            Position: "Color Specialist",
            Image: teamImg2
        },
        // Add more team members here...
    ];
    // #endregion

} else if (c_businessType == "Tree Service") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Trees' Health with Professional Care. Schedule Now!`
    home1_caption1 = `From expert tree services to personalized attention, our team provides a haven of care and expertise, ensuring your trees thrive and flourish.`
    home1_buttonText1 = `Schedule Now`
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Trees' Health with Professional Care. Schedule Now!`
    home2_heading1 = `Elevate your trees' well-being with ${c_businessName} Tree Services - where expertise meets natural beauty`
    home2_text1 = `Step into the World of Arboreal Excellence, Where Our Skilled Arborists Are Artisans of Tree Health. With a Keen Eye for Detail and a Passion for Nurturing Your Trees, We Transform Services into Personalized Tree Care. Our Dedication Reflects Your Trees' Vitality, Ensuring They Thrive for Generations. Trust Us to Sculpt Your Signature Tree Service, Redefining Your Landscape with Precision and Care.`
    home2_buttonText1 = `Book a Consultation`
    home2_buttonNav1 = `schedule`
    home2_heading2 = `Experience Tree Care Redefined at ${c_businessName}`
    home2_text2 = `Experience the Epitome of Tree Expertise: From Prudent Pruning to Disease Diagnosis, Our Team Cultivates an Environment Where Health and Beauty Converge. Every Moment of Care Is a Step Toward Arboricultural Revitalization. We Go Beyond Ordinary Services, Ensuring Your Trees Flourish and Prosper. Trust Us to Elevate Your Trees' Well-being Beyond the Ordinary.`
    home2_buttonText2 = `Learn More`
    home2_buttonNav2 = `blog`
    // #endregion
    // #region HOME 3
    home3_heading1 = `Empower Your Landscape with Expert Tree Services!`
    home3_caption1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.`
    home3_buttonText1 = `Schedule Now`
    home3_buttonNav1 = `schedule`
    home3_subBlocks = [
        {
            Title: "Pruning Excellence",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Tree Health Care",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Emergency Tree Removal",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        }
    ]
    // #endregion
    // #region HOME 4
    home4_heading1 = "Your Trusted Partner for Professional Tree Services.";
    home4_caption1 = "Experience expert tree care and maintenance at our tree service company. Our dedicated team is committed to preserving the health and beauty of your trees.";
    home4_buttonText1 = "Schedule a Consultation";
    home4_buttonNav1 = "contact";
    home4_heading2 = "Nurturing Trees, Enhancing Landscapes.";
    home4_caption2 = "Discover a world of specialized tree care and landscaping services at our company. Our skilled arborists are passionate about creating healthy and thriving outdoor spaces.";
    home4_subBlocks = [
        {
            Heading: "Comprehensive Tree Care Services.",
            Caption: "From tree trimming and pruning to removal, our company offers a wide range of tree care services to address all of your tree-related needs."
        },
        {
            Heading: "Expertise in Arboriculture.",
            Caption: "Experience the perfect blend of scientific knowledge and practical skill. Our arborists are equipped to diagnose tree health issues and provide effective solutions."
        },
        {
            Heading: "Promoting Landscape Sustainability.",
            Caption: "Indulge in landscape services that not only enhance your property's aesthetics but also contribute to the overall sustainability and well-being of your outdoor environment."
        },
        {
            Heading: "Confidence in Healthy Trees.",
            Caption: "Rest assured that your trees are in capable hands. Our goal is to provide you with healthy, well-maintained trees that enhance the beauty and value of your property."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Enhance Your Outdoor Space with Professional Tree Services at Evergreen ArborCare. Get a Free Estimate Today!`
    home5_caption1 = `From precise tree trimming to comprehensive tree care, our skilled arborists offer a haven of expertise and care, ensuring your trees and landscape flourish with health and vitality.`
    home5_buttonText1 = `Get Quote`
    home5_buttonNav1 = `quote`
    home5_heading2 = `Experience Tree Care Elevated at Evergreen ArborCare`
    home5_caption2 = `Experience the Epitome of Tree Expertise and Preservation: From Precise Tree Trimming to Comprehensive Care, Our Arborists Cultivate a Sanctuary Where Expertise and Growth Intersect.`
    home5_subBlocks = [
        {
            Heading: "Expert Tree Services",
            Caption: "Experience the mastery of our arborists who provide a range of tree services tailored to your property's needs. Whether you require trimming, pruning, or removal, we have you covered."
        },
        {
            Heading: "Comprehensive Tree Care",
            Caption: "Indulge in tree care that ensures the health and longevity of your trees. Our skilled arborists offer solutions that promote growth, stability, and overall landscape well-being."
        },
        {
            Heading: "Sustainable Landscaping",
            Caption: "Stay ahead of landscaping trends with our arborists' expertise in creating sustainable and aesthetically pleasing outdoor spaces that enhance the beauty of your property."
        },
        {
            Heading: "Reliable Expertise",
            Caption: "Immerse yourself in reliable tree services and care where the fusion of expert knowledge and a commitment to environmental stewardship ensures your landscape thrives."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Us`
    about1_heading1 = `Nurturing Trees with Passion: Crafting a Greener World with ${c_businessName} Tree Services`
    about1_text1 = `At ${c_businessName} Tree Services, our journey is one of dedication, knowledge, and a commitment to enhancing the beauty of nature. Established with the vision of safeguarding trees and the environment, we've cultivated a haven where tradition and modern techniques coexist harmoniously. Our story began with the understanding that tree care is an art, and our skilled arborists are the artists. With each service, we cultivate unique expressions of health and vitality that resonate with the essence of nature. The fusion of time-tested practices with innovative approaches sets us apart. From the moment you engage with us, you're not just a client – you're a steward of nature, contributing to a greener world. Welcome to ${c_businessName} Tree Services, where care is an art, and your journey becomes part of our tree-loving legacy.`
    about1_heading2 = `Beyond Tree Service: Discovering the Harmony of Health and Aesthetics at ${c_businessName}`
    about1_text2 = `At ${c_businessName} Tree Services, our offerings extend beyond basic tree care; they are experiences meticulously designed to enrich your landscape and elevate your trees' well-being. Our team of highly skilled arborists brings expertise to every tree, ensuring that each branch receives artful attention. From timeless methods that honor age-old practices to innovative techniques that push the boundaries of arboriculture, our services cater to the diverse needs of your trees. Immerse yourself in the luxury of a service that combines meticulous care with aesthetic sensibilities. We take pride in nurturing an environment where trees can flourish, connecting with nature and leaving not just well-cared-for, but truly thriving. Step into a world where tree care becomes an elevated experience, and each engagement with ${c_businessName} Tree Services is a step toward a greener future.`
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Awards & Recognition`
    awards1_caption = `Celebrate a Canopy of Excellence as ${c_businessName} Tree Services Receives Accolades: Each Award a Testament to Our Dedication. From Exceptional Arboricultural Practices to Environmental Stewardship, We Proudly Stand as a Beacon of Arbor Excellence. These Honors Transcend Accolades; They're a Celebration of Our Dedication to Elevating Trees' Well-being. Join us in this Journey of Achievement as We Continue to Define the Art of Tree Care.`
    awards1_awards = [
        {
            Award: "Arbor Excellence Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional tree care practices that elevate tree health."
        },
        {
            Award: "Innovation in Arboriculture Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern tree care for remarkable results."
        },
        {
            Award: "Green Stewardship Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to providing environmentally-conscious tree care that fosters healthy ecosystems."
        },
        {
            Award: "Master Arborist Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in delivering arboricultural services with precision and care."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Explore the World of ${c_businessName} Tree Services`
    bio1_heading1 = `Experience Arbor Excellence at ${c_businessName}`
    bio1_text1 = `Immerse yourself in the exceptional world of tree care experiences at ${c_businessName} Tree Services. Our skilled arborists are dedicated to creating interactions that nurture your trees' health and vitality. With precision techniques and a love for nature, we offer services that reflect your trees' unique needs. Whether it's a classic approach or a modern innovation, your trees' well-being is our priority. Join us for an experience that goes beyond ordinary tree care.`
    bio1_heading2 = `A Legacy of Tree Care and Commitment at ${c_businessName}`
    bio1_text2 = `At ${c_businessName} Tree Services, we take pride in our legacy of impeccable tree care and environmental stewardship. From the moment you engage with us, you're embraced by a blend of tradition and contemporary tree care. Our arborists are not just experts; they are stewards of nature who deliver services with precision and passion. Beyond the service, we create a haven where trees can thrive, ensuring that every interaction is a nurturing experience. Experience the fusion of time-tested practices and innovation at ${c_businessName} Tree Services.`
    bio1_heading3 = `Discover Exceptional Tree Care at ${c_businessName}`
    bio1_text3 = `Step into a world where every branch matters – welcome to ${c_businessName} Tree Services. Our dedicated team understands that tree care is more than just a task; it's an expression of your trees' unique needs. With attentive care and our expertise, we create experiences that resonate with your trees' story. From meticulous pruning to revitalizing treatments, your journey to tree vitality begins here. Join us at ${c_businessName} Tree Services and rediscover the art of nurturing trees.`
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Exploring the Craft: The Art of Our Services",
            Date: new Date(),
            Desc: "Dive into the world of our exceptional services and the meticulous techniques that shape customer experiences. Discover how our skilled professionals transform interactions into personalized masterpieces, showcasing the blend of tradition and modernity.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Exploring the Craft: The Art of Our Services
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Dive into the world of our exceptional services and the meticulous techniques that shape customer experiences. Discover how our skilled professionals transform interactions into personalized masterpieces, showcasing the blend of tradition and modernity.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the precise handling of tools to the artful use of methods, every step is a testament to their expertise. Step behind the scenes and learn about the attention to detail that ensures each client walks out with an experience that's as unique as they are.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey through the history of our industry and its evolution into a harmonious blend of classic and contemporary approaches. Learn how innovation meets tradition at our establishment, where the mastery of technique meets the canvas of customer satisfaction.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the stories that services tell – tales of individuality, transformation, and satisfaction. Witness how, at our establishment, every service is a reflection of personality, offering customers an opportunity to express themselves and enjoy a unique experience.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Delve into the intersection of skill and style, where our professionals showcase their artistry. Learn about the tools of the trade, the commitment to excellence, and the fusion of tradition with contemporary trends, resulting in timeless yet modern experiences.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Creating a Tranquil Haven for Ultimate Relaxation",
            Date: new Date(),
            Desc: "Step into the calming environment of our establishment and experience our services as a rejuvenating escape. Explore the ambiance, relaxation techniques, and careful attention that turn every visit into a soothing journey of self-care.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Creating a Tranquil Haven for Ultimate Relaxation
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the calming environment of our establishment and experience our services as a rejuvenating escape. Explore the ambiance, relaxation techniques, and careful attention that turn every visit into a soothing journey of self-care.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the art of creating a space where services transcend mere routine. Discover how every element, from the comfortable surroundings to the soothing scents, contributes to a haven of relaxation that rejuvenates both body and spirit.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey that delves into the holistic experience of services at our establishment, where craftsmanship, ambiance, and personal care merge to create a refuge of tranquility.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience services as more than just a transaction, but a rejuvenating ritual that pampers both the body and the mind. Discover how our establishment elevates self-care to an art form, creating a haven where customers can unwind, refresh, and leave with renewed vitality.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the moment you enter, our establishment envelops you in an ambiance of serenity, where relaxation becomes the cornerstone of the experience. Immerse yourself in an oasis designed to soothe the senses and elevate the service journey beyond expectations.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Crafting Unique Experiences for Every Customer",
            Date: new Date(),
            Desc: "Celebrate the individuality that shines through every interaction with our business. Learn how our skilled professionals collaborate with customers to curate experiences that reflect their preferences, leaving a lasting impression with each unique encounter.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Crafting Unique Experiences for Every Customer
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Celebrate the individuality that shines through every interaction with our business. Learn how our skilled professionals collaborate with customers to curate experiences that reflect their preferences, leaving a lasting impression with each unique encounter.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the journey of transformation that unfolds in every customer interaction. Explore the consultations, insights, and techniques that shape each experience into a statement of individuality. Experience how our establishment empowers customers to embrace and showcase their distinct preferences.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From classic elegance to contemporary flair, our professionals tailor each encounter to match customer preferences. Join us in celebrating the art of crafting unique experiences that resonate with each individual, leaving them confident and ready to embrace their journey.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Uncover the secrets behind the magic that happens within the walls of our establishment. Witness the transformation from a simple interaction to a personalized masterpiece that not only enhances appearance but also captures the essence and distinct preferences of each customer.
                    </p>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the harmony between creativity and precision as our professionals deliver exceptional services. From precise techniques to rejuvenating experiences, our establishment offers a haven of relaxation, style, and self-expression, ensuring customers leave revitalized and ready to conquer the world.
                    </p>
                </div>
            )
        },
        // Include similar entries for other blog posts
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team!`;
    careers1_caption1 = `Explore the opportunities below and apply today to become part of our skilled team.`;
    careers1_positions = [
        {
            Title: "Arborist",
            Desc: "Are you an experienced arborist with a passion for trees? Join our team and showcase your expertise in tree care and maintenance. Your attention to detail and commitment to preserving trees will contribute to the well-being of our clients' landscapes."
        },
        {
            Title: "Tree Care Specialist",
            Desc: "Ready to embark on a rewarding journey in tree care? Our position offers hands-on experience in pruning, trimming, and diagnosing tree health. Learn and grow your skills as you work with our team of professionals to provide top-notch tree care services."
        },
        {
            Title: "Customer Relations Coordinator",
            Desc: "Join us as a Customer Relations Coordinator and be the friendly voice that assists clients with appointments and inquiries. Your exceptional communication skills and attention to detail will contribute to a positive client experience and the success of our tree services."
        },
        {
            Title: "Tree Health Consultant",
            Desc: "As a Tree Health Consultant, you'll be the expert in diagnosing tree health issues and providing solutions. Guide clients in understanding their trees' needs and recommend appropriate care plans. Your knowledge will help maintain healthy and thriving landscapes."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Get in Touch`;
    contact1_mapSearchString = `Tree Service Location`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Special Offers`;
    coupons1_caption = `Discover our latest deals and save on professional tree care services.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Tree Care Deal",
            Details: "Experience our expert tree care services with a special discount. Enhance the health and beauty of your trees while enjoying savings."
        },
        {
            Image: couponImg1,
            Coupon: "Tree Pruning Special",
            Details: "Elevate your landscape with our discounted tree pruning service. Our skilled team will ensure your trees look their best and thrive."
        },
        {
            Image: couponImg1,
            Coupon: "Free Consultation",
            Details: "Schedule a free consultation with our tree care specialists. Get personalized insights and recommendations for your tree care needs."
        },
        {
            Image: couponImg1,
            Coupon: "New Customer Offer",
            Details: "Welcome to our tree service! Enjoy a special offer on your first tree care appointment. Experience the difference our professionals make."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Events`;
    events1_events = [
        {
            Title: "Tree Care Workshop",
            Desc: "Join us for an informative workshop on tree care techniques. Learn about proper pruning, tree health, and maintenance practices from our expert arborists.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Tree Health Seminar",
            Desc: "Explore the world of tree health and preservation in our seminar. Our specialists will share insights into diagnosing tree issues and maintaining vibrant landscapes.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Arborist Meetup",
            Desc: "Connect with fellow tree enthusiasts at our arborist meetup. Enjoy tree-related discussions, live demonstrations, and the opportunity to network with professionals.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Discover Our Services`;
    features1_features = [
        {
            Feature: "Tree Care Expertise",
            Desc: "Our skilled arborists specialize in providing top-notch tree care services. From pruning to tree health assessments, we ensure your trees thrive and enhance your landscape.",
            Subject: "Tree Care"
        },
        {
            Feature: "Professional Pruning",
            Desc: "Experience the art of tree pruning with our expert team. We skillfully shape and trim trees to promote healthy growth and maintain their natural beauty.",
            Subject: "Pruning"
        },
        {
            Feature: "Tree Health Diagnostics",
            Desc: "Our specialists are adept at diagnosing tree health issues. We assess tree conditions, identify problems, and recommend appropriate care plans for optimal tree health.",
            Subject: "Tree Health"
        },
        {
            Feature: "Personalized Consultations",
            Desc: "Every tree care plan starts with a personalized consultation. Our arborists listen to your concerns and tailor solutions to meet your specific tree care needs.",
            Subject: "Consultation"
        },
        {
            Feature: "Quality Products",
            Desc: "Discover our range of quality tree care products. From fertilizers to tree care tools, we offer resources to help you maintain healthy trees between our visits.",
            Subject: "Products"
        },
        {
            Feature: "Expert Team",
            Desc: "Our team of skilled arborists is dedicated to providing exceptional tree care services. We bring expertise, passion, and professionalism to every tree care project.",
            Subject: "Team"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `View Our Work`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `It all started here..`;
    history1_caption = `A Journey Through Time`;
    history1_heading1 = `Experience the journey: Where modern meets tradition, redefining the experience.`;
    history1_text1 = `Steeped in history, we've witnessed the evolution. From timeless styles to contemporary trends, we've been the architects of transformation. We take pride in being the destination for those who seek authenticity in every aspect of their journey. Our commitment to quality, precision, and personalized service stands strong.`;
    history1_heading2 = `More than what we do, we're a community cornerstone where stories are shared and traditions thrive.`;
    history1_text2 = `As we celebrate our journey, we look forward to continuing our legacy. With a team of dedicated professionals and a passion for the craft, we remain the destination for those who appreciate excellence. Join us in shaping the future together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Find Us`;
    locations1_locations = [
        {
            Location: "Main Office",
            Address: "123 Tree Street, Arbor City",
            Hours: "Mon - Fri: 8 AM - 5 PM\nSat: 9 AM - 1 PM\nSunday: Closed"
        },
        {
            Location: "Branch Location",
            Address: "456 Oak Avenue, Forest Town",
            Hours: "Mon - Fri: 9 AM - 6 PM\nSat: 10 AM - 4 PM\nSunday: Closed"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Stay Connected`;
    mailingList1_caption = `Be the first to know about our tree care tips, promotions, and news. Join our mailing list today.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Services`;
    menu1_menu = [
        {
            Name: "Tree Pruning",
            Category: "Tree Care",
            Prices: [
                {
                    Option: "Basic",
                    Price: "$80"
                },
                {
                    Option: "Standard",
                    Price: "$120"
                },
                {
                    Option: "Premium",
                    Price: "$180"
                }
            ],
            Desc: "Expert tree pruning to promote growth and shape your trees."
        },
        {
            Name: "Tree Removal",
            Category: "Tree Care",
            Prices: [
                {
                    Option: "Small Tree",
                    Price: "$150"
                },
                {
                    Option: "Medium Tree",
                    Price: "$250"
                },
                {
                    Option: "Large Tree",
                    Price: "$400"
                }
            ],
            Desc: "Safe and efficient tree removal services."
        },
        // Add more tree services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `Our Partners`;
    partners1_caption = `We collaborate with the best in the industry to provide comprehensive tree care services.`;
    partners1_partners = [
        {
            Partner: "Local Nursery",
            Desc: "Our go-to source for healthy tree specimens for planting and replacement.",
            Link: "https://localnursery.com",
            Img: partnerImg1
        },
        {
            Partner: "Tree Equipment Supplier",
            Desc: "Our reliable partner for top-quality tree care equipment and tools.",
            Link: "https://treeequipmentsupplier.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Portfolio`;
    portfolio1_caption = `Explore our portfolio showcasing successful tree care projects and satisfied clients.`;
    portfolio1_projects = [
        {
            Project: "Tree Pruning Showcase",
            Desc: "View before-and-after photos of our expert tree pruning projects.",
            Link: "https://portfolio.com/pruningshowcase",
            Image: portfolioImg1
        },
        {
            Project: "Tree Removal Gallery",
            Desc: "See our team in action during safe and efficient tree removals.",
            Link: "https://portfolio.com/removalgallery",
            Image: portfolioImg1
        },
        // Add more portfolio projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Choose the right package for your tree care needs and budget.`;
    pricing1_prices = [
        {
            Price: "$120",
            Desc: "Basic Tree Care Package: Pruning and trimming for 2 trees."
        },
        {
            Price: "$240",
            Desc: "Standard Tree Care Package: Pruning, trimming, and tree health assessment for 4 trees."
        },
        {
            Price: "$360",
            Desc: "Premium Tree Care Package: Pruning, trimming, tree health assessment, and fertilization for 6 trees."
        }
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Tree Care Products`;
    products1_caption = `Enhance your tree care efforts with our premium selection of tree care products.`;
    products1_products = [
        {
            Name: "Tree Fertilizer",
            Desc: "Promote healthy tree growth with our nutrient-rich fertilizer.",
            Price: "$18.00",
            Image: productsImg1
        },
        {
            Name: "Pruning Shears",
            Desc: "Equip yourself with professional-grade pruning shears for precise cuts.",
            Price: "$25.00",
            Image: productsImg2
        },
        // Add more tree care products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get a Free Quote`;
    quote1_caption = `Expert tree care solutions tailored to your needs and preferences.`;
    quote1_heading1 = `Choose a Service`;
    quote1_heading2 = `Tell us about your trees`;
    quote1_services = [
        `Tree Pruning`,
        `Tree Removal`,
        `Tree Health Assessment`,
        `Stump Removal`,
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Customer Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "Sarah Johnson",
            Review: "Extremely satisfied with their tree pruning service. My trees look healthier and more beautiful.",
            Rating: "5.0",
            Date: "08/15/2023"
        },
        {
            Reviewer: "Mark Williams",
            Review: "Efficient tree removal team. They safely removed a large tree near my house.",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        // Add more customer reviews here...
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule an Appointment`;
    schedule1_heading1 = `Select a Service`;
    schedule1_heading2 = `Choose a Date`;
    schedule1_heading3 = `Choose a Time Slot`;
    schedule1_buttonText = `Request Appointment`;
    schedule1_formHeading = `Appointment Details`;
    schedule1_formCaption = `You have chosen the following appointment slot:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Our Services`;
    services1_caption = `Comprehensive tree care services designed to ensure healthy and vibrant trees.`;
    services1_services = [
        {
            Name: "Tree Pruning",
            Desc: "Expert pruning to maintain tree health and promote growth.",
            Image: servicesImg1
        },
        {
            Name: "Tree Removal",
            Desc: "Safe and efficient tree removal services.",
            Image: servicesImg2
        },
        {
            Name: "Tree Health Assessment",
            Desc: "Thorough assessment of tree health and recommendations for care.",
            Image: servicesImg3
        },
        // Add more tree services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Our Team`;
    team1_caption = `Meet the experienced and dedicated professionals behind our successful tree care projects.`;
    team1_teamMembers = [
        {
            Name: "John Arborist",
            Position: "Certified Arborist",
            Image: teamImg1
        },
        {
            Name: "Emma Tree Care Specialist",
            Position: "Tree Care Specialist",
            Image: teamImg2
        },
        // Add more team members here...
    ];
    // #endregion
} else if (c_businessType == "Tattoo") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Style with Exquisite Tattoo Artistry. Book Your Appointment Today!`;
    home1_caption1 = `From intricate tattoo designs to personalized care, our team creates a haven of self-expression and artistry, ensuring you leave not just satisfied, but adorned with stunning body art.`;
    home1_buttonText1 = `Book Now`;
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Style with Exquisite Tattoo Artistry. Book Your Appointment Today!`;
    home2_heading1 = `Elevate Your Style with Exquisite Tattoo Artistry at ${c_businessName}`;
    home2_text1 = `Step into the World of Creative Expression, Where Our Skilled Artists Are Masters of Personalization. With a Keen Eye for Detail and a Passion for Unveiling Your Unique Charm, We Transform Tattoos into Personalized Masterpieces. Our Art Reflects Your Individuality, Ensuring You Emerge Adorned, Refined, and Infinitely Confident. Trust Us to Craft Your Signature Tattoo, Redefining Your Look with Precision and Panache.`;
    home2_buttonText1 = `Schedule Now`;
    home2_buttonNav1 = `schedule`;
    home2_heading2 = `Discover Tattoo Artistry Redefined at ${c_businessName}`;
    home2_text2 = `Experience the Epitome of Artistic Precision and Expression: From Intricate Designs to Skillful Techniques, Our Artists Curate a Sanctuary Where Creativity and Style Converge. Every Moment in Our Studio Is a Journey of Self-Expression. We Go Beyond Ordinary Tattoos, Ensuring You Depart Not Only Satisfied but also Embellished with Unique Body Art. Trust Us to Elevate Your Style Beyond the Ordinary.`;
    home2_buttonText2 = `Learn More`;
    home2_buttonNav2 = `gallery`;
    // #endregion
    // #region HOME 3
    home3_heading1 = `Unlock the Power of Tattoo Artistry in the Palm of Your Hand!`;
    home3_caption1 = `Explore the World of Exquisite Tattoos and Transformative Body Artistry. Book Your Appointment Today.`;
    home3_buttonText1 = `Book Now`;
    home3_buttonNav1 = `schedule`;
    home3_subBlocks = [
        {
            Title: "Creative Expression",
            Text: "Explore our gallery of intricate designs and visionary tattoos that tell your story. Discover the artistry behind every ink stroke.",
            ButtonText: "See More",
            ButtonNav: "gallery"
        },
        {
            Title: "Tattoo Experience",
            Text: "Step into our studio where creativity thrives and personalized tattoos come to life. Learn about our process and artists.",
            ButtonText: "Learn More",
            ButtonNav: "about"
        },
        {
            Title: "Custom Consultation",
            Text: "Ready to make your mark? Schedule a consultation to discuss your unique tattoo ideas and bring them to life.",
            ButtonText: "Book Consultation",
            ButtonNav: "schedule"
        }
    ];
    // #endregion
    // #region HOME 4
    home4_heading1 = "Express Yourself Through Unique Tattoos at Our Tattoo Parlor.";
    home4_caption1 = "Experience the art of tattooing and self-expression at our parlor. Our talented tattoo artists are dedicated to creating tattoos that tell your story.";
    home4_buttonText1 = "Book Your Tattoo Session";
    home4_buttonNav1 = "schedule";
    home4_heading2 = "Inking Your Dreams into Reality.";
    home4_caption2 = "Discover a world of personalized tattoo designs and skilled artistry at our tattoo parlor. Our experienced artists are passionate about bringing your visions to life.";
    home4_subBlocks = [
        {
            Heading: "Custom Tattoo Designs for Every Style.",
            Caption: "From intricate designs to minimalistic tattoos, our parlor offers a variety of options to match your style and provide you with a truly unique tattoo."
        },
        {
            Heading: "Masters of Tattoo Artistry.",
            Caption: "Experience the perfect blend of artistic creativity and technical precision. Our tattoo artists are dedicated to creating tattoos that you'll cherish forever."
        },
        {
            Heading: "A Memorable Tattoo Experience.",
            Caption: "Indulge in an unforgettable tattooing session that not only adds to your body's art but also provides you with an enjoyable and safe experience."
        },
        {
            Heading: "Confidence Through Artful Tattoos.",
            Caption: "Step out with confidence, showcasing tattoos that reflect your individuality. Our priority is to ensure your satisfaction and provide you with ink that speaks to you."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Express Your Unique Identity with Exceptional Tattoos at InkMasters. Schedule Your Consultation Now!`
    home5_caption1 = `From intricate tattoo designs to meaningful art, our skilled tattoo artists offer a sanctuary of creativity and expression, ensuring you leave with a masterpiece that speaks to you.`
    home5_buttonText1 = `Book Consultation`
    home5_buttonNav1 = `schedule`
    home5_heading2 = `Experience Tattoo Artistry Elevated at InkMasters`
    home5_caption2 = `Experience the Epitome of Tattoo Craftsmanship and Expression: From Intricate Designs to Meaningful Art, Our Tattoo Artists Curate an Oasis Where Creativity and Identity Converge.`
    home5_subBlocks = [
        {
            Heading: "Custom Tattoo Designs",
            Caption: "Experience the artistic talent of our tattoo artists who create custom tattoo designs that reflect your individuality and tell your personal story."
        },
        {
            Heading: "Expert Tattoo Artistry",
            Caption: "Indulge in tattoo artistry that goes beyond the ordinary. Our skilled artists craft tattoos with precision and creativity, ensuring you walk away with a masterpiece."
        },
        {
            Heading: "Meaningful Tattoo Art",
            Caption: "Stay ahead of tattoo trends with our artists' expertise in translating your ideas into meaningful tattoos that leave a lasting impression and reflect your identity."
        },
        {
            Heading: "Creative Environment",
            Caption: "Immerse yourself in a welcoming atmosphere where the fusion of artistic expression and a comfortable ambiance ensures your tattoo experience is exceptional."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Our Tattoo Parlor`;
    about1_heading1 = `The Artistry of Tattoo Excellence: Crafting Unique Expressions at ${c_businessName}`;
    about1_text1 = `At ${c_businessName}, our journey is one of artistic passion, dedicated craftsmanship, and a commitment to redefining tattoo experiences. Founded with the vision of offering more than just ink, we've cultivated an environment where artistic tradition and innovative designs coexist harmoniously. Our story began with the belief that tattoos are more than skin-deep; they're personal statements. With every tattoo, we curate a unique expression of artistry that reflects the essence of our clients. The fusion of timeless tattoo techniques with contemporary designs is what sets us apart. From the moment you enter our parlor, you're not just a client – you're part of an artistic journey waiting to be transformed into a masterpiece of self-expression. Welcome to ${c_businessName}, where tattoos are an art form and your story becomes a part of our canvas.`;
    about1_heading2 = `Beyond Ink: Discovering Artistry and Expression at ${c_businessName}`;
    about1_text2 = `At ${c_businessName}, our tattoos are more than just designs; they are artistic expressions meticulously crafted to adorn your body with unique stories. Our artists are not just skilled; they are creators who bring their artistic vision to every tattoo. Beyond the ink, we create an atmosphere of creativity and comfort, ensuring that every interaction is an opportunity to bring your tattoo vision to life. Experience the fusion of tradition and innovation at ${c_businessName}, where each tattoo is a masterpiece of self-expression.`;
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Tattoo Excellence Recognized`;
    awards1_caption = `Embrace a Tapestry of Tattoo Excellence as Our Parlor Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Tattoo Artistry to Client Satisfaction, We Proudly Stand as a Beacon of Tattoo Excellence. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Tattoo Artistry. Join us on this Journey of Achievement as We Continue to Define the Art of Tattooing.`;
    awards1_awards = [
        {
            Award: "Exemplary Tattoo Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional tattoo designs that elevate client self-expression."
        },
        {
            Award: "Innovation in Tattoo Art Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern designs for remarkable tattoos."
        },
        {
            Award: "Ultimate Artistic Haven Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to providing a sanctuary of creativity where clients experience artistic transformation."
        },
        {
            Award: "Masterful Tattoo Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in delivering tattoos with precision, artistry, and personalization."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of Tattoo Artistry at ${c_businessName}`;
    bio1_heading1 = `Experience Artistic Excellence at ${c_businessName}`;
    bio1_text1 = `Immerse yourself in the unparalleled world of artistic experiences at ${c_businessName}. Our skilled artists are dedicated to creating memorable tattoos that enhance your self-expression and confidence. With precision techniques and a flair for creative designs, we curate tattoos that reflect your unique identity. Whether it's a traditional approach or an innovative design, your satisfaction is our top priority. Join us for an experience that goes beyond the ordinary.`;
    bio1_heading2 = `A Legacy of Tattoo Artistry and Innovation at ${c_businessName}`;
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable tattoo artistry and client-centric care. From the moment you step into our parlor, you're greeted with a blend of artistic tradition and contemporary designs. Our artists are not just professionals; they are creators who bring your tattoo vision to life with precision and passion. Beyond the design, we create an ambiance of creativity and comfort, ensuring that every interaction is an opportunity to express yourself through body art. Experience the fusion of tradition and innovation at ${c_businessName}.`;
    bio1_heading3 = `Discover Artistic Experiences at ${c_businessName}`;
    bio1_text3 = `Step into a world where every tattoo becomes a statement – welcome to ${c_businessName}. Our dedicated team understands that tattoos are more than designs; they're expressions of your identity. With careful attention to your ideas and a touch of our artistic expertise, we craft tattoos that resonate with your unique story. From meticulously delivered designs to expressive body art, your journey to self-expression begins here. Join us at ${c_businessName} and rediscover the art of tattooing.`;
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Tattoo Insights`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Unveiling the Art: The Stories Behind Our Tattoo Creations",
            Date: new Date(),
            Desc: "Embark on a journey into the heart of our tattoo studio, where ink becomes art and stories are etched into skin. Discover the meticulous techniques that shape our tattoo experiences, blending artistic tradition with contemporary flair.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Unveiling the Art: The Stories Behind Our Tattoo Creations
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Embark on a journey into the heart of our tattoo studio, where ink becomes art and stories are etched into skin. Discover the meticulous techniques that shape our tattoo experiences, blending artistic tradition with contemporary flair.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the precise lines of classic designs to the innovative twists of modern concepts, every stroke is a testament to our artists' expertise. Join us behind the scenes and learn about the attention to detail that ensures each client walks out not only adorned with body art but also with a unique story to tell.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Journey through the evolution of tattooing, where craftsmanship meets creativity. Discover how tradition and innovation merge seamlessly in our studio, creating timeless tattoos that bear the marks of both history and personal expression.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the narratives that tattoos convey – stories of individuality, transformation, and self-expression. Witness how, within our studio, every tattoo is a canvas of personality, offering customers a chance to showcase their identity and enjoy a unique experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Immerse yourself in the intersection of skill and art, where our artists showcase their creativity. Learn about the tools, the dedication to excellence, and the fusion of tradition with contemporary trends, resulting in tattoos that stand the test of time while reflecting modern sensibilities.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Creating a Serene Oasis of Tattoo Relaxation",
            Date: new Date(),
            Desc: "Step into the serene environment of our tattoo studio and experience our services as a tranquil escape. Explore the ambiance, relaxation techniques, and thoughtful care that transform each session into a soothing journey of self-rejuvenation.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Creating a Serene Oasis of Tattoo Relaxation
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the serene environment of our tattoo studio and experience our services as a tranquil escape. Explore the ambiance, relaxation techniques, and thoughtful care that transform each session into a soothing journey of self-rejuvenation.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the art of creating a space where tattoos transcend mere ink. Unveil the elements that contribute to an oasis of relaxation, from the calming atmosphere to the gentle fragrances, ensuring each visit is not only about body art but also about self-care.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey that delves into the holistic experience of tattoos in our studio, where craftsmanship, ambiance, and personal care merge to offer a refuge of tranquility and artistic expression.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience our tattoo sessions as more than just procedures; they're a rejuvenating ritual that pampers both the body and the spirit. Learn how our studio elevates self-care to an art form, creating an oasis where clients can unwind, rejuvenate, and leave with renewed vitality.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the moment you step in, our studio envelops you in an atmosphere of serenity, where relaxation becomes the foundation of your tattoo journey. Immerse yourself in a sanctuary designed to soothe the senses and elevate the tattoo experience beyond expectations.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Crafting Personalized Stories: The Heart of Tattoo Artistry",
            Date: new Date(),
            Desc: "Celebrate the uniqueness that shines through each customer's journey with our studio. Discover how our skilled artists collaborate to create tattoo experiences that reflect individual preferences, leaving an indelible mark with each special encounter.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Crafting Personalized Stories: The Heart of Tattoo Artistry
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Celebrate the uniqueness that shines through each customer's journey with our studio. Discover how our skilled artists collaborate to create tattoo experiences that reflect individual preferences, leaving an indelible mark with each special encounter.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the transformative journey that unfolds in every customer interaction. Learn about the consultations, insights, and techniques that shape each tattoo into a personal narrative. Experience how our studio empowers clients to embrace and showcase their distinct preferences.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From classic designs to contemporary expressions, our artists tailor each encounter to match individual tastes. Join us in celebrating the art of crafting personalized stories that resonate with each person, leaving them empowered and ready to embark on their journey with confidence.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Uncover the magic that takes place within the walls of our studio. Witness the journey from a simple consultation to a personalized masterpiece that not only enhances appearance but also captures the essence and individuality of each client.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the harmony of creativity and precision as our artists deliver exceptional tattoos. From intricate designs to rejuvenating experiences, our studio offers an oasis of artistic expression, ensuring clients leave with an extraordinary tattoo and a sense of empowered self-expression.
                    </p>
                </div>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team`;
    careers1_caption1 = `Explore exciting opportunities and apply with ease. Your journey with us starts here.`;
    careers1_positions = [
        {
            Title: "Tattoo Artist",
            Desc: "Are you a skilled artist with a passion for tattoos? Join our team and showcase your creativity in providing unique tattoo experiences for our clients. With a focus on precision and innovation, you'll play a vital role in delivering exceptional body art."
        },
        {
            Title: "Apprentice Tattoo Artist",
            Desc: "Ready to learn the art of tattooing? Our apprentice position offers hands-on training as you work alongside experienced tattoo artists. Develop your skills while gaining insights into the world of tattooing and make your mark in the industry."
        },
        {
            Title: "Client Relations Specialist",
            Desc: "Become the face of our studio as a Client Relations Specialist. Welcome clients, assist with appointments, and ensure a seamless experience. Your excellent interpersonal skills will create a warm and inviting environment for our valued clients."
        },
        {
            Title: "Tattoo Consultation Expert",
            Desc: "Join us as a Tattoo Consultation Expert and help clients bring their tattoo visions to life. Guide clients in choosing the perfect design and style. Your understanding of industry trends and design expertise will ensure clients leave with their dream tattoos."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Stay Connected`;
    contact1_mapSearchString = `Our Tattoo Studio Location`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Special Offers`;
    coupons1_caption = `Unlock exclusive deals and elevate your tattoo experience with our limited-time offers.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Ink Deal",
            Details: "Experience our premium tattoo service with a special discount. Express yourself with unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "Artistry Package",
            Details: "Elevate your tattoo experience with a discounted package. Our skilled artists will bring your ideas to life."
        },
        {
            Image: couponImg1,
            Coupon: "Tattoo Add-On",
            Details: "Complete your tattoo journey with a bonus offer. Enhance your design with a complimentary addition."
        },
        {
            Image: couponImg1,
            Coupon: "First Ink Offer",
            Details: "Welcome to our studio! Enjoy a special offer on your first tattoo. Start your tattoo journey with us."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Tattoo Art Showcase",
            Desc: "Join us for an exclusive tattoo art showcase, where our artists will demonstrate the latest techniques. Learn about the art of tattoos and enjoy live demonstrations from our skilled team.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Tattoo Care Seminar",
            Desc: "Discover the art of tattoo care at our seminar. Our professionals will share insights into maintaining and enhancing your tattoos. Whether you're a tattoo enthusiast or new to ink, this event is for you.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Tattoo Anniversary Celebration",
            Desc: "Celebrate with us! Enjoy special offers, complimentary touch-ups, and festive treats. Join us for a day of tattoo artistry, fun, and community.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Discover Our Features`;
    features1_features = [
        {
            Feature: "Custom Tattoos",
            Desc: "Our skilled tattoo artists specialize in creating custom designs that reflect your individuality. From intricate patterns to bold statements, we bring your ideas to life.",
            Subject: "Tattoo Artistry"
        },
        {
            Feature: "Tattoo Care",
            Desc: "Experience the luxury of tattoo care with our aftercare services. We provide guidance on how to keep your tattoos looking vibrant and well-maintained.",
            Subject: "Tattoo Care"
        },
        {
            Feature: "Consultation",
            Desc: "Start your tattoo journey with a personalized consultation. Our experts listen to your ideas and provide insights to ensure your tattoo design matches your vision.",
            Subject: "Consultation"
        },
        {
            Feature: "Clean & Safe Studio",
            Desc: "Our studio adheres to strict hygiene and safety protocols. We ensure a clean environment where you can get your tattoo with confidence.",
            Subject: "Hygiene"
        },
        {
            Feature: "Tattoo Removal",
            Desc: "We offer tattoo removal services using advanced techniques. If you're looking to modify or remove an existing tattoo, we have you covered.",
            Subject: "Modification"
        },
        {
            Feature: "Community Events",
            Desc: "Join us for tattoo art showcases, workshops, and community events. Connect with fellow enthusiasts and artists, and celebrate the art of tattoos.",
            Subject: "Community"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Gallery`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `Our Journey`;
    history1_caption = `A Legacy in Ink`;
    history1_heading1 = `From Tradition to Modern Artistry`;
    history1_text1 = `Our journey in tattoo artistry spans decades, evolving from classic designs to contemporary masterpieces. We take pride in being a part of your stories, turning your ideas into stunning tattoos that reflect your journey.`;
    history1_heading2 = `Celebrating Our Community`;
    history1_text2 = `As we look back on our legacy, we're grateful for our clients who have trusted us with their stories. We're committed to providing exceptional tattoo experiences that inspire confidence and self-expression.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Our Studios`;
    locations1_locations = [
        {
            Location: "Downtown Studio",
            Address: "123 Ink Street, Cityville",
            Hours: "Mon - Fri: 10 AM - 8 PM\nSat: 11 AM - 7 PM\nSunday: Closed"
        },
        {
            Location: "Art District Studio",
            Address: "456 Tattoo Avenue, Creativille",
            Hours: "Mon - Fri: 11 AM - 9 PM\nSat: 10 AM - 6 PM\nSunday: 12 PM - 5 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join Our Newsletter`;
    mailingList1_caption = `Stay updated on tattoo trends, events, and special offers. Subscribe to our newsletter and be part of our vibrant community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Menu`;
    menu1_menu = [
        {
            Name: "Service 1",
            Category: "Category 1",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$20"
                },
                {
                    Option: "Option 2",
                    Price: "$25"
                }
            ],
            Desc: "Description for Service 1."
        },
        {
            Name: "Service 2",
            Category: "Category 2",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$15"
                },
                {
                    Option: "Option 2",
                    Price: "$20"
                }
            ],
            Desc: "Description for Service 2."
        },
        // Add more services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `Our Partners`;
    partners1_caption = `Collaborating with the Best`;
    partners1_partners = [
        {
            Partner: "Partner 1",
            Desc: "Description for Partner 1.",
            Link: "https://partner1.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 3",
            Desc: "Description for Partner 3.",
            Link: "https://partner3.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 4",
            Desc: "Description for Partner 4.",
            Link: "https://partner4.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Portfolio`;
    portfolio1_caption = `Explore Our Work`;
    portfolio1_projects = [
        {
            Project: "Collection 1",
            Desc: "Discover our curated collection of designs, from classic to contemporary.",
            Link: "https://portfolio.com/collection1",
            Image: portfolioImg1
        },
        {
            Project: "Guide 1",
            Desc: "Master the art with our comprehensive guide, covering basics to advanced techniques.",
            Link: "https://portfolio.com/guide1",
            Image: portfolioImg1
        },
        {
            Project: "Events Recap",
            Desc: "Relive the excitement of our recent events through captivating photos and highlights.",
            Link: "https://portfolio.com/events",
            Image: portfolioImg1
        },
        {
            Project: "Showcase 1",
            Desc: "Witness creativity and craftsmanship as we showcase our finest work.",
            Link: "https://portfolio.com/showcase1",
            Image: portfolioImg1
        },
        // Add more projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Discover Our Packages and Pricing`;
    pricing1_prices = [
        {
            Price: "40",
            Desc: "Experience our top-notch service delivered by skilled professionals."
        },
        {
            Price: "80",
            Desc: "Upgrade your experience with our comprehensive package and enhanced results."
        },
        {
            Price: "120",
            Desc: "Indulge in luxury with our premium offering for the epitome of excellence."
        },
        // Add more pricing options here...
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`;
    products1_caption = `Explore Premium Products`;
    products1_products = [
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product for superior performance.",
            Price: "18.00",
            Image: productsImg1
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients.",
            Price: "12.00",
            Image: productsImg2
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg3
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get a Quote`;
    quote1_caption = `Where Excellence Meets Style`;
    quote1_heading1 = `Choose an Option`;
    quote1_heading2 = `Tell Us Your Preferences`;
    quote1_services = [
        "Option 1",
        "Option 2",
        "Option 3",
        "Option 4",
        // Add more options here...
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "John Doe",
            Review: "Absolutely love this place! The atmosphere is fantastic, and the professionals are skilled. I always leave satisfied.",
            Rating: "5.0",
            Date: "08/05/2023"
        },
        {
            Reviewer: "Jane Smith",
            Review: "My go-to spot for quality. The attention to detail is incredible, and the staff is friendly. Highly recommend!",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Johnson",
            Review: "Outstanding service! The expertise of the team is unmatched. Every visit is a treat.",
            Rating: "4.9",
            Date: "09/15/2023"
        },
        {
            Reviewer: "Emily Adams",
            Review: "Exceptional experience every time. The attention to customer preferences is remarkable.",
            Rating: "5.0",
            Date: "10/03/2023"
        },
        // Add more reviews here...
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule an Appointment`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Choose a Date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Before We Confirm Your Appointment...`;
    schedule1_formCaption = `You've Selected the Following Time Slot:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Services`;
    services1_caption = `Explore Our Premium Services`;
    services1_services = [
        {
            Name: "Service 1",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 2",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 3",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet Our Team`;
    team1_caption = `Discover Our Talented Team`;
    team1_teamMembers = [
        {
            Name: "John Doe",
            Position: "Expert",
            Image: teamImg1
        },
        {
            Name: "Jane Smith",
            Position: "Professional",
            Image: teamImg2
        },
        {
            Name: "Michael Johnson",
            Position: "Master Barber",
            Image: teamImg3
        },
        // Add more team members here...
    ];
    // #endregion
} else if (c_businessType == "Fashion") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Style with Exquisite Fashion Artistry. Explore Our Collection Today!`;
    home1_caption1 = `From timeless designs to personalized styling, our team creates a haven of self-expression and elegance, ensuring you step out not just satisfied, but adorned with stunning fashion choices.`;
    home1_buttonText1 = `Explore Collection`;
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Style with Exquisite Fashion Artistry. Explore Our Collection Today!`;
    home2_heading1 = `Elevate Your Style with Exquisite Fashion Artistry at ${c_businessName}`;
    home2_text1 = `Step into the world of creative expression, where our skilled designers are masters of personalization. With a keen eye for detail and a passion for unveiling your unique charm, we transform fashion into personalized masterpieces. Our designs reflect your individuality, ensuring you emerge adorned, refined, and infinitely confident. Trust us to craft your signature style, redefining your look with precision and panache.`;
    home2_buttonText1 = `Discover More`;
    home2_buttonNav1 = `collection`;
    home2_heading2 = `Discover Fashion Artistry Redefined at ${c_businessName}`;
    home2_text2 = `Experience the epitome of artistic precision and expression: from intricate designs to skillful tailoring, our designers curate a sanctuary where creativity and style converge. Every moment with our fashion pieces is a journey of self-expression. We go beyond ordinary clothing, ensuring you depart not only satisfied but also embellished with unique fashion choices. Trust us to elevate your style beyond the ordinary.`;
    home2_buttonText2 = `Learn More`;
    home2_buttonNav2 = `about`;
    // #endregion
    // #region HOME 3
    home3_heading1 = `Elevate Your Fashion Sense with Our Exquisite Collection!`;
    home3_caption1 = `Explore the world of exquisite fashion and transform your wardrobe. Discover our collection today.`;
    home3_buttonText1 = `Explore Collection`;
    home3_buttonNav1 = `collection`;
    home3_subBlocks = [
        {
            Title: "Timeless Elegance",
            Text: "Explore our collection of intricate designs and visionary fashion that tells your story. Discover the artistry behind every stitch.",
            ButtonText: "See More",
            ButtonNav: "collection"
        },
        {
            Title: "Fashion Experience",
            Text: "Step into our studio where creativity thrives and personalized styling comes to life. Learn about our process and designers.",
            ButtonText: "Learn More",
            ButtonNav: "about"
        },
        {
            Title: "Custom Consultation",
            Text: "Ready to elevate your style? Schedule a consultation to discuss your unique fashion ideas and bring them to life.",
            ButtonText: "Book Consultation",
            ButtonNav: "schedule"
        }
    ];
    // #endregion
    // #region HOME 4
    home4_heading1 = "Elevate Your Style with Trendsetting Fashion.";
    home4_caption1 = "Experience the world of fashion and style at its finest. Our dedicated team is committed to helping you discover and embrace your unique fashion statement.";
    home4_buttonText1 = "Explore Our Collections";
    home4_buttonNav1 = "products";
    home4_heading2 = "Defining Fashion, Creating Trends.";
    home4_caption2 = "Discover a curated selection of fashion-forward pieces and styling at our establishment. Our fashion experts are passionate about transforming your look.";
    home4_subBlocks = [
        {
            Heading: "Curated Fashion Selections.",
            Caption: "From classic essentials to the latest trends, our establishment offers a diverse range of fashion pieces to cater to every taste and occasion."
        },
        {
            Heading: "Masters of Styling.",
            Caption: "Experience the perfect blend of artful styling and attention to detail. Our fashion experts are here to help you put together outfits that reflect your personality."
        },
        {
            Heading: "Elevating Your Wardrobe.",
            Caption: "Indulge in a fashion experience that not only adds new pieces to your wardrobe but also enhances your understanding of style and self-expression."
        },
        {
            Heading: "Confidence Through Fashion.",
            Caption: "Step out with confidence, showcasing outfits that empower you and make a statement. Our goal is to provide you with fashion that speaks to your individuality."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Elevate Your Style with Trendsetting Fashion at VogueStyle. Explore Our Collections Today!`
    home5_caption1 = `From runway-inspired outfits to everyday essentials, our fashion experts offer a sanctuary of style and sophistication, ensuring you leave with an ensemble that makes a statement.`
    home5_buttonText1 = `Explore Collections`
    home5_buttonNav1 = `products`
    home5_heading2 = `Experience Fashion Elevated at VogueStyle`
    home5_caption2 = `Experience the Epitome of Style and Elegance: From Trendsetting Fashion to Classic Elegance, Our Fashion Experts Curate an Oasis Where Style and Sophistication Converge.`
    home5_subBlocks = [
        {
            Heading: "Runway-Inspired Fashion",
            Caption: "Experience the expertise of our fashion experts who curate runway-inspired outfits that align with the latest trends and showcase your unique style."
        },
        {
            Heading: "Classic Elegance",
            Caption: "Indulge in timeless pieces that exude elegance and charm. Our fashion experts offer a range of classic styles that stand the test of time and elevate your wardrobe."
        },
        {
            Heading: "Personalized Styling",
            Caption: "Stay ahead of fashion trends with our experts' creativity in creating personalized ensembles that reflect your personality and make a lasting impression."
        },
        {
            Heading: "Sophisticated Atmosphere",
            Caption: "Immerse yourself in a stylish ambiance where the blend of expert fashion curation and a chic environment ensures your shopping experience is elevated."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Our Fashion Studio`;
    about1_heading1 = `The Artistry of Fashion Excellence: Crafting Unique Styles at ${c_businessName}`;
    about1_text1 = `At ${c_businessName}, our journey is one of artistic passion, dedicated craftsmanship, and a commitment to redefining fashion experiences. Founded with the vision of offering more than just clothing, we've cultivated an environment where artistic tradition and innovative designs coexist harmoniously. Our story began with the belief that fashion is more than fabric; it's personal statements. With every design, we curate a unique expression of artistry that reflects the essence of our clients. The fusion of timeless tailoring techniques with contemporary designs is what sets us apart. From the moment you explore our studio, you're not just a customer – you're part of an artistic journey waiting to be transformed into a masterpiece of style. Welcome to ${c_businessName}, where fashion is an art form and your story becomes a part of our creations.`;
    about1_heading2 = `Beyond Fabric: Discovering Artistry and Expression at ${c_businessName}`;
    about1_text2 = `At ${c_businessName}, our fashion pieces are more than just garments; they are artistic expressions meticulously crafted to adorn your body with unique stories. Our designers are not just professionals; they are creators who bring their artistic vision to every piece. Beyond the fabric, we create an atmosphere of creativity and elegance, ensuring that every interaction is an opportunity to bring your fashion vision to life. Experience the fusion of tradition and innovation at ${c_businessName}, where each piece is a masterpiece of self-expression.`;
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Fashion Excellence Recognized`;
    awards1_caption = `Embrace a Tapestry of Fashion Excellence as Our Studio Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Design Artistry to Client Satisfaction, We Proudly Stand as a Beacon of Fashion Excellence. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Fashion Artistry. Join us on this Journey of Achievement as We Continue to Define the Art of Fashion.`;
    awards1_awards = [
        {
            Award: "Exemplary Design Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional fashion designs that elevate client self-expression."
        },
        {
            Award: "Innovation in Fashion Design Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern designs for remarkable fashion pieces."
        },
        {
            Award: "Ultimate Artistic Haven Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to providing a sanctuary of creativity where clients experience artistic transformation."
        },
        {
            Award: "Masterful Design Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in delivering fashion pieces with precision, artistry, and personalization."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of Fashion Artistry at ${c_businessName}`;
    bio1_heading1 = `Experience Artistic Excellence at ${c_businessName}`;
    bio1_text1 = `Immerse yourself in the unparalleled world of artistic experiences at ${c_businessName}. Our skilled designers are dedicated to creating memorable fashion pieces that enhance your self-expression and confidence. With precision tailoring and a flair for creative designs, we curate styles that reflect your unique identity. Whether it's a traditional approach or an innovative design, your satisfaction is our top priority. Join us for an experience that goes beyond the ordinary.`;
    bio1_heading2 = `A Legacy of Fashion Artistry and Innovation at ${c_businessName}`;
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable fashion artistry and client-centric care. From the moment you explore our collection, you're greeted with a blend of artistic tradition and contemporary designs. Our designers are not just professionals; they are creators who bring your fashion vision to life with precision and passion. Beyond the design, we create an ambiance of creativity and elegance, ensuring that every interaction is an opportunity to express yourself through style. Experience the fusion of tradition and innovation at ${c_businessName}.`;
    bio1_heading3 = `Discover Artistic Experiences at ${c_businessName}`;
    bio1_text3 = `Step into a world where every fashion piece becomes a statement – welcome to ${c_businessName}. Our dedicated team understands that fashion is more than fabric; it's expressions of your identity. With careful attention to your ideas and a touch of our artistic expertise, we craft styles that resonate with your unique story. From meticulously delivered designs to expressive fashion choices, your journey to self-expression begins here. Join us at ${c_businessName} and rediscover the art of fashion.`;
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Fashion Insights`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Unveiling the Art: Stories Behind Our Fashion Creations",
            Date: new Date(),
            Desc: "Embark on a journey into the heart of our fashion studio, where fabrics become art and stories are woven into every stitch. Discover the meticulous techniques that shape our fashion experiences, blending artistic tradition with contemporary flair.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Unveiling the Art: Stories Behind Our Fashion Creations
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Embark on a journey into the heart of our fashion studio, where fabrics become art and stories are woven into every stitch. Discover the meticulous techniques that shape our fashion experiences, blending artistic tradition with contemporary flair.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the precise cuts of classic designs to the innovative twists of modern concepts, every detail is a testament to our designers' expertise. Join us behind the scenes and learn about the attention to detail that ensures each customer leaves not only adorned in fashion but also with a unique story to tell.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Journey through the evolution of fashion, where craftsmanship meets creativity. Discover how tradition and innovation merge seamlessly in our studio, creating timeless styles that bear the marks of both history and personal expression.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the narratives that fashion conveys – stories of individuality, transformation, and self-expression. Witness how, within our studio, every piece is a canvas of personality, offering customers a chance to showcase their identity and enjoy a unique experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Immerse yourself in the intersection of skill and art, where our designers showcase their creativity. Learn about the tools, the dedication to excellence, and the fusion of tradition with contemporary trends, resulting in styles that stand the test of time while reflecting modern sensibilities.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Creating a Serene Oasis of Fashion Relaxation",
            Date: new Date(),
            Desc: "Step into the serene environment of our fashion studio and experience our services as a tranquil escape. Explore the ambiance, relaxation techniques, and thoughtful care that transform each session into a soothing journey of self-rejuvenation.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Creating a Serene Oasis of Fashion Relaxation
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the serene environment of our fashion studio and experience our services as a tranquil escape. Explore the ambiance, relaxation techniques, and thoughtful care that transform each session into a soothing journey of self-rejuvenation.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the art of creating a space where fashion transcends mere fabrics. Unveil the elements that contribute to an oasis of relaxation, from the calming atmosphere to the gentle fragrances, ensuring each visit is not only about style but also about self-care.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey that delves into the holistic experience of fashion in our studio, where craftsmanship, ambiance, and personal care merge to offer a refuge of tranquility and artistic expression.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience our fashion sessions as more than just procedures; they're a rejuvenating ritual that pampers both the body and the spirit. Learn how our studio elevates self-care to an art form, creating an oasis where clients can unwind, rejuvenate, and leave with renewed vitality.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the moment you step in, our studio envelops you in an atmosphere of serenity, where relaxation becomes the foundation of your style journey. Immerse yourself in a sanctuary designed to soothe the senses and elevate the fashion experience beyond expectations.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Crafting Personalized Stories: The Heart of Fashion Artistry",
            Date: new Date(),
            Desc: "Celebrate the uniqueness that shines through each customer's journey with our studio. Discover how our skilled designers collaborate to create fashion experiences that reflect individual preferences, leaving an indelible mark with each special encounter.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Crafting Personalized Stories: The Heart of Fashion Artistry
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Celebrate the uniqueness that shines through each customer's journey with our studio. Discover how our skilled designers collaborate to create fashion experiences that reflect individual preferences, leaving an indelible mark with each special encounter.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the transformative journey that unfolds in every customer interaction. Learn about the consultations, insights, and techniques that shape each fashion piece into a personal narrative. Experience how our studio empowers clients to embrace and showcase their distinct preferences.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From classic designs to contemporary expressions, our designers tailor each encounter to match individual tastes. Join us in celebrating the art of crafting personalized stories that resonate with each person, leaving them empowered and ready to embark on their journey with confidence.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Uncover the magic that takes place within the walls of our studio. Witness the journey from a simple consultation to a personalized masterpiece that not only enhances appearance but also captures the essence and individuality of each client.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the harmony of creativity and precision as our designers deliver exceptional fashion pieces. From intricate designs to rejuvenating experiences, our studio offers an oasis of artistic expression, ensuring clients leave with extraordinary fashion items and a sense of empowered self-expression.
                    </p>
                </div>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team`;
    careers1_caption1 = `Explore exciting opportunities and apply with ease. Your journey with us starts here.`;
    careers1_positions = [
        {
            Title: "Fashion Designer",
            Desc: "Are you a skilled designer with a passion for fashion? Join our team and showcase your creativity in providing unique fashion experiences for our clients. With a focus on precision and innovation, you'll play a vital role in delivering exceptional clothing and styles."
        },
        {
            Title: "Apprentice Fashion Designer",
            Desc: "Ready to learn the art of fashion design? Our apprentice position offers hands-on training as you work alongside experienced designers. Develop your skills while gaining insights into the world of fashion and make your mark in the industry."
        },
        {
            Title: "Client Relations Specialist",
            Desc: "Become the face of our brand as a Client Relations Specialist. Welcome clients, assist with appointments, and ensure a seamless experience. Your excellent interpersonal skills will create a warm and inviting environment for our valued clients."
        },
        {
            Title: "Fashion Consultation Expert",
            Desc: "Join us as a Fashion Consultation Expert and help clients bring their style visions to life. Guide clients in choosing the perfect outfits and accessories. Your understanding of industry trends and design expertise will ensure clients leave with their dream looks."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Stay Connected`;
    contact1_mapSearchString = `Our Fashion Studio Location`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Special Offers`;
    coupons1_caption = `Unlock exclusive deals and elevate your fashion experience with our limited-time offers.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Style Deal",
            Details: "Experience our premium fashion service with a special discount. Express yourself with unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "Designer Package",
            Details: "Elevate your fashion experience with a discounted package. Our skilled designers will bring your ideas to life."
        },
        {
            Image: couponImg1,
            Coupon: "Accessory Add-On",
            Details: "Complete your style journey with a bonus offer. Enhance your look with a complimentary accessory."
        },
        {
            Image: couponImg1,
            Coupon: "First Style Offer",
            Details: "Welcome to our studio! Enjoy a special offer on your first fashion experience. Start your style journey with us."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Fashion Show Extravaganza",
            Desc: "Join us for an exclusive fashion show extravaganza, where our designers will unveil the latest trends. Learn about the art of fashion and enjoy live showcases of our skilled team's creations.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Fashion Styling Workshop",
            Desc: "Discover the art of fashion styling at our workshop. Our professionals will share insights into creating stunning looks and enhancing your personal style. Whether you're a fashion enthusiast or new to style, this event is for you.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Fashion Anniversary Celebration",
            Desc: "Celebrate with us! Enjoy special offers, personalized consultations, and festive treats. Join us for a day of fashion artistry, fun, and community.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Discover Our Features`;
    features1_features = [
        {
            Feature: "Custom Designs",
            Desc: "Our skilled fashion designers specialize in creating custom outfits that reflect your individuality. From intricate patterns to bold statements, we bring your style ideas to life.",
            Subject: "Fashion Design"
        },
        {
            Feature: "Style Consultation",
            Desc: "Start your fashion journey with a personalized consultation. Our experts listen to your ideas and provide insights to ensure your outfits and accessories match your vision.",
            Subject: "Consultation"
        },
        {
            Feature: "Fashion Accessories",
            Desc: "Complete your look with our curated selection of accessories. From jewelry to handbags, we offer the perfect finishing touches to elevate your style.",
            Subject: "Accessories"
        },
        {
            Feature: "Hygiene & Safety",
            Desc: "Our studio adheres to strict hygiene and safety protocols. We ensure a clean environment where you can enjoy your fashion experience with confidence.",
            Subject: "Hygiene"
        },
        {
            Feature: "Style Transformation",
            Desc: "Looking for a style transformation? Our designers are here to help you reinvent your look. Experience the power of a refreshed and confident appearance.",
            Subject: "Transformation"
        },
        {
            Feature: "Community Events",
            Desc: "Join us for fashion showcases, styling workshops, and community events. Connect with fellow fashion enthusiasts and artists, and celebrate the world of fashion.",
            Subject: "Community"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Gallery`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `Our Journey`;
    history1_caption = `A Legacy in Style`;
    history1_heading1 = `From Tradition to Modern Elegance`;
    history1_text1 = `Our journey in fashion spans decades, evolving from classic designs to contemporary masterpieces. We take pride in being a part of your stories, turning your ideas into stunning fashion that reflects your journey.`;
    history1_heading2 = `Celebrating Our Community`;
    history1_text2 = `As we look back on our legacy, we're grateful for our clients who have trusted us with their stories. We're committed to providing exceptional fashion experiences that inspire confidence and self-expression.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Our Studios`;
    locations1_locations = [
        {
            Location: "Downtown Studio",
            Address: "123 Style Street, Cityville",
            Hours: "Mon - Fri: 10 AM - 8 PM\nSat: 11 AM - 7 PM\nSunday: Closed"
        },
        {
            Location: "Fashion District Studio",
            Address: "456 Chic Avenue, Creativille",
            Hours: "Mon - Fri: 11 AM - 9 PM\nSat: 10 AM - 6 PM\nSunday: 12 PM - 5 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join Our Newsletter`;
    mailingList1_caption = `Stay updated on fashion trends, events, and special offers. Subscribe to our newsletter and be part of our vibrant community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Catalog`;
    menu1_menu = [
        {
            Name: "Apparel Styling",
            Category: "Clothing",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$50"
                },
                {
                    Option: "Option 2",
                    Price: "$65"
                }
            ],
            Desc: "Elevate your look with our personalized apparel styling service."
        },
        {
            Name: "Accessory Consultation",
            Category: "Accessories",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$30"
                },
                {
                    Option: "Option 2",
                    Price: "$40"
                }
            ],
            Desc: "Discover the perfect accessories to complement your style."
        },
        // Add more services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `Our Collaborations`;
    partners1_caption = `Empowering Fashion Together`;
    partners1_partners = [
        {
            Partner: "Designer Studio X",
            Desc: "Collaborating with Designer Studio X to bring you cutting-edge designs.",
            Link: "https://designerstudiox.com",
            Img: partnerImg1
        },
        {
            Partner: "Accessory Brands",
            Desc: "Partnering with top accessory brands to enhance your fashion choices.",
            Link: "https://accessorybrands.com",
            Img: partnerImg1
        },
        {
            Partner: "Fashion Event Co.",
            Desc: "Joining forces with Fashion Event Co. to create memorable style experiences.",
            Link: "https://fashioneventco.com",
            Img: partnerImg1
        },
        {
            Partner: "Style Magazine",
            Desc: "Collaborating with Style Magazine to bring you the latest fashion insights.",
            Link: "https://stylemagazine.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Fashion Portfolio`;
    portfolio1_caption = `Discover Our Stylish Creations`;
    portfolio1_projects = [
        {
            Project: "Runway Collection",
            Desc: "Explore our runway collection featuring the latest trends and designs.",
            Link: "https://fashionportfolio.com/runway",
            Image: portfolioImg1
        },
        {
            Project: "Accessories Lookbook",
            Desc: "Find inspiration in our accessories lookbook, showcasing versatile styles.",
            Link: "https://fashionportfolio.com/accessories",
            Image: portfolioImg1
        },
        {
            Project: "Fashion Events Gallery",
            Desc: "Relive the excitement of our fashion events through captivating visuals.",
            Link: "https://fashionportfolio.com/events",
            Image: portfolioImg1
        },
        {
            Project: "Style Transformation Stories",
            Desc: "Witness real style transformations and inspiring fashion journeys.",
            Link: "https://fashionportfolio.com/transformation",
            Image: portfolioImg1
        },
        // Add more projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing Packages`;
    pricing1_caption = `Discover Our Value-Added Packages`;
    pricing1_prices = [
        {
            Price: "60",
            Desc: "Experience our personalized styling service with attention to detail."
        },
        {
            Price: "100",
            Desc: "Upgrade your style journey with our comprehensive package and extra perks."
        },
        {
            Price: "150",
            Desc: "Indulge in the ultimate luxury experience with our premium offering."
        },
        // Add more pricing options here...
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Fashion Products`;
    products1_caption = `Explore Our Fashionable Products`;
    products1_products = [
        {
            Name: "Signature Handbag",
            Desc: "Elevate your fashion game with our exquisite signature handbag.",
            Price: "75.00",
            Image: productsImg1
        },
        {
            Name: "Elegant Scarf",
            Desc: "Upgrade your look with our elegant scarf crafted from luxurious materials.",
            Price: "45.00",
            Image: productsImg2
        },
        {
            Name: "Statement Jewelry Set",
            Desc: "Experience luxury with our statement jewelry set designed to make a mark.",
            Price: "120.00",
            Image: productsImg3
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get a Style Quote`;
    quote1_caption = `Where Elegance Meets Individuality`;
    quote1_heading1 = `Select an Option`;
    quote1_heading2 = `Tell Us Your Preferences`;
    quote1_services = [
        "Option 1",
        "Option 2",
        "Option 3",
        "Option 4",
        // Add more options here...
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Client Testimonials`;
    reviews1_reviews = [
        {
            Reviewer: "Alexandra Gray",
            Review: "Absolutely love their styling expertise! They understand my taste perfectly.",
            Rating: "5.0",
            Date: "08/15/2023"
        },
        {
            Reviewer: "Ethan Brooks",
            Review: "Top-notch service! Their attention to detail sets them apart. Highly recommended!",
            Rating: "4.9",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Olivia Parker",
            Review: "Exceeded my expectations! They truly have an eye for style and trends.",
            Rating: "5.0",
            Date: "09/20/2023"
        },
        {
            Reviewer: "Sophia Lewis",
            Review: "Impressed by their commitment to client satisfaction. Will return for sure.",
            Rating: "4.8",
            Date: "10/05/2023"
        },
        // Add more reviews here...
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Book an Appointment`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Select a Date`;
    schedule1_buttonText = `Schedule Now`;
    schedule1_formHeading = `Final Steps Before Your Appointment...`;
    schedule1_formCaption = `You've Chosen the Following Time Slot:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Fashion Services`;
    services1_caption = `Explore Our Premium Styling Services`;
    services1_services = [
        {
            Name: "Personal Styling",
            Desc: "Enhance your personal style with our expert personal styling service.",
            Image: servicesImg1
        },
        {
            Name: "Event Ensemble",
            Desc: "Elevate your event look with our specialized event ensemble service.",
            Image: servicesImg2
        },
        {
            Name: "Accessories Styling",
            Desc: "Complete your look with our accessories styling service for that perfect touch.",
            Image: servicesImg3
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet Our Stylists`;
    team1_caption = `Discover Our Passionate Stylists`;
    team1_teamMembers = [
        {
            Name: "Sophia Adams",
            Position: "Fashion Expert",
            Image: teamImg1
        },
        {
            Name: "Liam Johnson",
            Position: "Style Consultant",
            Image: teamImg2
        },
        {
            Name: "Isabella Martinez",
            Position: "Accessory Specialist",
            Image: teamImg3
        },
        // Add more team members here...
    ];
    // #endregion
} else if (c_businessType == "Other") {
    // #region HOME 1
    home1_heading1 = `Elevate Your Experience with Premium Services. Book Now!`
    home1_caption1 = `From top-notch services to personalized care, our team provides an oasis of relaxation and style, ensuring you leave not just satisfied, but revitalized.`
    home1_buttonText1 = `Book Now`
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Experience with Premium Services. Book Now!`
    home2_heading1 = `Elevate your experience at ${c_businessName} - where excellence meets modern sophistication`
    home2_text1 = `Step into the World of Distinctive Excellence, Where Our Skilled Professionals Are Artisans of Personalization. With a Keen Eye for Detail and a Passion for Unveiling Your Unique Charm, We Transform Services into Personalized Masterpieces. Our Craftsmanship Reflects Your Uniqueness, Ensuring You Emerge Polished, Refined, and Infinitely Confident. Trust Us to Sculpt Your Signature Experience, Redefining Your Journey with Precision and Panache.`
    home2_buttonText1 = `Schedule Now`
    home2_buttonNav1 = `schedule`
    home2_heading2 = `Experience excellence redefined at ${c_businessName}`
    home2_text2 = `Experience the Epitome of Precision and Pampering: From Intricate Craftsmanship to Rejuvenating Techniques, Our Team Curates an Oasis Where Relaxation and Style Converge. Every Moment in Our Care Is a Journey to Revitalization. We Go Beyond Ordinary Services, Ensuring You Depart Not Only Satisfied, but Renewed and Reinvigorated. Trust Us to Elevate Your Experience Beyond the Ordinary.`
    home2_buttonText2 = `Learn More`
    home2_buttonNav2 = `blog`
    // #endregion
    // #region HOME 3
    home3_heading1 = `The power of websites in the palm of your hand!`
    home3_caption1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.`
    home3_buttonText1 = `Book Now`
    home3_buttonNav1 = `schedule`
    home3_subBlocks = [
        {
            Title: "Heading 1",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Heading 2",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Heading 3",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        }
    ]
    // #endregion
    // #region HOME 4
    home4_heading1 = "Empowering Your Business for Success.";
    home4_caption1 = "Experience the potential of growth and innovation with our dedicated team. We're committed to helping businesses like yours thrive and reach new heights.";
    home4_buttonText1 = "Learn More";
    home4_buttonNav1 = "about";
    home4_heading2 = "Transforming Ideas into Reality.";
    home4_caption2 = "Discover a world of solutions and possibilities at our establishment. Our experts are passionate about turning your ideas into tangible results.";
    home4_subBlocks = [
        {
            Heading: "Tailored Services for Every Business Need.",
            Caption: "From strategic consulting to operational support, our establishment offers a comprehensive range of services to address the unique needs of your business."
        },
        {
            Heading: "Masters of Business Excellence.",
            Caption: "Experience the perfect blend of industry knowledge and innovative thinking. Our experts are dedicated to helping your business stay competitive and successful."
        },
        {
            Heading: "Driving Growth and Progress.",
            Caption: "Indulge in business solutions that not only solve challenges but also contribute to the growth and progress of your business in a dynamic marketplace."
        },
        {
            Heading: "Confidence Through Expertise.",
            Caption: "Move forward with confidence, knowing that your business is in capable hands. Our priority is to provide you with solutions that align with your goals and vision."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Experience Excellence in Business Solutions at Swift Enterprises. Contact Us Today!`
    home5_caption1 = `From strategic consulting to operational support, our dedicated team offers a haven of expertise and innovation, ensuring your business thrives and achieves new milestones.`;
    home5_buttonText1 = `Contact Us`;
    home5_buttonNav1 = `contact`;
    home5_heading2 = `Experience Business Excellence at Swift Enterprises`;
    home5_caption2 = `Experience the Epitome of Business Solutions: From Strategic Consulting to Operational Excellence, Our Experts Create a Sanctuary Where Expertise and Growth Intersect.`;
    home5_subBlocks = [
        {
            Heading: "Tailored Business Services",
            Caption: "Experience the mastery of our experts who provide a range of services tailored to address the unique needs of your business. Whether you require strategic planning or operational optimization, we have you covered."
        },
        {
            Heading: "Innovative Solutions",
            Caption: "Indulge in business solutions that drive innovation and efficiency. Our skilled professionals offer solutions that propel your business forward in a dynamic marketplace."
        },
        {
            Heading: "Staying Competitive",
            Caption: "Stay ahead of industry trends with our experts' knowledge in creating strategies that ensure your business remains competitive, adaptable, and ready for the future."
        },
        {
            Heading: "Reliable Expertise",
            Caption: "Immerse yourself in reliable business support where the fusion of expert knowledge and a commitment to your success ensures your business reaches new heights of excellence."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Us`
    about1_heading1 = `The Artistry of Excellence: Crafting Memorable Experiences at ${c_businessName}`
    about1_text1 = `At ${c_businessName}, our story is one of passion, dedication, and a commitment to redefining customer experiences. Founded with the vision of creating more than just a business, we've cultivated an environment where tradition and innovation coexist harmoniously. Our journey began with the belief that service is an art, and our skilled professionals are the artists. With each interaction, we curate unique expressions of excellence that reflect the essence of our clients. The fusion of timeless techniques with contemporary approaches is what sets us apart. From the moment you engage with us, you're not just a customer – you're part of an experience waiting to be transformed into a masterpiece of satisfaction. Welcome to ${c_businessName}, where excellence is an experience, and your story becomes a part of ours.`
    about1_heading2 = `Beyond Expectations: Discovering Refinement and Relaxation at ${c_businessName}`
    about1_text2 = `At ${c_businessName}, our services are more than just transactions; they are experiences meticulously designed to rejuvenate your senses and elevate your satisfaction. Our team of highly skilled professionals brings expertise to every interaction, ensuring that every step is an artful endeavor. From classic approaches that pay homage to time-honored traditions, to contemporary innovations that push the boundaries of modern style, our offerings cater to diverse preferences. Indulge in the luxury of a soothing experience that combines meticulous attention to detail with relaxation. We take pride in curating an atmosphere where you can unwind, connect, and leave not only satisfied but also invigorated. Step into a world where excellence becomes a statement, and each engagement with ${c_businessName} is an opportunity to experience something exceptional.`
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Awards`
    awards1_caption = `Embrace a Tapestry of Excellence as Our Establishment Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Services to Customer Satisfaction, We Proudly Stand as a Beacon of Excellence. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Experiences. Join us on this Journey of Achievement as We Continue to Define the Art of Service.`
    awards1_awards = [
        {
            Award: "Exemplary Service Excellence Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional services that elevate customer experiences."
        },
        {
            Award: "Innovation in Service Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern trends for remarkable experiences."
        },
        {
            Award: "Ultimate Relaxation Haven Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to providing a haven of relaxation where clients experience rejuvenation and pampering."
        },
        {
            Award: "Masterful Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in delivering services with precision and comfort."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of ${c_businessName}`
    bio1_heading1 = `Experience Timeless Excellence at ${c_businessName}`
    bio1_text1 = `Immerse yourself in the unparalleled world of exceptional experiences at ${c_businessName}. Our skilled professionals are dedicated to creating memorable interactions that enhance your satisfaction and confidence. With precision techniques and a flair for modern approaches, we curate experiences that reflect your unique preferences. Whether it's a classic approach or a rejuvenating technique, your satisfaction is our top priority. Join us for an experience that transcends the ordinary.`
    bio1_heading2 = `A Legacy of Craftsmanship and Care at ${c_businessName}`
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable craftsmanship and customer-centric care. From the moment you engage with us, you're greeted with a blend of tradition and contemporary style. Our professionals are not just experts; they are artists who deliver services with precision and passion. Beyond the transaction, we create an ambiance of comfort and relaxation, ensuring that every interaction is a rejuvenating escape. Experience the fusion of tradition and innovation at ${c_businessName}.`
    bio1_heading3 = `Discover Exceptional Experiences at ${c_businessName}`
    bio1_text3 = `Step into a world where every detail matters – welcome to ${c_businessName}. Our dedicated team understands that interactions are more than just transactions; they're about expressing your preferences. With careful attention to your needs and a touch of our expertise, we craft experiences that resonate with your unique story. From meticulously delivered services to invigorating approaches, your journey to satisfaction begins here. Join us at ${c_businessName} and rediscover the art of exceptional experiences.`
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Exploring the Craft: The Art of Our Services",
            Date: new Date(),
            Desc: "Dive into the world of our exceptional services and the meticulous techniques that shape customer experiences. Discover how our skilled professionals transform interactions into personalized masterpieces, showcasing the blend of tradition and modernity.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Exploring the Craft: The Art of Our Services
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Dive into the world of our exceptional services and the meticulous techniques that shape customer experiences. Discover how our skilled professionals transform interactions into personalized masterpieces, showcasing the blend of tradition and modernity.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the precise handling of tools to the artful use of methods, every step is a testament to their expertise. Step behind the scenes and learn about the attention to detail that ensures each client walks out with an experience that's as unique as they are.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey through the history of our industry and its evolution into a harmonious blend of classic and contemporary approaches. Learn how innovation meets tradition at our establishment, where the mastery of technique meets the canvas of customer satisfaction.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the stories that services tell – tales of individuality, transformation, and satisfaction. Witness how, at our establishment, every service is a reflection of personality, offering customers an opportunity to express themselves and enjoy a unique experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Delve into the intersection of skill and style, where our professionals showcase their artistry. Learn about the tools of the trade, the commitment to excellence, and the fusion of tradition with contemporary trends, resulting in timeless yet modern experiences.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Creating a Tranquil Haven for Ultimate Relaxation",
            Date: new Date(),
            Desc: "Step into the calming environment of our establishment and experience our services as a rejuvenating escape. Explore the ambiance, relaxation techniques, and careful attention that turn every visit into a soothing journey of self-care.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Creating a Tranquil Haven for Ultimate Relaxation
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the calming environment of our establishment and experience our services as a rejuvenating escape. Explore the ambiance, relaxation techniques, and careful attention that turn every visit into a soothing journey of self-care.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the art of creating a space where services transcend mere routine. Discover how every element, from the comfortable surroundings to the soothing scents, contributes to a haven of relaxation that rejuvenates both body and spirit.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey that delves into the holistic experience of services at our establishment, where craftsmanship, ambiance, and personal care merge to create a refuge of tranquility.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience services as more than just a transaction, but a rejuvenating ritual that pampers both the body and the mind. Discover how our establishment elevates self-care to an art form, creating a haven where customers can unwind, refresh, and leave with renewed vitality.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the moment you enter, our establishment envelops you in an ambiance of serenity, where relaxation becomes the cornerstone of the experience. Immerse yourself in an oasis designed to soothe the senses and elevate the service journey beyond expectations.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Crafting Unique Experiences for Every Customer",
            Date: new Date(),
            Desc: "Celebrate the individuality that shines through every interaction with our business. Learn how our skilled professionals collaborate with customers to curate experiences that reflect their preferences, leaving a lasting impression with each unique encounter.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Crafting Unique Experiences for Every Customer
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Celebrate the individuality that shines through every interaction with our business. Learn how our skilled professionals collaborate with customers to curate experiences that reflect their preferences, leaving a lasting impression with each unique encounter.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the journey of transformation that unfolds in every customer interaction. Explore the consultations, insights, and techniques that shape each experience into a statement of individuality. Experience how our establishment empowers customers to embrace and showcase their distinct preferences.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From classic elegance to contemporary flair, our professionals tailor each encounter to match customer preferences. Join us in celebrating the art of crafting unique experiences that resonate with each individual, leaving them confident and ready to embrace their journey.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Uncover the secrets behind the magic that happens within the walls of our establishment. Witness the transformation from a simple interaction to a personalized masterpiece that not only enhances appearance but also captures the essence and distinct preferences of each customer.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the harmony between creativity and precision as our professionals deliver exceptional services. From precise techniques to rejuvenating experiences, our establishment offers a haven of relaxation, style, and self-expression, ensuring customers leave revitalized and ready to conquer the world.
                    </p>
                </div>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `We're Hiring!`;
    careers1_caption1 = `Take the time to look at these open positions and click on the button to apply. It has never been easier!`;
    careers1_positions = [
        {
            Title: "Position 1",
            Desc: "Are you a skilled professional with an eye for detail and a passion for delivering exceptional results? Join our team and showcase your expertise in providing top-notch services to our valued clients. With a focus on precision and creativity, you'll play a pivotal role in enhancing our clients' experiences and leaving them satisfied."
        },
        {
            Title: "Position 2",
            Desc: "Ready to embark on a rewarding journey in the industry? Our position offers a hands-on learning experience as you work alongside our experienced professionals. Develop your skills while gaining insights into the art of delivering exceptional services. Grow your craft under the guidance of our skilled team and make your mark in the field."
        },
        {
            Title: "Position 3",
            Desc: "Join us as a Client Experience Specialist and be the friendly face that welcomes clients, assists with appointments, and ensures their overall interaction is seamless and enjoyable. Your outstanding interpersonal skills and attention to detail will contribute to creating a welcoming and comfortable environment for all our valued clients."
        },
        {
            Title: "Position 4",
            Desc: "As a Grooming Consultant, you'll be the bridge between our clients' aspirations and our professionals' expertise. Guide clients in choosing the perfect service that suits their preferences. With your understanding of industry trends and product knowledge, you'll provide valuable insights to help clients achieve their desired results."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Keep in touch!`;
    contact1_mapSearchString = `Location Name`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Check out our new deals!`;
    coupons1_caption = `Unlock unbeatable savings and transform your experience with our exclusive offers.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Deal 1",
            Details: "Experience our premium service with a special discount. Treat yourself to a unique experience while enjoying unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "Deal 2",
            Details: "Elevate your experience with a discounted service. Our skilled professionals will deliver results that leave you satisfied."
        },
        {
            Image: couponImg1,
            Coupon: "Deal 3",
            Details: "Complete your experience with a bonus offer. Indulge in the luxury of a complimentary addition to your service."
        },
        {
            Image: couponImg1,
            Coupon: "Deal 4",
            Details: "Welcome to our establishment! Enjoy a special offer on your first visit. Discover the art of personalized service and start your journey with us."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Events`;
    events1_events = [
        {
            Title: "Event 1",
            Desc: "Join us for an exclusive showcase and workshop, where our experts will demonstrate the latest trends and techniques. Learn how to achieve the perfect result for any occasion and enjoy live demonstrations and personalized tips from our skilled team.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Event 2",
            Desc: "Discover the art of care at our seminar and session. Our professionals will share insights into maintaining and enhancing, followed by hands-on guidance. Whether you're a seasoned enthusiast or just starting your journey, this event is for you.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Event 3",
            Desc: "Celebrate with us in style! Enjoy a day filled with special offers, complimentary experiences, and festive treats. It's our way of thanking you for being part of our journey. Join us for a day of fun, service, and community.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Check out our latest features.`;
    features1_features = [
        {
            Feature: "Feature 1",
            Desc: "Our skilled professionals specialize in delivering exceptional results that enhance your unique preferences. From classic to contemporary, we tailor each experience to suit your needs, ensuring you leave satisfied.",
            Subject: "Service"
        },
        {
            Feature: "Feature 2",
            Desc: "Experience the luxury of our rejuvenating service. Our experts combine skill and attention to detail to provide a result that leaves you refreshed and revitalized.",
            Subject: "Experience"
        },
        {
            Feature: "Feature 3",
            Desc: "Whether you're seeking maintenance or enhancement, our professionals are experts in delivering results that suit your preferences. From shaping to trimming, we know the art of creating well-groomed and stylish outcomes.",
            Subject: "Care"
        },
        {
            Feature: "Feature 4",
            Desc: "Every experience starts with a personalized consultation where our professionals take the time to understand your preferences. We believe in delivering tailored experiences that cater to your individual needs and goals.",
            Subject: "Consultation"
        },
        {
            Feature: "Feature 5",
            Desc: "Discover our range of premium products that help you maintain your look between experiences. From high-quality products to styling tools, we offer the resources you need for a polished appearance.",
            Subject: "Products"
        },
        {
            Feature: "Feature 6",
            Desc: "Step into our establishment and experience a welcoming atmosphere where you can relax and enjoy your experience. Our inviting setting is designed to make you feel at ease while we deliver results.",
            Subject: "Ambiance"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Take a closer look!`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `It all started here..`;
    history1_caption = `A Journey Through Time`;
    history1_heading1 = `Experience the journey: Where modern meets tradition, redefining the experience.`;
    history1_text1 = `Steeped in history, we've witnessed the evolution. From timeless styles to contemporary trends, we've been the architects of transformation. We take pride in being the destination for those who seek authenticity in every aspect of their journey. Our commitment to quality, precision, and personalized service stands strong.`;
    history1_heading2 = `More than what we do, we're a community cornerstone where stories are shared and traditions thrive.`;
    history1_text2 = `As we celebrate our journey, we look forward to continuing our legacy. With a team of dedicated professionals and a passion for the craft, we remain the destination for those who appreciate excellence. Join us in shaping the future together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `We are here..`;
    locations1_locations = [
        {
            Location: "Location 1",
            Address: "Address 1",
            Hours: "Mon - Fri: 8 AM - 5 PM\nSat: 10 AM - 8 PM\nSunday: 2 PM - 8 PM"
        },
        {
            Location: "Location 2",
            Address: "Address 2",
            Hours: "Mon - Fri: 8 AM - 5 PM\nSat: 10 AM - 8 PM\nSunday: 2 PM - 8 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join our mailing list!`;
    mailingList1_caption = `Stay updated and never miss a newsletter. Enter your email to be part of our community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Menu`;
    menu1_menu = [
        {
            Name: "Service 1",
            Category: "Category 1",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$20"
                },
                {
                    Option: "Option 2",
                    Price: "$25"
                }
            ],
            Desc: "Description for Service 1."
        },
        {
            Name: "Service 2",
            Category: "Category 2",
            Prices: [
                {
                    Option: "Option 1",
                    Price: "$15"
                },
                {
                    Option: "Option 2",
                    Price: "$20"
                }
            ],
            Desc: "Description for Service 2."
        },
        // Add more services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `We only work with the best!`;
    partners1_caption = `Our partners make it all possible.`;
    partners1_partners = [
        {
            Partner: "Partner 1",
            Desc: "Description for Partner 1.",
            Link: "https://partner1.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        {
            Partner: "Partner 2",
            Desc: "Description for Partner 2.",
            Link: "https://partner2.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Portfolio`;
    portfolio1_caption = ``;
    portfolio1_projects = [
        {
            Project: "Collection 1",
            Desc: "Explore our curated collection of the latest designs, from classic to modern.",
            Link: "https://portfolio.com/collection1",
            Image: portfolioImg1
        },
        {
            Project: "Guide 1",
            Desc: "Master the art of something with our comprehensive guide, covering everything from basics to advanced techniques.",
            Link: "https://portfolio.com/guide1",
            Image: portfolioImg1
        },
        {
            Project: "Events Recap",
            Desc: "Relive the excitement of our recent events through captivating photos and highlights.",
            Link: "https://portfolio.com/events",
            Image: portfolioImg1
        },
        {
            Project: "Showcase 1",
            Desc: "Witness the creativity and craftsmanship as we showcase some of our finest work.",
            Link: "https://portfolio.com/showcase1",
            Image: portfolioImg1
        }
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Refine Your Experience: Discover Our Packages and Pricing`;
    pricing1_prices = [
        {
            Price: "40",
            Desc: "Get the best with our top-notch service. Our experienced professionals will deliver results that impress."
        },
        {
            Price: "80",
            Desc: "Upgrade your experience with our comprehensive package. Get more value and enhanced results."
        },
        {
            Price: "120",
            Desc: "Indulge in luxury with our premium offering. Experience the epitome of excellence and satisfaction."
        }
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`;
    products1_caption = `Elevate Your Experience: Discover Premium Products`;
    products1_products = [
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg1
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg2
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg3
        },
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg4
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg5
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg6
        },
        {
            Name: "Product 1",
            Desc: "Achieve excellence with our top-tier product that offers superior performance and quality.",
            Price: "18.00",
            Image: productsImg7
        },
        {
            Name: "Product 2",
            Desc: "Upgrade your routine with our advanced product made from natural ingredients for exceptional results.",
            Price: "12.00",
            Image: productsImg8
        },
        {
            Name: "Product 3",
            Desc: "Experience luxury with our premium product set, crafted for the discerning individual.",
            Price: "35.00",
            Image: productsImg9
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get your quote today!`;
    quote1_caption = `Where excellence meets style. Step into our world, where quality is paramount and every experience is remarkable.`;
    quote1_heading1 = `Pick an option`;
    quote1_heading2 = `Tell us about your preferences`;
    quote1_services = [
        `Option 1`,
        `Option 2`,
        `Option 3`,
        `Option 4`,
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "John Doe",
            Review: "Absolutely love this place! The atmosphere is fantastic, and the professionals are skilled. I always leave satisfied.",
            Rating: "5.0",
            Date: "08/05/2023"
        },
        {
            Reviewer: "Jane Smith",
            Review: "My go-to spot for quality. The attention to detail is incredible, and the staff is friendly. Highly recommend!",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Johnson",
            Review: "Outstanding service! The expertise of the team is unmatched. Every visit is a treat.",
            Rating: "4.9",
            Date: "09/15/2023"
        },
        {
            Reviewer: "Emily Adams",
            Review: "Exceptional experience every time. The attention to customer preferences is remarkable.",
            Rating: "5.0",
            Date: "10/03/2023"
        }
    ];

    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Pick a date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Before we finalize your appointment...`;
    schedule1_formCaption = `You have selected the following time slot:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Services`;
    services1_caption = `Experience top-tier services that seamlessly blend expertise with personalized care.`;
    services1_services = [
        {
            Name: "Service 1",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 2",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 3",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        {
            Name: "Service 1",
            Desc: "Enhance your experience with our precision service tailored to your needs.",
            Image: servicesImg1
        },
        {
            Name: "Service 2",
            Desc: "Elevate your routine with our expert service designed to exceed your expectations.",
            Image: servicesImg2
        },
        {
            Name: "Service 3",
            Desc: "Indulge in luxury with our premium service that ensures your satisfaction.",
            Image: servicesImg3
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet our team`;
    team1_caption = ``;
    team1_teamMembers = [
        {
            Name: "John Doe",
            Position: "Expert",
            Image: teamImg1
        },
        {
            Name: "Jane Smith",
            Position: "Professional",
            Image: teamImg2
        },
        {
            Name: "Michael Johnson",
            Position: "Master Barber",
            Image: teamImg3
        },
        {
            Name: "Emily Adams",
            Position: "Hair Stylist",
            Image: teamImg4
        },
        {
            Name: "Daniel Clark",
            Position: "Beard Specialist",
            Image: teamImg1
        }
    ];

    // #endregion
} else if (c_businessType == "Plumber") {
    // #region HOME 1
    home1_heading1 = `Enhance Your Spaces with Professional Plumbing Services. Contact Us Now!`;
    home1_caption1 = `From fixing leaks to plumbing installations, our team provides reliable plumbing solutions that ensure your spaces are not just functional, but also well-maintained.`;
    home1_buttonText1 = `Contact Us`;
    // #endregion
    // #region HOME 2
    home2_caption = `Elevate Your Spaces with Professional Plumbing Services. Contact Us Now!`;
    home2_heading1 = `Enhance your spaces with expert plumbing services at ${c_businessName} - where functionality meets seamless solutions`;
    home2_text1 = `Step into the World of Plumbing Excellence, Where Our Skilled Technicians Are Masters of Functionality. With a Dedication to Providing Plumbing Services That Reflect Your Unique Needs, We Turn Plumbing Problems into Streamlined Solutions. Our Plumbing Services Revolve Around Your Comfort, Ensuring Every Space Is Equipped with Reliable Plumbing. Trust Us to Attend to Your Plumbing Needs, Redefining Your Spaces with Efficient Solutions and Plumbing Expertise.`;
    home2_buttonText1 = `Contact Us`;
    home2_buttonNav1 = `contact`;
    home2_heading2 = `Experience Plumbing Solutions at ${c_businessName}`;
    home2_text2 = `Experience the Epitome of Functionality and Efficiency: From Plumbing Repairs to Seamless Installations, Our Technicians Provide Services that Reflect Quality and Reliability. Every Fixture is a Testament to Precision. We Go Beyond Basic Plumbing, Ensuring Your Spaces Are Not Only Functional, but Also Equipped with Plumbing Excellence. Trust Us to Elevate Your Spaces Beyond the Ordinary.`;
    home2_buttonText2 = `Learn More`;
    home2_buttonNav2 = `services`;
    // #endregion
    // #region HOME 3
    home3_heading1 = `Experience Plumbing Solutions and Efficient Services!`;
    home3_caption1 = `Benefit from the mastery of plumbing services and expert solutions by our team. From leak repairs to installations, we bring reliable functionality to your spaces.`;
    home3_buttonText1 = `Contact Us`;
    home3_buttonNav1 = `contact`;
    home3_subBlocks = [
        {
            Title: "Leak Repairs",
            Text: "Experience the efficiency of our technicians who repair leaks with precision, ensuring your spaces are free from water damage and inconveniences.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Installation Services",
            Text: "Experience plumbing installations that are seamlessly integrated into your spaces, providing functional solutions that meet your needs.",
            ButtonText: "Learn More",
            ButtonNav: "services"
        },
        {
            Title: "Plumbing Maintenance",
            Text: "Step into a world where plumbing expertise meets routine care. Our team is dedicated to delivering functionality that enhances your spaces and comfort.",
            ButtonText: "Learn More",
            ButtonNav: "about"
        }
    ];
    // #endregion
    // #region HOME 4
    home4_heading1 = "Elevate Your Spaces with Expert Plumbing Services.";
    home4_caption1 = "Experience the potential of reliable plumbing with our dedicated team. We're committed to making your spaces truly functional by providing efficient solutions and plumbing expertise.";
    home4_buttonText1 = "Contact Us";
    home4_buttonNav1 = "contact";
    home4_heading2 = "Crafting Functional Spaces.";
    home4_caption2 = "Discover a world of plumbing solutions and expert services at our establishment. Our technicians are passionate about turning your spaces into efficiently running environments.";
    home4_subBlocks = [
        {
            Heading: "Efficient Plumbing Repairs",
            Caption: "Experience the skill of our technicians who repair plumbing issues with precision, ensuring your spaces are free from disruptions and damages."
        },
        {
            Heading: "Seamless Installation Services",
            Caption: "Experience plumbing installations that blend functionality with aesthetics, providing solutions that enhance the efficiency of your spaces."
        },
        {
            Heading: "Enhancing Functional Moments",
            Caption: "Stay ahead of plumbing trends with our experts' knowledge in providing services that guarantee your spaces are equipped with reliable plumbing solutions."
        },
        {
            Heading: "Passion for Plumbing Excellence",
            Caption: "Experience the dedication and passion of our plumbing team, where the fusion of efficiency and expert solutions brings your spaces to life."
        },
    ];
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Discover the Art of Efficient Plumbing and Expert Services at ${c_businessName}. Contact Us for Solutions!`;
    home5_caption1 = `From repairs to installations, our dedicated team offers reliable plumbing solutions and expertise, ensuring your spaces are not only functional but also efficiently maintained.`;
    home5_buttonText1 = `Contact Us`;
    home5_buttonNav1 = `contact`;
    home5_heading2 = `Experience Plumbing Excellence at ${c_businessName}`;
    home5_caption2 = `Experience the Epitome of Plumbing Solutions: From Repairs to Seamless Services, Our Technicians Create a Haven Where Functionality and Comfort Converge.`;
    home5_subBlocks = [
        {
            Heading: "Customized Plumbing Services",
            Caption: "Indulge in the mastery of our technicians who provide a range of plumbing services tailored to meet your unique needs, ensuring that each solution is efficient and reliable."
        },
        {
            Heading: "Expert Plumbing Craftsmanship",
            Caption: "Experience the skill of our technicians who provide plumbing solutions that bring functionality to your spaces. From repairs to installations, our services are designed to impress."
        },
        {
            Heading: "Crafting Functional Spaces",
            Caption: "Stay ahead of plumbing trends with our experts' knowledge in providing solutions that guarantee your spaces are equipped with reliable plumbing solutions."
        },
        {
            Heading: "Passion for Plumbing Excellence",
            Caption: "Immerse yourself in the dedication and expertise of our plumbing team, where the fusion of functionality and reliable solutions brings a new dimension to your spaces."
        },
    ];
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Us`;
    about1_heading1 = `Experience the Artistry of Functionality at ${c_businessName}`;
    about1_text1 = `At ${c_businessName}, our story revolves around precision, dedication, and a commitment to redefining plumbing experiences. Founded with the vision of providing more than just plumbing services, we've cultivated an environment where efficiency and reliability coexist harmoniously. Our journey began with the belief that plumbing is a science, and our skilled technicians are the experts. With each solution, we curate unique expressions of functionality that reflect the essence of our customers. The fusion of traditional techniques with contemporary plumbing practices is what sets us apart. From the moment you experience our services, you're not just a client – you're part of a smoothly running system waiting to be transformed into an efficient masterpiece. Welcome to ${c_businessName}, where functionality is an experience, and your spaces become part of our expertise.`;
    about1_heading2 = `Beyond Expectations: Discovering Functionality and Plumbing Excellence at ${c_businessName}`;
    about1_text2 = `At ${c_businessName}, our offerings go beyond basic plumbing services; they are efficient solutions meticulously designed to enhance your spaces' functionality and reliability. Our team of highly skilled technicians brings expertise to every solution, ensuring that every aspect is efficiently addressed. From traditional techniques that align with industry standards to contemporary innovations that push the boundaries of efficiency, our services cater to diverse needs. Embrace the luxury of efficiently running spaces that combine meticulous attention to detail with reliability. We take pride in curating an atmosphere where you can rely on, connect, and leave not only satisfied but also with systems that work seamlessly. Step into a world where functionality becomes a statement, and each solution at ${c_businessName} is an opportunity to experience something exceptional.`;
    // #endregion ABOUT 1
    // #region AWARDS 1
    awards1_pageTitle = `Awards`;
    awards1_caption = `Embrace a Tapestry of Plumbing Excellence as ${c_businessName} Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Repairs to Plumbing Solutions, We Proudly Stand as a Beacon of Functionality. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Plumbing Experiences. Join us on this Journey of Achievement as We Continue to Define the Art of Efficiency.`;
    awards1_awards = [
        {
            Award: "Efficient Plumbing Solutions Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to providing plumbing solutions that enhance functionality and reliability."
        },
        {
            Award: "Innovation in Plumbing Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern techniques for efficient solutions."
        },
        {
            Award: "Ultimate Plumbing Excellence Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to delivering plumbing services that create functional and efficient spaces."
        },
        {
            Award: "Masterful Plumbing Craftsmanship Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in providing plumbing solutions with precision and expertise."
        }
    ];
    // #endregion AWARDS 1
    // #region BIO 1
    bio1_pageTitle = `Discover the World of Efficient Plumbing and Expert Solutions`;
    bio1_heading1 = `Experience the Artistry of Functionality at ${c_businessName}`;
    bio1_text1 = `Immerse yourself in the unparalleled world of efficient plumbing solutions at ${c_businessName}. Our skilled technicians are dedicated to creating functional spaces that enhance your satisfaction and efficiency. With precise techniques and a passion for providing solutions that reflect your unique needs, every aspect is a testament to streamlined functionality. Whether it's a repair or an installation, your satisfaction is our priority. Join us for a functional journey that transcends the ordinary.`;
    bio1_heading2 = `A Legacy of Functionality and Passion at ${c_businessName}`;
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable plumbing craftsmanship and customer-centric care. From the moment you experience our solutions, you're greeted with a blend of tradition and contemporary efficiency. Our technicians are not just experts; they are craftsmen who deliver solutions with precision and passion. Beyond the service, we create an ambiance of efficiency and reliability, ensuring that every aspect runs seamlessly. Experience the fusion of tradition and innovation at ${c_businessName}.`;
    bio1_heading3 = `Discover Extraordinary Efficiency at ${c_businessName}`;
    bio1_text3 = `Step into a world where every plumbing solution is an opportunity to enhance functionality – welcome to ${c_businessName}. Our dedicated team understands that efficient solutions are more than just fixes; they're about expressing your unique needs. With careful attention to your requirements and a touch of our expertise, we provide plumbing solutions that resonate with your individual functionality journey. From meticulously crafted repairs to seamless installations, your efficient journey begins here. Join us at ${c_businessName} and rediscover the art of exceptional efficiency.`;
    // #endregion BIO 1
    // #region BLOG 1
    blog1_pageTitle = `Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "Exploring a New Topic: The Expertise of Plumbing Craftsmanship",
            Date: new Date(),
            Desc: "Embark on a journey through our plumbing services and the meticulous techniques that shape unique customer experiences. Discover how our skilled technicians transform routine tasks into personalized masterpieces, blending efficiency and precision seamlessly.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Exploring a New Topic: The Expertise of Plumbing Craftsmanship
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Embark on a journey through our plumbing services and the meticulous techniques that shape unique customer experiences. Discover how our skilled technicians transform routine tasks into personalized masterpieces, blending efficiency and precision seamlessly.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the precise handling of tools to the artful use of methods, every step is a testament to their expertise. Step behind the scenes and learn about the attention to detail that ensures each client receives plumbing solutions that are as unique as their needs.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a journey through the history of plumbing techniques and their evolution into a harmonious blend of classic and contemporary approaches. Learn how innovation meets efficiency at our establishment, where the mastery of technique meets the canvas of customer satisfaction.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the stories that plumbing services tell – tales of functionality, transformation, and satisfaction. Witness how, at our establishment, every plumbing task is a reflection of expertise, offering customers an opportunity to embrace efficient solutions and enjoy a unique experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Delve into the intersection of skill and precision, where our professionals showcase their craftsmanship. Learn about the tools of the trade, the commitment to excellence, and the fusion of efficiency with contemporary trends, resulting in timeless yet modern plumbing experiences.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "Creating a Tranquil Space: A Journey to Functional Comfort",
            Date: new Date(),
            Desc: "Step into the serene environment of our establishment and discover how we turn plumbing solutions into a comforting escape. Explore the ambiance, reliability, and meticulous attention that transform each interaction into a soothing experience of functional self-care.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Creating a Tranquil Space: A Journey to Functional Comfort
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Step into the serene environment of our establishment and discover how we turn plumbing solutions into a comforting escape. Explore the ambiance, reliability, and meticulous attention that transform each interaction into a soothing experience of functional self-care.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Immerse yourself in the art of creating a space where our plumbing solutions transcend mere functionality. Experience how every element, from reliable systems to the assurance of efficiency, contributes to an environment of comfort that enhances both well-being and functionality.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Embark on a journey that delves into the holistic experience of plumbing solutions at our establishment. Witness the fusion of craftsmanship, ambiance, and functional care that creates a refuge of tranquility for every visitor.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the depth of our plumbing services as more than tasks; they are comforting rituals that address both practicality and well-being. Our establishment elevates functional care to an art form, offering an environment where customers find assurance, refreshment, and renewed reliability.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the moment you engage with our establishment, you're enveloped in an ambiance of functional comfort, where well-being becomes the foundation of the experience. Immerse yourself in an oasis designed to soothe the senses and elevate the plumbing service journey beyond expectations.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Crafting Unforgettable Solutions: Customized Plumbing Experiences",
            Date: new Date(),
            Desc: "Celebrate the uniqueness that shines through every interaction with our establishment. Learn how our skilled professionals collaborate with customers to create plumbing experiences that reflect their needs, leaving a lasting impression with each personalized encounter.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Crafting Unforgettable Solutions: Customized Plumbing Experiences
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Celebrate the uniqueness that shines through every interaction with our establishment. Learn how our skilled professionals collaborate with customers to create plumbing experiences that reflect their needs, leaving a lasting impression with each personalized encounter.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the journey of transformation that unfolds in every customer interaction. Dive into the consultations, insights, and techniques that shape each plumbing experience into a statement of individuality. Experience how our establishment empowers customers to embrace and showcase their distinct needs.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From timeless reliability to contemporary efficiency, our professionals tailor each plumbing encounter to match customer needs. Join us in celebrating the art of crafting unique solutions that resonate with each individual, leaving them confident and ready to embrace their functional journey.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Uncover the magic that happens within the walls of our establishment. Witness the transformation from a simple interaction to a personalized solution that enhances functionality and captures the essence of each customer's distinct needs.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the harmony between creativity and precision as our professionals deliver exceptional plumbing services. From accurate techniques to reliable solutions, our establishment offers a haven of functionality, style, and individual expression, ensuring customers leave revitalized and ready to embrace their day-to-day activities.
                    </p>
                </div>
            )
        },
    ];
    // #endregion BLOG 1
    // #region CAREERS 1
    careers1_pageTitle = `Explore Job Opportunities`;
    careers1_caption1 = `Take a moment to review the available positions below. Click the button to apply and take the first step towards joining our team!`;
    careers1_positions = [
        {
            Title: "Plumbing Technician",
            Desc: "Are you a skilled professional with an eye for detail and a passion for delivering exceptional results? Join our team and showcase your expertise in providing top-notch plumbing services to our valued clients. With a focus on precision and efficiency, you'll play a pivotal role in enhancing our clients' experiences and leaving them satisfied."
        },
        {
            Title: "Apprentice Plumber",
            Desc: "Ready to embark on a rewarding journey in the plumbing industry? Our apprentice position offers a hands-on learning experience as you work alongside our experienced plumbing professionals. Develop your skills while gaining insights into the art of delivering exceptional plumbing services. Grow your craft under the guidance of our skilled team and make your mark in the field."
        },
        {
            Title: "Client Relations Specialist",
            Desc: "Join us as a Client Relations Specialist and be the friendly face that welcomes clients, schedules appointments, and ensures their overall interaction is seamless and enjoyable. Your outstanding interpersonal skills and attention to detail will contribute to creating a welcoming and comfortable environment for all our valued clients."
        },
        {
            Title: "Plumbing Consultant",
            Desc: "As a Plumbing Consultant, you'll be the bridge between our clients' plumbing needs and our professionals' expertise. Guide clients in choosing the perfect plumbing service that suits their requirements. With your understanding of plumbing trends and product knowledge, you'll provide valuable insights to help clients achieve their desired results."
        },
    ];
    // #endregion CAREERS 1
    // #region CONTACT 1
    contact1_pageTitle = `Stay Connected!`;
    contact1_mapSearchString = `Find Us Here`;
    // #endregion CONTACT 1
    // #region COUPONS 1
    coupons1_pageTitle = `Discover Our Latest Offers`;
    coupons1_caption = `Unlock unbeatable savings and enhance your experience with our exclusive deals.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Plumbing Deal 1",
            Details: "Experience our premium plumbing service with a special discount. Treat yourself to a unique plumbing experience while enjoying unbeatable savings."
        },
        {
            Image: couponImg1,
            Coupon: "Plumbing Deal 2",
            Details: "Elevate your plumbing experience with a discounted service. Our skilled plumbing professionals will deliver results that leave you satisfied."
        },
        {
            Image: couponImg1,
            Coupon: "Plumbing Deal 3",
            Details: "Complete your plumbing experience with a bonus offer. Indulge in the luxury of a complimentary addition to your plumbing service."
        },
        {
            Image: couponImg1,
            Coupon: "Welcome Deal",
            Details: "Welcome to our establishment! Enjoy a special offer on your first plumbing visit. Discover the art of personalized plumbing service and start your journey with us."
        },
    ];
    // #endregion COUPONS 1
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Plumbing Workshop",
            Desc: "Join us for an exclusive plumbing showcase and workshop, where our experts will demonstrate the latest plumbing trends and techniques. Learn how to achieve the perfect plumbing result for any situation and enjoy live plumbing demonstrations and personalized tips from our skilled team.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Plumbing Care Seminar",
            Desc: "Discover the art of plumbing care at our seminar and session. Our plumbing professionals will share insights into maintaining and enhancing plumbing systems, followed by hands-on guidance. Whether you're a seasoned plumbing enthusiast or just starting your plumbing journey, this event is for you.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Plumbing Appreciation Day",
            Desc: "Celebrate with us in style! Enjoy a day filled with special offers, complimentary plumbing experiences, and festive treats. It's our way of thanking you for being part of our plumbing journey. Join us for a day of fun, plumbing service, and community.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion EVENTS 1
    // #region FEATURES 1
    features1_pageTitle = `Explore Our Notable Features`;
    features1_features = [
        {
            Feature: "Expert Plumbing Services",
            Desc: "Our skilled plumbing professionals specialize in delivering exceptional plumbing results that enhance your unique preferences. From maintenance to repairs, we tailor each plumbing experience to suit your needs, ensuring you leave satisfied.",
            Subject: "Services"
        },
        {
            Feature: "Efficient Plumbing Solutions",
            Desc: "Experience the efficiency of our plumbing service. Our plumbing experts combine skill and attention to detail to provide plumbing results that leave you satisfied and your plumbing systems operating smoothly.",
            Subject: "Efficiency"
        },
        {
            Feature: "Personalized Plumbing Care",
            Desc: "Whether you're seeking plumbing maintenance or repairs, our professionals are experts in delivering plumbing results that suit your preferences. From diagnosis to solution, we know the art of creating well-maintained plumbing systems.",
            Subject: "Care"
        },
        {
            Feature: "Consultation for Plumbing Needs",
            Desc: "Every plumbing experience starts with a personalized consultation where our professionals take the time to understand your plumbing needs. We believe in delivering tailored plumbing experiences that cater to your individual requirements and goals.",
            Subject: "Consultation"
        },
        {
            Feature: "High-Quality Plumbing Products",
            Desc: "Discover our range of premium plumbing products that help you maintain your plumbing systems. From high-quality parts to reliable fixtures, we offer the resources you need for well-functioning plumbing systems.",
            Subject: "Products"
        },
        {
            Feature: "Comfortable Plumbing Service",
            Desc: "Step into our establishment and experience a welcoming atmosphere where you can relax and enjoy your plumbing experience. Our inviting setting is designed to make you feel at ease while we deliver reliable plumbing results.",
            Subject: "Comfort"
        }
    ];
    // #endregion FEATURES 1
    // #region GALLERY 1
    gallery1_pageTitle = `Take a Visual Tour`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion GALLERY 1
    // #region HISTORY 1
    history1_pageTitle = `Our Plumbing Journey`;
    history1_caption = `A Journey Through Plumbing Excellence`;
    history1_heading1 = `Experience the plumbing journey: Where modern solutions meet tradition, redefining plumbing experience.`;
    history1_text1 = `Steeped in plumbing history, we've witnessed the evolution. From timeless plumbing techniques to contemporary plumbing trends, we've been the architects of plumbing transformation. We take pride in being the destination for those who seek plumbing authenticity in every aspect of their plumbing journey. Our commitment to plumbing quality, precision, and personalized service stands strong.`;
    history1_heading2 = `More than what we do, we're a plumbing community cornerstone where plumbing stories are shared and plumbing traditions thrive.`;
    history1_text2 = `As we celebrate our plumbing journey, we look forward to continuing our plumbing legacy. With a team of dedicated plumbing professionals and a passion for the plumbing craft, we remain the destination for those who appreciate plumbing excellence. Join us in shaping the plumbing future together.`;
    // #endregion HISTORY 1
    // #region LOCATIONS 1
    locations1_pageTitle = `Our Convenient Locations`;
    locations1_locations = [
        {
            Location: "Main Office",
            Address: "123 Plumbing Street, City, State, Zip",
            Hours: "Mon - Fri: 8 AM - 5 PM\nSat: 10 AM - 4 PM\nSunday: Closed"
        },
        {
            Location: "Branch Location",
            Address: "456 Plumbing Avenue, City, State, Zip",
            Hours: "Mon - Fri: 9 AM - 6 PM\nSat: 11 AM - 3 PM\nSunday: Closed"
        }
    ];
    // #endregion LOCATIONS 1
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join Our Plumbing Mailing List!`;
    mailingList1_caption = `Stay informed and never miss an update about plumbing. Enter your email to become part of our plumbing community.`;
    // #endregion MAILING LIST 1
    // #region MENU 1
    menu1_pageTitle = `Plumbing Services Menu`;
    menu1_menu = [
        {
            Name: "Leak Detection",
            Category: "Services",
            Prices: [
                {
                    Option: "Basic",
                    Price: "$89.99"
                },
                {
                    Option: "Advanced",
                    Price: "$149.99"
                }
            ],
            Desc: "Expert leak detection to prevent water damage and save costs."
        },
        {
            Name: "Pipe Repairs",
            Category: "Services",
            Prices: [
                {
                    Option: "Minor Fix",
                    Price: "$79.99"
                },
                {
                    Option: "Major Repair",
                    Price: "$129.99"
                }
            ],
            Desc: "Fast and reliable pipe repairs for a seamless plumbing system."
        },
        {
            Name: "Drain Cleaning",
            Category: "Services",
            Prices: [
                {
                    Option: "Clog Removal",
                    Price: "$59.99"
                },
                {
                    Option: "Deep Cleaning",
                    Price: "$99.99"
                }
            ],
            Desc: "Efficient drain cleaning to prevent blockages and backups."
        },
        {
            Name: "Fixture Installation",
            Category: "Services",
            Prices: [
                {
                    Option: "Basic Fixture",
                    Price: "$69.99"
                },
                {
                    Option: "Premium Fixture",
                    Price: "$119.99"
                }
            ],
            Desc: "Professional installation of fixtures for enhanced functionality."
        },
        // Add more plumbing services here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `Trusted Partners`;
    partners1_caption = `Collaborating with Industry Leaders for Reliable Plumbing Solutions`;
    partners1_partners = [
        {
            Partner: "Pipe Supplier Inc.",
            Desc: "Sourcing high-quality pipes and materials for durable plumbing solutions.",
            Link: "https://pipesupplier.com",
            Img: partnerImg1
        },
        {
            Partner: "Fixture Producers",
            Desc: "Providing top-notch fixtures that meet plumbing standards for longevity.",
            Link: "https://fixtureproducers.com",
            Img: partnerImg1
        },
        {
            Partner: "Drain Tech Solutions",
            Desc: "Partnering with experts to offer advanced drain cleaning technologies.",
            Link: "https://draintechsolutions.com",
            Img: partnerImg1
        },
        {
            Partner: "Eco-Plumbing Alliance",
            Desc: "Working with environmentally conscious partners for sustainable plumbing solutions.",
            Link: "https://eco-plumbingalliance.com",
            Img: partnerImg1
        },
        // Add more plumbing partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Project Showcase`;
    portfolio1_caption = `From Plumbing Installations to Repairs, Our Portfolio Demonstrates Our Plumbing Expertise.`;
    portfolio1_projects = [
        {
            Project: "Bathroom Remodel",
            Desc: "Transforming bathrooms with modern fixtures and efficient plumbing systems.",
            Link: "https://portfolio.com/bathroom-remodel",
            Image: portfolioImg1
        },
        {
            Project: "Kitchen Upgrade",
            Desc: "Revamping kitchens with functional layouts and reliable plumbing installations.",
            Link: "https://portfolio.com/kitchen-upgrade",
            Image: portfolioImg1
        },
        {
            Project: "Pipe Replacement",
            Desc: "Showcasing successful pipe replacement projects for improved water flow.",
            Link: "https://portfolio.com/pipe-replacement",
            Image: portfolioImg1
        },
        {
            Project: "Emergency Repairs",
            Desc: "Highlighting efficient emergency repairs to address urgent plumbing issues.",
            Link: "https://portfolio.com/emergency-repairs",
            Image: portfolioImg1
        },
        // Add more plumbing projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Affordable Plumbing Solutions: Explore Our Pricing Options`;
    pricing1_prices = [
        {
            Price: "Leak Detection",
            Desc: "Expert leak detection services to identify and fix water leaks.",
        },
        {
            Price: "Pipe Repairs",
            Desc: "Professional pipe repair solutions for leaks and damages.",
        },
        {
            Price: "Drain Cleaning",
            Desc: "Efficient drain cleaning to prevent blockages and backups.",
        },
        // Add more plumbing pricing options here...
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Plumbing Products`;
    products1_caption = `Quality Products for Your Plumbing Needs`;
    products1_products = [
        {
            Name: "High-Quality Pipes",
            Desc: "Durable pipes and fittings for long-lasting plumbing solutions.",
            Price: "$2.99 per foot",
            Image: productsImg1
        },
        {
            Name: "Efficient Fixtures",
            Desc: "Modern fixtures that enhance water efficiency and functionality.",
            Price: "$39.99",
            Image: productsImg2
        },
        {
            Name: "Eco-Friendly Drain Cleaners",
            Desc: "Environmentally safe drain cleaners to maintain clear drains.",
            Price: "$9.99",
            Image: productsImg3
        },
        // Add more plumbing products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get a Custom Quote`;
    quote1_caption = `Tailored Plumbing Solutions: Let Us Create Your Perfect Plumbing Plan`;
    quote1_heading1 = `Select Your Service`;
    quote1_heading2 = `Tell Us Your Needs`;
    quote1_services = [
        `Leak Detection`,
        `Pipe Repairs`,
        `Drain Cleaning`,
        `Fixture Installation`,
        // Add more plumbing service options here...
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Customer Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "Mark Johnson",
            Review: "Impressed with their quick and efficient pipe repair service. Highly recommended!",
            Rating: "4.9",
            Date: "08/15/2023"
        },
        {
            Reviewer: "Sarah Miller",
            Review: "Their leak detection saved me from a major water damage disaster. Great service!",
            Rating: "5.0",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Thompson",
            Review: "Fast response to my drain cleaning request. Very professional and friendly team.",
            Rating: "4.8",
            Date: "09/05/2023"
        },
        {
            Reviewer: "Emily Davis",
            Review: "Installed new fixtures in my bathroom flawlessly. Will hire them again!",
            Rating: "5.0",
            Date: "10/03/2023"
        }
        // Add more plumbing reviews here...
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule a Visit`;
    schedule1_heading1 = `Select a Service`;
    schedule1_heading2 = `Choose Your Preferred Time`;
    schedule1_heading3 = `Pick a Date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Before We Confirm Your Visit...`;
    schedule1_formCaption = `You have selected the following appointment time:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Our Services`;
    services1_caption = `Expert Plumbing Services: We Provide Solutions You Can Rely On.`;
    services1_services = [
        {
            Name: "Leak Detection",
            Desc: "Professional leak detection to find hidden water leaks.",
            Image: servicesImg1
        },
        {
            Name: "Pipe Repairs",
            Desc: "Efficient pipe repair solutions for various plumbing issues.",
            Image: servicesImg2
        },
        {
            Name: "Drain Cleaning",
            Desc: "Thorough drain cleaning to prevent clogs and backups.",
            Image: servicesImg3
        },
        // Add more plumbing services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet Our Experts`;
    team1_caption = `The Plumbing Professionals Behind Our Success: Meet Our Team.`;
    team1_teamMembers = [
        {
            Name: "John Smith",
            Position: "Master Plumber",
            Image: teamImg1
        },
        {
            Name: "Emma Davis",
            Position: "Plumbing Technician",
            Image: teamImg2
        },
        {
            Name: "William Johnson",
            Position: "Pipe Specialist",
            Image: teamImg3
        },
        {
            Name: "Olivia Brown",
            Position: "Drain Expert",
            Image: teamImg4
        },
        // Add more plumbing team members here...
    ];
    // #endregion

} else if (c_businessType == "Coffee") {
    // #region HOME 1
    home1_heading1 = `Indulge in Delicious Coffee, Pastries, and Smoothies. Visit Us Today!`
    home1_caption1 = `From freshly brewed coffee to mouthwatering pastries and refreshing smoothies, our team provides a delightful oasis of flavors, ensuring you leave not just satisfied, but craving for more.`
    home1_buttonText1 = `Visit Us`
    // #endregion
    // #region HOME 2
    home2_caption = `Savor the Flavors of ${c_businessName} - where taste meets modern indulgence`
    home2_heading1 = `Savor the Finest at ${c_businessName} - where Flavor Meets Innovation`
    home2_text1 = `Step into the World of Culinary Excellence, Where Our Skilled Artisans Are Masters of Flavor. With a Keen Palate for Detail and a Passion for Unveiling Unique Tastes, We Craft Creations into Culinary Masterpieces. Our Craftsmanship Reflects Your Cravings, Ensuring You Depart Satisfied, and Eager for More. Trust Us to Craft Your Signature Experience, Redefining Your Journey with Taste and Temptation.`
    home2_buttonText1 = `Order Now`
    home2_buttonNav1 = `menu`
    home2_heading2 = `Experience Flavor Redefined at ${c_businessName}`
    home2_text2 = `Experience the Epitome of Taste and Refreshment: From Delectable Pastries to Revitalizing Smoothies, Our Team Curates a Paradise Where Flavor and Indulgence Converge. Every Moment in Our Cafe Is a Journey to Culinary Delight. We Go Beyond Ordinary Offerings, Ensuring You Depart Not Only Satisfied, but Craving for More. Trust Us to Elevate Your Tastebuds Beyond the Ordinary.`
    home2_buttonText2 = `Learn More`
    home2_buttonNav2 = `blog`
    // #endregion
    // #region HOME 3
    home3_heading1 = `The Flavorful World in Your Cup!`
    home3_caption1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.`
    home3_buttonText1 = `Visit Us`
    home3_buttonNav1 = `menu`
    home3_subBlocks = [
        {
            Title: "Our Pastries",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "pastries"
        },
        {
            Title: "Our Smoothies",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "smoothies"
        },
        {
            Title: "Our Coffee",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "coffee"
        }
    ]
    // #endregion
    // #region HOME 4
    home4_heading1 = "Fueling Your Day with Flavorful Creations.";
    home4_caption1 = "Experience the potential of taste and innovation with our dedicated team. We're committed to helping you satisfy your cravings and reach new heights of culinary delight.";
    home4_buttonText1 = "Learn More";
    home4_buttonNav1 = "about";
    home4_heading2 = "Turning Tastes into Reality.";
    home4_caption2 = "Discover a world of culinary solutions and possibilities at our cafe. Our experts are passionate about crafting flavors that turn your cravings into tangible satisfaction.";
    home4_subBlocks = [
        {
            Heading: "Crafted Culinary Delights.",
            Caption: "From delectable pastries to refreshing smoothies, our cafe offers a diverse range of flavors to satisfy your cravings."
        },
        {
            Heading: "Masters of Culinary Excellence.",
            Caption: "Experience the perfect blend of taste expertise and innovative culinary thinking. Our experts are dedicated to satisfying your cravings with culinary creations that stand out."
        },
        {
            Heading: "Satisfying Cravings and Desires.",
            Caption: "Indulge in culinary solutions that not only delight your taste buds but also elevate your culinary experience in a dynamic marketplace."
        },
        {
            Heading: "Flavorful Confidence.",
            Caption: "Savor our creations with confidence, knowing that our cafe is dedicated to providing you with flavors that align with your cravings and desires."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Experience Culinary Excellence at ${c_businessName}. Contact Us Today!`
    home5_caption1 = `From mouthwatering pastries to refreshing smoothies, our dedicated team offers a haven of flavors and innovation, ensuring your cravings are satisfied and your taste buds are delighted.`;
    home5_buttonText1 = `Contact Us`;
    home5_buttonNav1 = `contact`;
    home5_heading2 = `Experience Culinary Delight at ${c_businessName}`;
    home5_caption2 = `Experience the Epitome of Culinary Solutions: From Delectable Pastries to Refreshing Smoothies, Our Experts Craft a Sanctuary Where Flavor and Satisfaction Intersect.`;
    home5_subBlocks = [
        {
            Heading: "Tailored Culinary Offerings",
            Caption: "Experience the mastery of our experts who provide a range of culinary delights tailored to satisfy your unique cravings. Whether you're in the mood for pastries, smoothies, or coffee, we have you covered."
        },
        {
            Heading: "Innovative Flavors",
            Caption: "Indulge in culinary creations that satisfy your cravings and elevate your taste experience. Our skilled professionals offer flavors that take you on a culinary journey in a dynamic marketplace."
        },
        {
            Heading: "Satisfying Your Cravings",
            Caption: "Stay ahead of your cravings with our experts' knowledge in crafting flavors that ensure your satisfaction, adaptability to your desires, and readiness for new culinary experiences."
        },
        {
            Heading: "Reliable Culinary Expertise",
            Caption: "Immerse yourself in reliable culinary support where the fusion of expert taste and a commitment to satisfying your cravings ensures your culinary journey reaches new heights of delight."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Us`
    about1_heading1 = `The Artistry of Flavor: Crafting Memorable Culinary Experiences at ${c_businessName}`
    about1_text1 = `At ${c_businessName}, our story is one of passion, dedication, and a commitment to redefining customer culinary experiences. Founded with the vision of creating more than just a cafe, we've cultivated an environment where tradition and innovation coexist harmoniously. Our journey began with the belief that culinary service is an art, and our skilled professionals are the artists. With each creation, we curate unique expressions of flavor that reflect the essence of our customers. The fusion of timeless recipes with contemporary approaches is what sets us apart. From the moment you engage with us, you're not just a customer – you're part of a culinary experience waiting to be transformed into a masterpiece of satisfaction. Welcome to ${c_businessName}, where flavor is an experience, and your cravings become a part of ours.`
    about1_heading2 = `Beyond Expectations: Discovering Deliciousness at ${c_businessName}`
    about1_text2 = `At ${c_businessName}, our culinary offerings are more than just transactions; they are culinary experiences meticulously designed to satisfy your cravings and elevate your delight. Our team of highly skilled professionals brings culinary expertise to every creation, ensuring that every bite is an artful endeavor. From classic recipes that pay homage to time-honored traditions to contemporary innovations that push the boundaries of culinary style, our offerings cater to diverse cravings. Savor the luxury of a delightful experience that combines meticulous attention to flavor with satisfaction. We take pride in curating an atmosphere where you can indulge, connect, and leave not only satisfied but also delighted. Step into a world where flavor becomes a statement, and each culinary creation with ${c_businessName} is an opportunity to experience something exceptional.`
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Awards`
    awards1_caption = `Embrace a Tapestry of Culinary Excellence as Our Cafe Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Flavors to Customer Delight, We Proudly Stand as a Beacon of Culinary Artistry. These Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Flavors. Join us on this Journey of Achievement as We Continue to Define the Art of Culinary Service.`
    awards1_awards = [
        {
            Award: "Exemplary Flavor Excellence Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Recognizing dedication to exceptional flavors that satisfy cravings and elevate customer delight."
        },
        {
            Award: "Innovation in Culinary Creation Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Celebrating innovative approaches that blend tradition with modern culinary trends for remarkable culinary experiences."
        },
        {
            Award: "Ultimate Cravings Haven Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Honoring commitment to providing a haven of flavors where customers experience culinary delight and satisfaction."
        },
        {
            Award: "Masterful Flavor Artistry Award",
            Year: "2023",
            Location: "City, State",
            Desc: "Commending mastery in delivering culinary creations with precision and flavor."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the World of ${c_businessName}`
    bio1_heading1 = `Experience Timeless Flavor at ${c_businessName}`
    bio1_text1 = `Immerse yourself in the unparalleled world of exceptional culinary experiences at ${c_businessName}. Our skilled professionals are dedicated to creating memorable interactions that enhance your cravings and elevate your satisfaction. With precision techniques and a flair for modern flavor, we craft culinary experiences that reflect your unique preferences. Whether it's a classic recipe or an innovative creation, your satisfaction is our top priority. Join us for a culinary experience that transcends the ordinary.`
    bio1_heading2 = `A Legacy of Culinary Craftsmanship and Care at ${c_businessName}`
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable culinary craftsmanship and customer-centric care. From the moment you engage with us, you're greeted with a blend of tradition and contemporary flavor. Our professionals are not just experts; they are artists who craft culinary creations with precision and passion. Beyond the transaction, we create an ambiance of flavor and satisfaction, ensuring that every interaction is a delightful escape. Experience the fusion of tradition and innovation at ${c_businessName}.`
    bio1_heading3 = `Discover Exceptional Flavors at ${c_businessName}`
    bio1_text3 = `Step into a world where every flavor matters – welcome to ${c_businessName}. Our dedicated team understands that culinary interactions are more than just transactions; they're about satisfying your cravings. With careful attention to your cravings and a touch of our culinary expertise, we craft flavors that resonate with your unique story. From meticulously crafted recipes to innovative creations, your journey to culinary satisfaction begins here. Join us at ${c_businessName} and rediscover the art of exceptional flavors.`
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "The Art of Crafting Delicious Coffee",
            Date: new Date(),
            Desc: "Discover the intricate art of brewing the perfect cup of coffee. Learn about the rich history, precise techniques, and modern innovations that go into creating the coffee experience at our cafe.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        The Art of Crafting Delicious Coffee
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Dive into the world of coffee craftsmanship and explore the journey of transforming coffee beans into a delightful cup of java. Discover the history, techniques, and innovations that shape the coffee experience at our cafe.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the selection of premium beans to the precision of brewing methods, every step in the process contributes to the unique flavor profiles we offer. Join us as we take you behind the scenes to learn the secrets of making the perfect cup of coffee.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the origins of coffee culture and how it has evolved over time. At our cafe, we blend tradition with modernity to create a coffee experience that satisfies the cravings of coffee enthusiasts and connoisseurs alike.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the artistry of crafting a cup of coffee that not only energizes but also indulges your senses. Join us on a journey through the world of coffee and discover the dedication that goes into every brew at our cafe.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "The Pastry Perfection: A Sweet Journey",
            Date: new Date(),
            Desc: "Take a delightful journey into the world of pastries. Explore the art of creating delectable pastries that melt in your mouth. Learn about the ingredients, techniques, and flavors that make our pastries a sweet sensation.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        The Pastry Perfection: A Sweet Journey
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Indulge in the world of pastries as we unveil the secrets behind crafting mouthwatering delights. Explore the art of creating pastries that are not only visually appealing but also a sweet sensation for your taste buds.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From selecting the finest ingredients to mastering the techniques of baking, our pastry chefs take you on a sweet journey through the world of confectionery. Join us as we delve into the flavors, textures, and aromas that define our pastries.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the magic that happens in our kitchen as we blend traditional recipes with innovative twists to create pastries that leave a lasting impression. Every bite is a testament to our commitment to pastry perfection.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a sweet journey that celebrates the artistry of pastry-making. Whether you're a pastry enthusiast or simply looking to satisfy your sweet cravings, our pastries promise to delight your senses and leave you craving for more.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Sip and Savor: The World of Smoothies",
            Date: new Date(),
            Desc: "Embark on a flavorful journey through the world of smoothies. Learn about the fresh ingredients, creative combinations, and health benefits that make our smoothies a delightful and nutritious choice for every palate.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Sip and Savor: The World of Smoothies
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Immerse yourself in the world of smoothies as we explore the art of blending fresh ingredients into delicious and nutritious concoctions. Learn about the vibrant flavors, health benefits, and creative combinations that make our smoothies a favorite choice for every palate.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the selection of ripe fruits to the addition of superfoods and the perfect blend of textures, our smoothie artisans craft beverages that not only refresh but also nourish your body and soul. Join us on a journey through the world of sippable sensations.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the balance of flavor and health in each sip as we unveil the secrets behind our smoothie recipes. Whether you're a health-conscious individual or simply seeking a tasty treat, our smoothies offer a delightful and refreshing experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a flavorful journey that celebrates the world of sipping and savoring. With a range of smoothie options to choose from, you can sip your way to satisfaction while enjoying the goodness of fresh ingredients and creative blends.
                    </p>
                </div>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team`;
    careers1_caption1 = `Explore exciting career opportunities at our coffee, pastries, and smoothies shop. Join us in delivering exceptional experiences to our valued customers.`;
    careers1_positions = [
        {
            Title: "Barista",
            Desc: "Are you a skilled barista with a passion for crafting perfect coffee? Join our team and create delightful coffee experiences for our customers. Your attention to detail and love for coffee will be the key to our success."
        },
        {
            Title: "Pastry Chef",
            Desc: "Ready to showcase your pastry-making skills? We're looking for a talented pastry chef to create mouthwatering pastries that our customers will love. Join us in delivering the sweetest moments to our patrons."
        },
        {
            Title: "Smoothie Specialist",
            Desc: "Join us as a smoothie specialist and blend fresh, nutritious smoothies that keep our customers coming back for more. Your creativity and knowledge of healthy ingredients will play a vital role in our shop's success."
        },
        {
            Title: "Customer Service Representative",
            Desc: "As a Customer Service Representative, you'll be the friendly face that greets our customers, takes orders, and ensures they have a seamless and enjoyable experience. Your excellent communication skills will make a difference."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Get in Touch`;
    contact1_mapSearchString = `Coffee, Pastries, and Smoothies Shop Location`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Special Offers`;
    coupons1_caption = `Discover our latest deals and enjoy savings on your favorite coffee, pastries, and smoothies. Don't miss out on these exclusive offers.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Coffee Delight",
            Details: "Savor the rich flavor of our premium coffee at a special discount. Start your day with a cup of perfection and save."
        },
        {
            Image: couponImg1,
            Coupon: "Pastry Indulgence",
            Details: "Treat yourself to a sweet escape with our delectable pastries. Get a discount on your favorite pastry and satisfy your cravings."
        },
        {
            Image: couponImg1,
            Coupon: "Smoothie Bliss",
            Details: "Refresh and revitalize with our nutritious smoothies. Enjoy a special offer on our smoothie menu and stay healthy."
        },
        {
            Image: couponImg1,
            Coupon: "First Visit Bonus",
            Details: "Welcome to our shop! Enjoy a special discount on your first visit and experience the art of coffee, pastries, and smoothies."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Coffee Tasting Experience",
            Desc: "Join us for a coffee tasting event where our experts will showcase the finest coffee beans and brewing techniques. Learn to appreciate the nuances of coffee flavors.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Pastry Workshop",
            Desc: "Discover the art of pastry-making in our hands-on workshop. Create your pastries and take home your delicious creations.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Smoothie Sensation Day",
            Desc: "Celebrate the goodness of smoothies with us. Enjoy free smoothie samples, learn about healthy ingredients, and embrace the smoothie lifestyle.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Why Choose Us`;
    features1_features = [
        {
            Feature: "Quality Coffee",
            Desc: "We take pride in brewing the finest coffee using premium beans, ensuring every cup is a delightful experience.",
            Subject: "Coffee"
        },
        {
            Feature: "Delectable Pastries",
            Desc: "Our pastries are crafted with love and expertise, delivering sweet moments to satisfy your cravings.",
            Subject: "Pastries"
        },
        {
            Feature: "Nutritious Smoothies",
            Desc: "Our smoothies are packed with fresh, healthy ingredients, providing a tasty and revitalizing option for your day.",
            Subject: "Smoothies"
        },
        {
            Feature: "Friendly Service",
            Desc: "Our team is dedicated to providing friendly and efficient service, making your visit enjoyable and memorable.",
            Subject: "Service"
        },
        {
            Feature: "Cozy Ambiance",
            Desc: "Relax in our cozy shop's ambiance, designed to create a welcoming environment for you to enjoy your coffee, pastries, and smoothies.",
            Subject: "Ambiance"
        }
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Photo Gallery`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `Our Story`;
    history1_caption = `Journey Through Time`;
    history1_heading1 = `Where Coffee, Pastries, and Smoothies Come Together in Perfect Harmony.`;
    history1_text1 = `Our shop's history is a tale of passion, flavor, and innovation. From brewing the perfect cup of coffee to crafting delectable pastries and blending nutritious smoothies, we've been on a journey to create a haven for coffee, pastry, and smoothie enthusiasts. Our commitment to quality and taste remains unwavering.`;
    history1_heading2 = `Join Us in Crafting the Future of Flavorful Experiences.`;
    history1_text2 = `As we celebrate our history, we look forward to sharing more delightful moments with you. With a team of dedicated baristas, pastry chefs, and smoothie specialists, we continue to be your go-to destination for coffee, pastries, and smoothies that tantalize your taste buds. Join us in savoring the future together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Our Locations`;
    locations1_locations = [
        {
            Location: "Ocean Beach Shop",
            Address: "4984 Voltaire St Ocean Beach, CA 92107",
            Hours: "Mon - Fri: 7 AM - 8 PM\nSat - Sun: 8 AM - 6 PM"
        },
        {
            Location: "El Cajon Shop",
            Address: "1275 Broadway El Cajon, CA 92021",
            Hours: "Mon - Fri: 8 AM - 7 PM\nSat - Sun: 9 AM - 5 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join Our Mailing List`;
    mailingList1_caption = `Stay updated on our latest coffee, pastries, and smoothie offerings. Enter your email to become a part of our flavor-loving community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Our Menu`;
    menu1_menu = [
        {
            Name: "Espresso Shot",
            Category: "Coffee",
            Prices: [
                {
                    Option: "Single Shot",
                    Price: "$2.00"
                },
                {
                    Option: "Double Shot",
                    Price: "$3.50"
                }
            ],
            Desc: "Experience the bold and rich flavors of our espresso shots."
        },
        {
            Name: "Chocolate Croissant",
            Category: "Pastries",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$3.50"
                },
                {
                    Option: "Large",
                    Price: "$4.50"
                }
            ],
            Desc: "Satisfy your sweet tooth with our chocolate-filled croissant."
        },
        {
            Name: "Tropical Paradise Smoothie",
            Category: "Smoothies",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$5.50"
                },
                {
                    Option: "Large",
                    Price: "$6.50"
                }
            ],
            Desc: "Escape to a tropical paradise with this refreshing smoothie blend."
        },
        {
            Name: "Mocha Latte",
            Category: "Coffee",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$4.00"
                },
                {
                    Option: "Large",
                    Price: "$5.00"
                }
            ],
            Desc: "Indulge in the perfect harmony of coffee and chocolate with our mocha latte."
        },
        {
            Name: "Blueberry Muffin",
            Category: "Pastries",
            Prices: [
                {
                    Option: "Single",
                    Price: "$2.50"
                },
                {
                    Option: "Double",
                    Price: "$4.50"
                }
            ],
            Desc: "Enjoy the classic goodness of our freshly baked blueberry muffin."
        },
        {
            Name: "Protein Power Smoothie",
            Category: "Smoothies",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$6.00"
                },
                {
                    Option: "Large",
                    Price: "$7.00"
                }
            ],
            Desc: "Fuel your day with our protein-packed smoothie for a healthy boost."
        },
        {
            Name: "Iced Americano",
            Category: "Coffee",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$3.50"
                },
                {
                    Option: "Large",
                    Price: "$4.50"
                }
            ],
            Desc: "Stay cool and caffeinated with our refreshing iced Americano."
        },
        {
            Name: "Apple Turnover",
            Category: "Pastries",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$3.00"
                },
                {
                    Option: "Large",
                    Price: "$4.00"
                }
            ],
            Desc: "Delight in the flaky goodness of our apple turnover."
        },
        {
            Name: "Detox Green Smoothie",
            Category: "Smoothies",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$5.50"
                },
                {
                    Option: "Large",
                    Price: "$6.50"
                }
            ],
            Desc: "Cleanse and rejuvenate with our detoxifying green smoothie."
        },
        {
            Name: "Caramel Macchiato",
            Category: "Coffee",
            Prices: [
                {
                    Option: "Regular",
                    Price: "$4.00"
                },
                {
                    Option: "Large",
                    Price: "$5.00"
                }
            ],
            Desc: "Sip on the sweetness of caramel in our caramel macchiato."
        },
        // Add more items here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `Our Partnerships`;
    partners1_caption = `We collaborate with trusted partners to bring you the finest ingredients and flavors.`;
    partners1_partners = [
        {
            Partner: "Coffee Bean Co.",
            Desc: "We source our premium coffee beans from Coffee Bean Co., known for their high-quality beans.",
            Link: "https://coffeebeanco.com",
            Img: partnerImg1
        },
        {
            Partner: "Sweet Creations Bakery",
            Desc: "Our pastries are freshly delivered from Sweet Creations Bakery, a local favorite for baked goods.",
            Link: "https://sweetcreationsbakery.com",
            Img: partnerImg1
        },
        {
            Partner: "Fresh Harvest Farms",
            Desc: "We get our fresh fruits and vegetables for smoothies from Fresh Harvest Farms, ensuring top-notch quality.",
            Link: "https://freshharvestfarms.com",
            Img: partnerImg1
        },
        {
            Partner: "Coffee Bean Co.",
            Desc: "We source our premium coffee beans from Coffee Bean Co., known for their high-quality beans.",
            Link: "https://coffeebeanco.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Our Portfolio`;
    portfolio1_caption = `Explore our curated collection of coffee, pastries, and smoothies that showcase our delicious offerings.`;
    portfolio1_projects = [
        {
            Project: "Coffee Creations",
            Desc: "Discover our signature coffee creations, each crafted to perfection.",
            Link: "https://portfolio.com/coffeecreations",
            Image: portfolioImg1
        },
        {
            Project: "Pastry Paradise",
            Desc: "Indulge in the world of pastries with our mouthwatering selection.",
            Link: "https://portfolio.com/pastryparadise",
            Image: portfolioImg1
        },
        {
            Project: "Smoothie Sensations",
            Desc: "Experience the goodness of our revitalizing smoothies that energize your day.",
            Link: "https://portfolio.com/smoothiesensations",
            Image: portfolioImg1
        },
        {
            Project: "Coffee Creations",
            Desc: "Discover our signature coffee creations, each crafted to perfection.",
            Link: "https://portfolio.com/coffeecreations",
            Image: portfolioImg1
        },
        // Add more projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Choose from our pricing packages and enjoy the best coffee, pastries, and smoothies.`;
    pricing1_prices = [
        {
            Price: "$5.00",
            Desc: "Single Coffee & Pastry Combo"
        },
        {
            Price: "$10.00",
            Desc: "Double Coffee & Pastry Combo"
        },
        {
            Price: "$7.00",
            Desc: "Single Smoothie"
        },
        {
            Price: "$14.00",
            Desc: "Double Smoothie Combo"
        },
        // Add more packages here...
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`;
    products1_caption = `Enhance your experience with premium coffee beans and more.`;
    products1_products = [
        {
            Name: "Premium Coffee Beans",
            Desc: "Experience the rich flavor of our premium coffee beans sourced from the best regions.",
            Price: "$12.00",
            Image: productsImg1
        },
        {
            Name: "Pastry Mix Pack",
            Desc: "Bake delicious pastries at home with our pastry mix pack, including all your favorite flavors.",
            Price: "$8.00",
            Image: productsImg2
        },
        {
            Name: "Smoothie Blender",
            Desc: "Blend your smoothies like a pro with our high-quality smoothie blender.",
            Price: "$35.00",
            Image: productsImg3
        },
        {
            Name: "Premium Coffee Beans",
            Desc: "Experience the rich flavor of our premium coffee beans sourced from the best regions.",
            Price: "$12.00",
            Image: productsImg4
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get a Quote`;
    quote1_caption = `Where flavor meets excellence. Share your preferences and let us create a memorable experience for you.`;
    quote1_heading1 = `Select an Option`;
    quote1_heading2 = `Tell Us Your Preferences`;
    quote1_services = [
        `Coffee Selection`,
        `Pastry Delights`,
        `Smoothie Bliss`,
        `Custom Combo`,
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Customer Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "John Doe",
            Review: "The coffee here is incredible, and the pastries are to die for. I'm a regular customer now!",
            Rating: "5.0",
            Date: "08/05/2023"
        },
        {
            Reviewer: "Jane Smith",
            Review: "The smoothies are so fresh and healthy. I love the variety of options they offer.",
            Rating: "4.8",
            Date: "07/28/2023"
        },
        {
            Reviewer: "Michael Johnson",
            Review: "This place has the best coffee in town, hands down. The service is friendly and quick too!",
            Rating: "4.9",
            Date: "09/15/2023"
        },
        {
            Reviewer: "Emily Adams",
            Review: "I can't resist their pastries. They're always so soft and delicious. Highly recommended!",
            Rating: "5.0",
            Date: "10/03/2023"
        }
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Select a Date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Finalize Your Appointment`;
    schedule1_formCaption = `You've chosen the following time slot for your visit:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Our Services`;
    services1_caption = `Experience our top-tier services that delight your taste buds and rejuvenate your day.`;
    services1_services = [
        {
            Name: "Coffee Service",
            Desc: "Savor our freshly brewed coffee that awakens your senses and brightens your day.",
            Image: servicesImg1
        },
        {
            Name: "Pastry Delights",
            Desc: "Indulge in our delectable pastries that melt in your mouth with each bite.",
            Image: servicesImg2
        },
        {
            Name: "Smoothie Bliss",
            Desc: "Revitalize with our nutritious smoothies packed with flavors and nutrients.",
            Image: servicesImg3
        },
        {
            Name: "Custom Combos",
            Desc: "Create your own combo with a choice of coffee, pastry, and smoothie for a personalized experience.",
            Image: servicesImg1
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet Our Team`;
    team1_caption = `Meet the passionate individuals behind the flavors and smiles.`;
    team1_teamMembers = [
        {
            Name: "Barista Bella",
            Position: "Coffee Artist",
            Image: teamImg1
        },
        {
            Name: "Patty the Pastry Chef",
            Position: "Pastry Master",
            Image: teamImg2
        },
        {
            Name: "Smoothie Sam",
            Position: "Smoothie Guru",
            Image: teamImg3
        },
        {
            Name: "Custom Combo Charlie",
            Position: "Flavor Specialist",
            Image: teamImg4
        },
        {
            Name: "Barista Bella",
            Position: "Coffee Artist",
            Image: teamImg1
        },
        // Add more team members here...
    ];
    // #endregion
} else if (c_businessType == "Jalisco") {
    // #region HOME 1
    home1_heading1 = `Savor the Flavors of Mexico in Sunny San Diego! Visit Us Today!`
    home1_caption1 = `From freshly brewed coffee to mouthwatering Mexican pastries and refreshing aguas frescas, our team provides a delightful oasis of Mexican flavors, ensuring you leave not just satisfied, but craving for more.`
    home1_buttonText1 = `Visit Us`
    // #endregion
    // #region HOME 2
    home2_caption = `Savor the Authentic Taste of Mexico at ${c_businessName} - where Mexican Flavor Meets Modern Indulgence`
    home2_heading1 = `Savor the Finest Mexican Flavors at ${c_businessName} - where Tradition Meets Innovation`
    home2_text1 = `Step into the World of Mexican Culinary Excellence, Where Our Skilled Artisans Are Masters of Mexican Flavor. With a Keen Palate for Detail and a Passion for Unveiling Unique Mexican Tastes, We Craft Creations into Mexican Culinary Masterpieces. Our Craftsmanship Reflects Your Cravings for Authentic Mexican Cuisine, Ensuring You Depart Satisfied, and Eager for More. Trust Us to Craft Your Signature Mexican Experience, Redefining Your Journey with Mexican Taste and Temptation.`
    home2_buttonText1 = `View Menu`
    home2_buttonNav1 = `menu`
    home2_heading2 = `Experience Mexican Flavor Redefined at ${c_businessName}`
    home2_text2 = `Experience the Epitome of Mexican Taste and Refreshment: From Delectable Mexican Pastries to Authentic Mexican Aguas Frescas, Our Team Curates a Mexican Paradise Where Flavor and Indulgence Converge. Every Moment in Our Mexican Restaurant Is a Journey to Culinary Mexican Delight. We Go Beyond Ordinary Offerings, Ensuring You Depart Not Only Satisfied, but Craving for More. Trust Us to Elevate Your Tastebuds Beyond the Ordinary with Authentic Mexican Flavors.`
    home2_buttonText2 = `View Locations`
    home2_buttonNav2 = `locations`
    // #endregion
    // #region HOME 3
    home3_heading1 = `The Flavorful World of Mexico in Your Cup!`
    home3_caption1 = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.`
    home3_buttonText1 = `Visit Us`
    home3_buttonNav1 = `menu`
    home3_subBlocks = [
        {
            Title: "Our Mexican Pastries",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "pastries"
        },
        {
            Title: "Authentic Mexican Aguas Frescas",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "aguasfrescas"
        },
        {
            Title: "Mexican Coffee Delights",
            Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan.",
            ButtonText: "Learn More",
            ButtonNav: "coffee"
        }
    ]
    // #endregion
    // #region HOME 4
    home4_heading1 = "Fueling Your Day with Authentic Mexican Flavors.";
    home4_caption1 = "Experience the potential of Mexican taste and innovation with our dedicated team. We're committed to helping you satisfy your cravings and reach new heights of culinary Mexican delight.";
    home4_buttonText1 = "Learn More";
    home4_buttonNav1 = "about";
    home4_heading2 = "Turning Mexican Tastes into Reality.";
    home4_caption2 = "Discover a world of Mexican culinary solutions and possibilities at our Mexican restaurant. Our experts are passionate about crafting Mexican flavors that turn your cravings into tangible Mexican satisfaction.";
    home4_subBlocks = [
        {
            Heading: "Crafted Mexican Culinary Delights.",
            Caption: "From delectable Mexican pastries to refreshing Mexican aguas frescas, our Mexican restaurant offers a diverse range of Mexican flavors to satisfy your cravings."
        },
        {
            Heading: "Masters of Mexican Culinary Excellence.",
            Caption: "Experience the perfect blend of Mexican taste expertise and innovative Mexican culinary thinking. Our experts are dedicated to satisfying your Mexican cravings with Mexican culinary creations that stand out."
        },
        {
            Heading: "Satisfying Mexican Cravings and Desires.",
            Caption: "Indulge in Mexican culinary solutions that not only delight your taste buds but also elevate your Mexican culinary experience in a dynamic marketplace."
        },
        {
            Heading: "Mexican Flavorful Confidence.",
            Caption: "Savor our Mexican creations with confidence, knowing that our Mexican restaurant is dedicated to providing you with Mexican flavors that align with your Mexican cravings and desires."
        },
    ]
    // #endregion HOME 4
    // #region HOME 5
    home5_heading1 = `Experience Mexican Culinary Excellence at ${c_businessName}. Contact Us Today!`
    home5_caption1 = `From mouthwatering Mexican pastries to refreshing Mexican aguas frescas, our dedicated team offers a haven of Mexican flavors and innovation, ensuring your cravings are satisfied and your taste buds are delighted.`;
    home5_buttonText1 = `Contact Us`;
    home5_buttonNav1 = `contact`;
    home5_heading2 = `Experience Mexican Culinary Delight at ${c_businessName}`;
    home5_caption2 = `Experience the Epitome of Mexican Culinary Solutions: From Delectable Mexican Pastries to Refreshing Mexican Aguas Frescas, Our Mexican Experts Craft a Sanctuary Where Mexican Flavor and Satisfaction Intersect.`;
    home5_subBlocks = [
        {
            Heading: "Tailored Mexican Culinary Offerings",
            Caption: "Experience the mastery of our Mexican experts who provide a range of Mexican culinary delights tailored to satisfy your unique Mexican cravings. Whether you're in the mood for Mexican pastries, aguas frescas, or Mexican coffee, we have you covered."
        },
        {
            Heading: "Innovative Mexican Flavors",
            Caption: "Indulge in Mexican culinary creations that satisfy your Mexican cravings and elevate your Mexican taste experience. Our skilled Mexican professionals offer Mexican flavors that take you on a Mexican culinary journey in a dynamic marketplace."
        },
        {
            Heading: "Satisfying Your Mexican Cravings",
            Caption: "Stay ahead of your Mexican cravings with our Mexican experts' knowledge in crafting Mexican flavors that ensure your Mexican satisfaction, adaptability to your Mexican desires, and readiness for new Mexican culinary experiences."
        },
        {
            Heading: "Reliable Mexican Culinary Expertise",
            Caption: "Immerse yourself in reliable Mexican culinary support where the fusion of expert Mexican taste and a commitment to satisfying your Mexican cravings ensures your Mexican culinary journey reaches new heights of Mexican delight."
        },
    ]
    // #endregion HOME 5
    // #region ABOUT 1
    about1_pageTitle = `About Us`
    about1_heading1 = `The Artistry of Mexican Flavor: Crafting Memorable Mexican Culinary Experiences at ${c_businessName}`
    about1_text1 = `At ${c_businessName}, our story is one of passion, dedication, and a commitment to redefining customer Mexican culinary experiences. Founded with the vision of creating more than just a Mexican cafe, we've cultivated an environment where Mexican tradition and innovation coexist harmoniously. Our journey began with the belief that Mexican culinary service is an art, and our skilled Mexican professionals are the artists. With each Mexican creation, we curate unique expressions of Mexican flavor that reflect the essence of our Mexican customers. The fusion of timeless Mexican recipes with contemporary Mexican approaches is what sets us apart. From the moment you engage with us, you're not just a Mexican customer – you're part of a Mexican culinary experience waiting to be transformed into a Mexican masterpiece of satisfaction. Welcome to ${c_businessName}, where Mexican flavor is an experience, and your Mexican cravings become a part of ours.`
    about1_heading2 = `Beyond Expectations: Discovering Mexican Deliciousness at ${c_businessName}`
    about1_text2 = `At ${c_businessName}, our Mexican culinary offerings are more than just transactions; they are Mexican culinary experiences meticulously designed to satisfy your Mexican cravings and elevate your Mexican delight. Our team of highly skilled Mexican professionals brings Mexican culinary expertise to every Mexican creation, ensuring that every bite is a Mexican artful endeavor. From classic Mexican recipes that pay homage to Mexican time-honored traditions to contemporary Mexican innovations that push the boundaries of Mexican culinary style, our Mexican offerings cater to diverse Mexican cravings. Savor the luxury of a Mexican delightful experience that combines meticulous attention to Mexican flavor with Mexican satisfaction. We take pride in curating a Mexican atmosphere where you can indulge, connect, and leave not only satisfied but also delighted. Step into a Mexican world where Mexican flavor becomes a statement, and each Mexican culinary creation with ${c_businessName} is an opportunity to experience something exceptional.`
    // #endregion
    // #region AWARDS 1
    awards1_pageTitle = `Awards`
    awards1_caption = `Embrace a Tapestry of Mexican Culinary Excellence as Our Mexican Cafe Earns Recognition: Each Award a Testament to Our Dedication. From Outstanding Mexican Flavors to Mexican Customer Delight, We Proudly Stand as a Beacon of Mexican Culinary Artistry. These Mexican Honors Are More Than Accolades; They're a Celebration of Our Passion for Elevating Mexican Flavors. Join us on this Mexican Journey of Achievement as We Continue to Define the Art of Mexican Culinary Service.`
    awards1_awards = [
        {
            Award: "Exemplary Mexican Flavor Excellence Award",
            Year: "2023",
            Location: "San Diego, California",
            Desc: "Recognizing dedication to exceptional Mexican flavors that satisfy Mexican cravings and elevate Mexican customer delight."
        },
        {
            Award: "Innovation in Mexican Culinary Creation Award",
            Year: "2023",
            Location: "San Diego, California",
            Desc: "Celebrating innovative Mexican approaches that blend Mexican tradition with modern Mexican culinary trends for remarkable Mexican culinary experiences."
        },
        {
            Award: "Ultimate Mexican Cravings Haven Award",
            Year: "2023",
            Location: "San Diego, California",
            Desc: "Honoring commitment to providing a haven of Mexican flavors where Mexican customers experience Mexican culinary delight and Mexican satisfaction."
        },
        {
            Award: "Masterful Mexican Flavor Artistry Award",
            Year: "2023",
            Location: "San Diego, California",
            Desc: "Commending mastery in delivering Mexican culinary creations with precision and Mexican flavor."
        }
    ];
    // #endregion
    // #region BIO 1
    bio1_pageTitle = `Discover the Mexican World of ${c_businessName}`
    bio1_heading1 = `Experience Timeless Mexican Flavor at ${c_businessName}`
    bio1_text1 = `Immerse yourself in the unparalleled Mexican world of exceptional Mexican culinary experiences at ${c_businessName}. Our skilled Mexican professionals are dedicated to creating memorable Mexican interactions that enhance your Mexican cravings and elevate your Mexican satisfaction. With precision Mexican techniques and a flair for modern Mexican flavor, we craft Mexican culinary experiences that reflect your unique Mexican preferences. Whether it's a classic Mexican recipe or an innovative Mexican creation, your Mexican satisfaction is our top priority. Join us for a Mexican culinary experience that transcends the ordinary.`
    bio1_heading2 = `A Legacy of Mexican Culinary Craftsmanship and Care at ${c_businessName}`
    bio1_text2 = `At ${c_businessName}, we take pride in our legacy of impeccable Mexican culinary craftsmanship and Mexican customer-centric care. From the moment you engage with us, you're greeted with a blend of Mexican tradition and contemporary Mexican flavor. Our Mexican professionals are not just Mexican experts; they are Mexican artists who craft Mexican culinary creations with precision and Mexican passion. Beyond the Mexican transaction, we create a Mexican ambiance of Mexican flavor and Mexican satisfaction, ensuring that every Mexican interaction is a delightful Mexican escape. Experience the fusion of Mexican tradition and innovation at ${c_businessName}.`
    bio1_heading3 = `Discover Exceptional Mexican Flavors at ${c_businessName}`
    bio1_text3 = `Step into a Mexican world where every Mexican flavor matters – welcome to ${c_businessName}. Our dedicated Mexican team understands that Mexican culinary interactions are more than just Mexican transactions; they're about satisfying your Mexican cravings. With careful attention to your Mexican cravings and a touch of our Mexican culinary expertise, we craft Mexican flavors that resonate with your unique Mexican story. From meticulously crafted Mexican recipes to innovative Mexican creations, your journey to Mexican culinary satisfaction begins here. Join us at ${c_businessName} and rediscover the art of exceptional Mexican flavors.`
    // #endregion
    // #region BLOG 1
    blog1_pageTitle = `Blog`;
    blog1_blogs = [
        {
            id: 0,
            Image: blog1img1,
            Title: "The Art of Crafting Authentic Mexican Cuisine",
            Date: new Date(),
            Desc: "Embark on a flavorful journey through the art of crafting authentic Mexican cuisine. Learn about the rich history, traditional techniques, and modern innovations that bring the flavors of Mexico to your table at our restaurant.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog1img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        The Art of Crafting Authentic Mexican Cuisine
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Embark on a culinary adventure as we explore the art of crafting authentic Mexican cuisine. Discover the historical roots, traditional cooking techniques, and modern innovations that come together to create a fiesta of flavors at our restaurant.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From selecting the finest ingredients to mastering the intricacies of Mexican recipes, every step in our kitchen is a celebration of the rich culinary heritage of Mexico. Join us as we unveil the secrets behind crafting the perfect Mexican dishes.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Explore the flavors, spices, and regional influences that make Mexican cuisine a globally beloved choice. At our restaurant, we blend tradition with innovation to serve up a taste of Mexico that satisfies food enthusiasts and connoisseurs alike.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Experience the artistry of crafting authentic Mexican dishes that not only tantalize your taste buds but also transport you to the heart of Mexico. Join us on a culinary journey and discover the passion that goes into every dish at our restaurant.
                    </p>
                </div>
            )
        },
        {
            id: 1,
            Image: blog2img1,
            Title: "The Sweet Delights of Mexican Desserts",
            Date: new Date(),
            Desc: "Indulge in the world of Mexican desserts as we take you on a sweet journey through the art of creating delectable treats that bring a taste of Mexico to your table. Learn about the ingredients, techniques, and flavors that make our desserts a sweet sensation.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog2img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        The Sweet Delights of Mexican Desserts
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Indulge in the world of Mexican desserts as we unveil the secrets behind crafting mouthwatering treats that are not only visually appealing but also a sweet sensation for your taste buds.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From selecting the finest ingredients to mastering the techniques of dessert-making, our pastry chefs take you on a sweet journey through the vibrant world of Mexican confectionery. Join us as we delve into the flavors, textures, and aromas that define our desserts.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the magic that happens in our kitchen as we blend traditional Mexican dessert recipes with innovative twists to create treats that leave a lasting impression. Every bite is a testament to our commitment to dessert perfection.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a sweet journey that celebrates the artistry of Mexican dessert-making. Whether you're a dessert enthusiast or simply looking to satisfy your sweet cravings, our Mexican desserts promise to delight your senses and leave you craving for more.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            Image: blog3img1,
            Title: "Sip and Savor: Mexican Beverages",
            Date: new Date(),
            Desc: "Embark on a flavorful journey through the world of Mexican beverages. Learn about the fresh ingredients, creative combinations, and cultural significance that make our Mexican drinks a delightful choice for every palate.",
            HTML: (
                <div>
                    <div style={{ height: '4vh' }} />
                    <div style={{ height: '60vh' }}>
                        <img src={blog3img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                    <div style={{ height: '4vh' }} />
                    <h1 style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1.2', fontFamily: c_textFont }}>
                        Sip and Savor: Mexican Beverages
                    </h1>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Immerse yourself in the world of Mexican beverages as we explore the art of blending fresh ingredients into delicious and culturally significant concoctions. Learn about the vibrant flavors, cultural heritage, and creative combinations that make our Mexican drinks a favorite choice for every palate.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        From the selection of authentic ingredients to the time-honored preparation methods, our beverage artisans craft drinks that not only refresh but also provide a taste of Mexican traditions. Join us on a journey through the world of sippable sensations.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Discover the balance of flavor, culture, and refreshment in each sip as we unveil the secrets behind our Mexican beverage recipes. Whether you're seeking a traditional drink or a creative Mexican-inspired concoction, our beverages offer a delightful and culturally enriching experience.
                    </p>
                    <div style={{ height: '2vh' }} />
                    <p style={{ fontSize: '1.2em', lineHeight: '1.5', fontFamily: c_textFont }}>
                        Join us on a flavorful journey that celebrates the world of sipping and savoring Mexican beverages. With a range of drink options to choose from, you can sip your way to satisfaction while enjoying the rich cultural tapestry of Mexico.
                    </p>
                </div>
            )
        },
    ];
    // #endregion
    // #region CAREERS 1
    careers1_pageTitle = `Join Our Team`;
    careers1_caption1 = `Explore exciting career opportunities at our Mexican restaurant. Join us in delivering exceptional culinary experiences to our valued customers.`;
    careers1_positions = [
        {
            Title: "Chef de Cuisine",
            Desc: "Are you an experienced chef with a passion for crafting authentic Mexican dishes? Join our team and lead our kitchen in creating memorable dining experiences. Your culinary expertise will be the key to our success."
        },
        {
            Title: "Sous Chef",
            Desc: "Ready to assist our Chef de Cuisine in creating mouthwatering Mexican cuisine? We're looking for a skilled Sous Chef to support our culinary team in delivering the flavors of Mexico to our patrons."
        },
        {
            Title: "Bartender",
            Desc: "Join us as a bartender and craft signature Mexican cocktails that complement our delicious dishes. Your mixology skills and knowledge of Mexican spirits will play a vital role in enhancing our restaurant's offerings."
        },
        {
            Title: "Server",
            Desc: "As a Server, you'll be the friendly face that guides our guests through their dining experience, takes orders, and ensures they have a delightful time. Your excellent service will make a difference."
        },
    ];
    // #endregion
    // #region CONTACT 1
    contact1_pageTitle = `Get in Touch`;
    contact1_mapSearchString = `Jalisco cafe palm ave`;
    // #endregion
    // #region COUPONS 1
    coupons1_pageTitle = `Special Offers`;
    coupons1_caption = `Discover our latest deals and enjoy savings on your favorite Mexican dishes. Don't miss out on these exclusive offers.`;
    coupons1_coupons = [
        {
            Image: couponImg1,
            Coupon: "Taco Tuesdays",
            Details: "Enjoy our delicious tacos at a special discount every Tuesday. It's a fiesta of flavors that you won't want to miss."
        },
        {
            Image: couponImg1,
            Coupon: "Enchilada Extravaganza",
            Details: "Indulge in our enchiladas with a special offer that will satisfy your cravings. It's a cheesy, saucy delight."
        },
        {
            Image: couponImg1,
            Coupon: "Margarita Madness",
            Details: "Join us for Margarita Madness and enjoy discounts on our signature margaritas. It's the perfect way to celebrate the Mexican spirit."
        },
        {
            Image: couponImg1,
            Coupon: "Family Fiesta",
            Details: "Gather your family and friends for a Mexican feast. Get a discount on our family-sized platters and share the joy of Mexican cuisine."
        },
    ];
    // #endregion
    // #region EVENTS 1
    events1_pageTitle = `Upcoming Events`;
    events1_events = [
        {
            Title: "Taco Night Fiesta",
            Desc: "Join us for a taco night fiesta where we'll be serving a variety of mouthwatering tacos. It's a celebration of flavor!",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Salsa Dancing Night",
            Desc: "Put on your dancing shoes and join us for a night of salsa dancing. Enjoy live music, great food, and lots of dancing!",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
        {
            Title: "Tequila Tasting",
            Desc: "Discover the world of tequila in our tequila tasting event. Sample different tequilas and learn about their unique flavors.",
            Date: "Upcoming Date",
            Image: eventsImg1
        },
    ];
    // #endregion
    // #region FEATURES 1
    features1_pageTitle = `Why Choose Us`;
    features1_features = [
        {
            Feature: "Authentic Mexican Flavors",
            Desc: "We take pride in serving authentic Mexican cuisine prepared with traditional recipes and the finest ingredients.",
            Subject: "Cuisine"
        },
        {
            Feature: "Handcrafted Cocktails",
            Desc: "Our bartenders craft handcrafted cocktails using premium Mexican spirits and fresh ingredients, elevating your dining experience.",
            Subject: "Cocktails"
        },
        {
            Feature: "Vibrant Atmosphere",
            Desc: "Experience the vibrant and welcoming atmosphere of Mexico right here in our restaurant, making every visit a fiesta.",
            Subject: "Atmosphere"
        },
        {
            Feature: "Friendly Staff",
            Desc: "Our team is dedicated to providing friendly and efficient service, ensuring your dining experience is enjoyable and memorable.",
            Subject: "Service"
        },
        {
            Feature: "Mexican Music",
            Desc: "Enjoy the rhythms of Mexico with live Mexican music performances on special occasions, adding to the festive ambiance.",
            Subject: "Music"
        },
    ];
    // #endregion
    // #region GALLERY 1
    gallery1_pageTitle = `Photo Gallery`;
    gallery1_images = [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
    ];
    // #endregion
    // #region HISTORY 1
    history1_pageTitle = `Our Story`;
    history1_caption = `A Taste of Mexico's Culinary Heritage`;
    history1_heading1 = `Where Mexican Flavors Come to Life.`;
    history1_text1 = `Our restaurant's history is a journey of passion for authentic Mexican cuisine, flavorful cocktails, and the vibrant culture of Mexico. From traditional recipes to modern interpretations, we've been on a quest to bring the richness of Mexican flavors to your table. Our commitment to authenticity remains unwavering.`;
    history1_heading2 = `Join Us in Savoring the Flavors of Mexico.`;
    history1_text2 = `As we celebrate our history, we look forward to sharing more delicious moments with you. With a team of dedicated chefs, bartenders, and servers, we continue to be your go-to destination for Mexican dishes and cocktails that transport you to Mexico. Join us in savoring the flavors of Mexico together.`;
    // #endregion
    // #region LOCATIONS 1
    locations1_pageTitle = `Our Locations`;
    locations1_locations = [
        {
            Location: "Jalisco Cafe Mexican Restaurant \nPalm Avenue",
            Address: "Jalisco Cafe 1669 Palm Avenue,\nSan Diego, CA 92154",
            Hours: "Mon - Sun:	06:00 AM - 08:00 PM"
        },
        {
            Location: "Jalisco Cafe Bonita Rd",
            Address: "Jalisco Cafe 4026 Bonita Rd,\nBonita, CA 91902",
            Hours: "Mon - Thurs: 07:00 AM - 09:00 PM,\nFri & Sat: 07:00 AM - 10:00 PM,\nSunday:	07:00 AM - 09:00 PM"
        }
    ];
    // #endregion
    // #region MAILING LIST 1
    mailingList1_pageTitle = `Join Our Mailing List`;
    mailingList1_caption = `Stay updated on our latest Mexican culinary offerings and special events. Enter your email to become a part of our flavor-loving community.`;
    // #endregion
    // #region MENU 1
    menu1_pageTitle = `Our Menu`;
    menu1_menu = [
        {
            Name: "Coffee",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Decaf Coffee",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Milk",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Mexican Hot Chocolate",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Hot Tea",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$2.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Hot Chocolate",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Iced Tea",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Juice",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Horchata-Jamaica Lemonade",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$4.35"
                }
            ],
            Desc: ""
        },
        {
            Name: "Cafe de Olla",
            Category: "Beverages",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "B1. Huevos Rancheros",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$10.99"
                }
            ],
            Desc: "Three eggs any style on a corn tortilla covered with ranchero sauce."
        },
        {
            Name: "B2. Tres Huevos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$8.99"
                }
            ],
            Desc: "Three eggs any style."
        },
        {
            Name: "B3. Chorizo Con Huevos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$10.45"
                }
            ],
            Desc: "Three scrambled eggs with spicy Mexican sausage."
        },
        {
            Name: "B4. Machaca con Huevos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$11.99"
                }
            ],
            Desc: "Three eggs scrambled with seasoned shredded beef."
        },
        {
            Name: "B5. Huevos Mexicanos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$10.49"
                }
            ],
            Desc: "Three eggs with tomatoes, onions and jalapenos."
        },
        {
            Name: "B6. Omelet Con Queso",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$9.99"
                }
            ],
            Desc: "Three egg omelet filled with Jack cheese and covered with ranchero sauce."
        },
        {
            Name: "B7. Omelet Con Jamon",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$11.99"
                }
            ],
            Desc: "Three egg omelet mixed ham, bacon or sausage and filled with Jack cheese."
        },
        {
            Name: "B8. Green Chile Omelet",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$11.99"
                }
            ],
            Desc: "Three eggs mixed green chile and filled with Jack cheese."
        },
        {
            Name: "B9. Carne Asada Con Huevos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Carne asada and three eggs any style."
        },
        {
            Name: "B10. Jamon Con Huevos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$12.99"
                }
            ],
            Desc: "Slice of charbroiled ham and three eggs any style."
        },
        {
            Name: "B11. Pollo Asado Con Huevos",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Charbroiled chicken and three eggs any style."
        },
        {
            Name: "A1. 2 Eggs",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$8.99"
                }
            ],
            Desc: "Two eggs any style."
        },
        {
            Name: "A2. 2 Eggs with Protein",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$9.99"
                }
            ],
            Desc: "Two eggs any style with protein."
        },
        {
            Name: "A3. 3 Eggs",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$9.45"
                }
            ],
            Desc: "Three eggs any style."
        },
        {
            Name: "A4. 3 Eggs with Protein",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$11.99"
                }
            ],
            Desc: "Three eggs any style with protein."
        },
        {
            Name: "A5. Cheese Omelet",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$10.99"
                }
            ],
            Desc: "Three egg omelet filled with Jack cheese, served with hash browns and toast."
        },
        {
            Name: "A6. Ham and Cheese Omelet",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$12.99"
                }
            ],
            Desc: "Three egg omelet with ham, bacon or sausage, and filled with Jack cheese, served with hash browns and toast."
        },
        {
            Name: "A7. Carne Asada and Eggs",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Carne asada served with three eggs, hash browns and toast."
        },
        {
            Name: "A8. Ham and Eggs",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$12.99"
                }
            ],
            Desc: "Slice of charbroiled ham and three eggs any style served with hash browns and toast."
        },
        {
            Name: "A9. Chicken and Eggs",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Charbroiled chicken and three eggs any style served with hash browns and toast."
        },
        {
            Name: "Jalisco Special Omelet",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$15.99"
                }
            ],
            Desc: "Four egg omelet filled with carne asada or pollo asado and jack cheese, served with beans and tortilla."
        },
        {
            Name: "Reuben's Omelet",
            Category: "Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Three egg omelet with tomatoes, onions, peppers and cheese."
        },
        {
            Name: "Chilaquiles",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$12.95"
                }
            ],
            Desc: "Crispy tortilla strips covered with choice of red chile or tomatillo sauce and melted cheese, topped with three eggs any style. Served with beans."
        },
        {
            Name: "Nopales con Huevos",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$13.25"
                }
            ],
            Desc: "Three scrambled eggs with cactus strips, onions, tomato, jalapeno and green chile. Served with beans and two flour tortillas or three corn tortillas."
        },
        {
            Name: "Eggs and Enchiladas",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$12.45"
                }
            ],
            Desc: "Three eggs any style with two cheese enchiladas, served with beans and three flour tortillas or two corn tortillas."
        },
        {
            Name: "Menudo",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "Served with two flour tortillas or three corn tortillas."
        },
        {
            Name: "Pozole",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "Served with two flour tortillas or three corn tortillas."
        },
        {
            Name: "Machaca Burrito Special",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "Two burritos filled with three scrambled eggs with seasoned, shredded beef and covered with ranchero sauce and cheese. Served with beans."
        },
        {
            Name: "Meat Omelet",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "Three egg omelet filled with jack cheese and covered with choice of chile con carne or pork tips in a mild green sauce. Served with beans and two flour tortillas or three corn tortillas."
        },
        {
            Name: "Chorizo con Huevos",
            Category: "Late Breakfast",
            Prices: [
                {
                    Option: "",
                    Price: "$10.45"
                }
            ],
            Desc: "Three scrambled eggs with spicy Mexican sausage, served with refried beans, rice and choice of tortillas."
        },
        {
            Name: "Machaca con Huevos",
            Category: "Breakfast Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$12.45"
                }
            ],
            Desc: "Three scrambled eggs with seasoned shredded beef, served with refried beans, rice and choice of tortillas."
        },
        {
            Name: "Hotcakes",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$5.50"
                }
            ],
            Desc: "Two."
        },
        {
            Name: "One Egg",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$2.75"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Beans",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Rice",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Three Flour Tortillas",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$2.50"
                }
            ],
            Desc: ""
        },
        {
            Name: "Two Corn Tortillas",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$2.50"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Bacon",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Sausage",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Ham",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.65"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Carne Asada",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$7.49"
                }
            ],
            Desc: ""
        },
        {
            Name: "Side of Pollo Asado",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$7.49"
                }
            ],
            Desc: ""
        },
        {
            Name: "Hash Browns",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.45"
                }
            ],
            Desc: ""
        },
        {
            Name: "Toast",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$6.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Oatmeal",
            Category: "Breakfast Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$3.59"
                }
            ],
            Desc: ""
        },
        {
            Name: "Jalapeno Poppers",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$7.69"
                }
            ],
            Desc: "Served with sour cream."
        },
        {
            Name: "Nachos",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$7.09"
                }
            ],
            Desc: "Fresh, crisp tortilla chips covered with melted cheddar and Jack cheese."
        },
        {
            Name: "Nachos Ole",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$12.59"
                }
            ],
            Desc: "Our delicious nachos topped with guacamole, beans and sour cream."
        },
        {
            Name: "Cheese Crisp",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$7.10"
                }
            ],
            Desc: "A crisp flour tortilla covered with melted cheese."
        },
        {
            Name: "Cheese Crisp Ole",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$7.55"
                }
            ],
            Desc: "A cheese crisp topped with beans, guacamole and sour cream."
        },
        {
            Name: "Guacamole Dip",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Regular Quesadilla",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$6.59"
                }
            ],
            Desc: "A large grilled flour tortilla filled with melted cheddar cheese."
        },
        {
            Name: "Green Chile Quesadilla",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$7.10"
                }
            ],
            Desc: "Made with jack cheese and green chile."
        },
        {
            Name: "Ranchero Quesadilla",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$7.99"
                }
            ],
            Desc: "Grilled green chiles, tomatoes, jalapenos, and onions."
        },
        {
            Name: "Ranchero Deluxe Quesadilla",
            Category: "Appetizers",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "Grilled green chiles, tomatoes, jalapenos, and onions."
        },
        {
            Name: "Menudo Soup",
            Category: "Soup",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Traditional Mexican soup with hominy."
        },
        {
            Name: "Pozole Soup",
            Category: "Soup",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Tender chunks of pork with hominy."
        },
        {
            Name: "Vegetable Soup",
            Category: "Soup",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Potatoes, carrots, zucchini and more."
        },
        {
            Name: "Albondigas",
            Category: "Soup",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Mexican meatball soup."
        },
        {
            Name: "Caldo de Pollo",
            Category: "Soup",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Caesar Salad",
            Category: "Salads",
            Prices: [
                {
                    Option: "",
                    Price: "$8.50"
                }
            ],
            Desc: "Fresh romaine tossed with Caesar dressing and croutons served in a tortilla bowl."
        },
        {
            Name: "Small Salad",
            Category: "Salads",
            Prices: [
                {
                    Option: "",
                    Price: "$5.95"
                }
            ],
            Desc: "A dinner sized salad with fresh lettuce and tomatoes."
        },
        {
            Name: "Deluxe Salad",
            Category: "Salads",
            Prices: [
                {
                    Option: "",
                    Price: "$7.45"
                }
            ],
            Desc: "A larger salad with fresh lettuce and tomatoes."
        },
        {
            Name: "Shredded Beef Taco",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$4.95"
                }
            ],
            Desc: "Seasoned shredded beef in a crisp corn tortilla topped with lettuce, tomato and cheese."
        },
        {
            Name: "Shredded Chicken Taco",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: "Made with seasoned shredded chicken."
        },
        {
            Name: "Fish Taco",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: "Large breaded fish fillet in a soft corn tortilla, with cabbage and our special yogurt salsa."
        },
        {
            Name: "Carne Asada Taco",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$5.99"
                }
            ],
            Desc: "Charbroiled steak in a soft corn tortilla, with guacamole."
        },
        {
            Name: "Pollo Asado Taco",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$5.45"
                }
            ],
            Desc: "Charbroiled chicken in a soft corn tortilla, with guacamole."
        },
        {
            Name: "Carnitas",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$5.45"
                }
            ],
            Desc: "Pork chunks in a corn tortilla with guacamole."
        },
        {
            Name: "Beef Taquitos",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$5.99"
                }
            ],
            Desc: "Rolled tacos with shredded beef, topped with lettuce, tomato, cheese and guacamole."
        },
        {
            Name: "Chicken Taquitos",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$5.99"
                }
            ],
            Desc: "Rolled tacos with shredded chicken, topped with lettuce, tomato, cheese and guacamole."
        },
        {
            Name: "Chimitaquitos",
            Category: "Tacos",
            Prices: [
                {
                    Option: "",
                    Price: "$7.99"
                }
            ],
            Desc: "Crisp rolled tacos in a flour tortilla topped with lettuce."
        },
        {
            Name: "Regular Quesadilla",
            Category: "Quesadillas",
            Prices: [
                {
                    Option: "",
                    Price: "$6.99"
                }
            ],
            Desc: "A large grilled flour tortilla filled with melted cheddar cheese."
        },
        {
            Name: "Green Chile Quesadilla",
            Category: "Quesadillas",
            Prices: [
                {
                    Option: "",
                    Price: "$7.99"
                }
            ],
            Desc: "Made with jack cheese and green chile."
        },
        {
            Name: "Ranchero Quesadilla",
            Category: "Quesadillas",
            Prices: [
                {
                    Option: "",
                    Price: "$7.99"
                }
            ],
            Desc: "Grilled green chiles, tomatoes, jalapenos and onions."
        },
        {
            Name: "Ranchero Deluxe with Carne Asada Quesadilla",
            Category: "Quesadillas",
            Prices: [
                {
                    Option: "",
                    Price: "$15.99"
                }
            ],
            Desc: "Grilled green chiles, tomatoes, jalapenos, onions and carne asada."
        },
        {
            Name: "Ranchero Deluxe with Pollo Asada Quesadilla",
            Category: "Quesadillas",
            Prices: [
                {
                    Option: "",
                    Price: "$15.99"
                }
            ],
            Desc: "Grilled green chiles, tomatoes, jalapenos, onions and pollo asada."
        },
        {
            Name: "Torta Sandwich",
            Category: "Sandwiches",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "Charbroiled steak, chicken or ham on a toasted Mexican telera bun with lettuce, tomato and guacamole. Served with a side."
        },
        {
            Name: "Steak Sandwich",
            Category: "Sandwiches",
            Prices: [
                {
                    Option: "",
                    Price: "$13.99"
                }
            ],
            Desc: "Open faced sandwich with charbroiled steak, lettuce and tomato, served with a side."
        },
        {
            Name: "Hamburger",
            Category: "Sandwiches",
            Prices: [
                {
                    Option: "",
                    Price: "$5.49"
                }
            ],
            Desc: ""
        },
        {
            Name: "Cheeseburger",
            Category: "Sandwiches",
            Prices: [
                {
                    Option: "",
                    Price: "$6.59"
                }
            ],
            Desc: ""
        },
        {
            Name: "French Fries",
            Category: "Sandwiches",
            Prices: [
                {
                    Option: "",
                    Price: "$4.67"
                }
            ],
            Desc: ""
        },
        {
            Name: "Carne Asada Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$11.95"
                }
            ],
            Desc: "Charbroiled steak with guacamole."
        },
        {
            Name: "Pollo Asada Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$11.95"
                }
            ],
            Desc: "Charbroiled boneless chicken with guacamole."
        },
        {
            Name: "No-Meat Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$8.45"
                }
            ],
            Desc: "Refried beans, rice, lettuce, cheese, sour cream, salsa fresca and guacamole."
        },
        {
            Name: "Bean Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$7.45"
                }
            ],
            Desc: "Flour tortilla filled with refried beans and topped with cheese and red chile sauce."
        },
        {
            Name: "Chile Con Carne Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.45"
                }
            ],
            Desc: "Chunks of beef cooked in a mild red chile sauce, topped with cheese and more sauce."
        },
        {
            Name: "Combination Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$9.45"
                }
            ],
            Desc: "Refried beans, rice, lettuce, cheese, sour cream, salsa fresca and guacamole."
        },
        {
            Name: "Shredded Beef Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.25"
                }
            ],
            Desc: "Shredded beef seasoned with green peppers, onion and tomato."
        },
        {
            Name: "Shredded Chicken Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.25"
                }
            ],
            Desc: "Shredded chicken seasoned with green peppers, onion and tomato."
        },
        {
            Name: "Machaca Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.45"
                }
            ],
            Desc: "Seasoned shredded beef cooked with egg."
        },
        {
            Name: "Pork Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.25"
                }
            ],
            Desc: "Our special tender pork tips in a flour tortilla, covered with Ranchero sauce."
        },
        {
            Name: "Chorizo with Egg Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.25"
                }
            ],
            Desc: "Spicy Mexican sausage cooked with eggs."
        },
        {
            Name: "Carnitas Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$10.50"
                }
            ],
            Desc: "Pieces of pork cooked in the traditional Mexican style."
        },
        {
            Name: "Beef Chimichanga",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$11.45"
                }
            ],
            Desc: "A fried burrito with shredded beef with ranchero sauce."
        },
        {
            Name: "Chicken Chimichanga",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$11.45"
                }
            ],
            Desc: "A fried burrito with shredded chicken with ranchero sauce."
        },
        {
            Name: "Carne Asada Chimichanga",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$12.45"
                }
            ],
            Desc: "A fried burrito with charbroiled steak with ranchero sauce."
        },
        {
            Name: "Pollo Asada Chimichanga",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$12.45"
                }
            ],
            Desc: "A fried burrito with charbroiled chicken with ranchero sauce."
        },
        {
            Name: "Shrimp Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$11.99"
                }
            ],
            Desc: "Delicious shrimp burrito topped with shredded cabbage and our delicious yogurt sauce!"
        },
        {
            Name: "California Burrito",
            Category: "Burritos Grandes",
            Prices: [
                {
                    Option: "",
                    Price: "$11.95"
                }
            ],
            Desc: ""
        },
        {
            Name: "C1. Taco, Enchilada and Tamale",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.99"
                }
            ],
            Desc: "A crisp beef or chicken taco, cheese enchilada and beef, chicken or chile and cheese tamale."
        },
        {
            Name: "C2. Taco, Enchilada and Chile Relleno",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.99"
                }
            ],
            Desc: "A crisp beef or chicken taco, cheese enchilada and green pepper stuffed with Jack cheese."
        },
        {
            Name: "C3. Taco, Enchilada and Bean Tostada",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.99"
                }
            ],
            Desc: "A crisp beef or chicken taco, cheese enchilada, and bean tostada."
        },
        {
            Name: "C4. Two Cheese Enchiladas",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$13.99"
                }
            ],
            Desc: "Corn tortillas filled with melted cheese and covered with red chile sauce."
        },
        {
            Name: "C5. Two Beef Enchiladas",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.45"
                }
            ],
            Desc: "Corn tortillas filled with shredded beef, and your choice of red chile sauce or ranchero sauce."
        },
        {
            Name: "C5. Two Chicken Enchiladas",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.45"
                }
            ],
            Desc: "Corn tortillas filled with shredded chicken, and your choice of red chile sauce or ranchero sauce."
        },
        {
            Name: "C6. Chile Relleno",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$13.45"
                }
            ],
            Desc: "A mild green chile stuffed with jack cheese and then battered and deep fried."
        },
        {
            Name: "C7. Two Tacos",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$13.99"
                }
            ],
            Desc: "Crisp tortillas filled with your choice of seasoned shredded beef or chicken. Topped with lettuce, tomato, and cheese."
        },
        {
            Name: "C8. Taco and Enchilada",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$14.45"
                }
            ],
            Desc: "A beef or chicken taco and cheese enchilada."
        },
        {
            Name: "C9. Four Taquitos",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Corn tortillas filled with shredded beef or chicken, rolled and fired and topped with lettuce, tomato, cheese and guacamole."
        },
        {
            Name: "C10. Taco and Combination Burrito",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.99"
                }
            ],
            Desc: "A beef or chicken taco and flour tortilla filled with beans and chili con carne."
        },
        {
            Name: "C11. Chile Relleno and Beef Enchilada",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.45"
                }
            ],
            Desc: "A mild green chile stuffed with jack cheese and then battered and fried with shredded beef enchilada."
        },
        {
            Name: "C11. Chile Relleno and Chicken Enchilada",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.45"
                }
            ],
            Desc: "A mild green chile stuffed with jack cheese and then battered and fried with shredded chicken enchilada."
        },
        {
            Name: "C12. Two Chimitaquitos",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$15.00"
                }
            ],
            Desc: "Rolled flour tortilla with shredded protein, fried and topped with lettuce, tomato, cheese and guacamole."
        },
        {
            Name: "C12. Three Chimitaquitos",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$15.25"
                }
            ],
            Desc: "Rolled flour tortilla with shredded protein, fried and topped with lettuce, tomato, cheese and guacamole."
        },
        {
            Name: "C13. Tacos and Carne Asada Enchilada",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$15.99"
                }
            ],
            Desc: "Taco and an enchilada filled with steak topped with guacamole."
        },
        {
            Name: "C13. Taco and Pollo Asado Enchilada",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$16.99"
                }
            ],
            Desc: "Taco and an enchilada filled with chicken topped with guacamole."
        },
        {
            Name: "C14. Tamales",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$11.99"
                }
            ],
            Desc: "Cheese covered in red chili sauce."
        },
        {
            Name: "C14. Two Tamales",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$13.99"
                }
            ],
            Desc: "Cheese covered in red chili sauce."
        },
        {
            Name: "C15 Three sopes your choice of chicken or beef or chorizo",
            Category: "Combination Plates",
            Prices: [
                {
                    Option: "",
                    Price: "$15.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Flying Saucer Tostada",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$7.99"
                }
            ],
            Desc: "A crisp flour tortilla topped with refried beans, your choice of meat, lettuce, tomato, cheese, sour cream and guacamole."
        },
        {
            Name: "Carne Asada Tostada",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$13.95"
                }
            ],
            Desc: "Crisp tortilla topped with refried beans, charbroiled steak, lettuce, cheese, tomato, guacamole and sour cream."
        },
        {
            Name: "Pollo Asada Tostada",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$13.95"
                }
            ],
            Desc: "Crisp tortilla topped with refried beans, charbroiled chicken, lettuce, cheese, tomato, guacamole and sour cream."
        },
        {
            Name: "Tostada Especial",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$10.50"
                }
            ],
            Desc: "Crisp corn tortilla topped with refried beans, chorizo, lettuce, cheese, tomato, guacamole and sour cream, served with Spanish rice."
        },
        {
            Name: "Bean Tostada",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$6.99"
                }
            ],
            Desc: "Crisp corn tortilla topped with refried beans, lettuce, tomato and cheese."
        },
        {
            Name: "Chicken Tostada",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$8.99"
                }
            ],
            Desc: "Crisp corn tortilla topped with refried beans, lettuce, tomato and cheese, and seasoned shredded chicken."
        },
        {
            Name: "Beef Tostada",
            Category: "Tostadas",
            Prices: [
                {
                    Option: "",
                    Price: "$9.99"
                }
            ],
            Desc: "Crisp corn tortilla topped with refried beans, lettuce, tomato and cheese, and seasoned shredded beef."
        },
        {
            Name: "Cheese Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$4.49"
                }
            ],
            Desc: "A corn tortilla filled with melted cheddar cheese and topped with red chile sauce."
        },
        {
            Name: "Shredded Beef Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.25"
                }
            ],
            Desc: "Seasoned shredded beef topped with red chile sauce."
        },
        {
            Name: "Shredded Chicken Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.25"
                }
            ],
            Desc: "Seasoned shredded chicken topped with red chile sauce."
        },
        {
            Name: "Carne Asada Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.99"
                }
            ],
            Desc: "A corn tortilla stuffed with pieces of charbroiled stead, topped with red chile sauce or ranchero sauce."
        },
        {
            Name: "Pollo Asada Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.99"
                }
            ],
            Desc: "A corn tortilla stuffed with pieces of charbroiled chicken and topped with red chile sauce or ranchero sauce."
        },
        {
            Name: "Jack Cheese and Green Chile Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.25"
                }
            ],
            Desc: "Topped with ranchero sauce."
        },
        {
            Name: "Suizas Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.49"
                }
            ],
            Desc: "Shredded chicken and jack cheese, topped with tomatillo sauce and sour cream."
        },
        {
            Name: "Mole Enchilada",
            Category: "Enchiladas",
            Prices: [
                {
                    Option: "",
                    Price: "$5.99"
                }
            ],
            Desc: "Shredded chicken and jack cheese, topped with sesame seeds and sour cream."
        },
        {
            Name: "Beef Tamale",
            Category: "Tamales",
            Prices: [
                {
                    Option: "",
                    Price: "4.45"
                }
            ],
            Desc: "Corn meal dough filled with seasoned beef chunks and topped with red chili sauce."
        },
        {
            Name: "Chicken Tamale",
            Category: "Tamales",
            Prices: [
                {
                    Option: "",
                    Price: "$4.45"
                }
            ],
            Desc: "Corn meal dough filled with seasoned chicken and topped with red chili sauce."
        },
        {
            Name: "Green Chili Jack Cheese Tamale",
            Category: "Tamales",
            Prices: [
                {
                    Option: "",
                    Price: "$4.45"
                }
            ],
            Desc: "Delicious mix of corn meal dough, green chilies and Jack cheese covered with red chili sauce."
        },
        {
            Name: "Chile Relleno",
            Category: "Tamales",
            Prices: [
                {
                    Option: "",
                    Price: "$6.25"
                }
            ],
            Desc: "Fresh green chile stuffed Jack cheese then battered and fried, covered in ranchero sauce."
        },
        {
            Name: "1. Enchiladas Suizas",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Two corn tortillas filled with shredded chicken and Jack cheese, covered in tomatillo sauce and topped with sour cream. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "2. Two Fish Tacos",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$15.99"
                }
            ],
            Desc: "Soft corn tortillas filled with a piece of lightly breaded and fried fish fillet, topped with shredded cabbage and our special yogurt salsa. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "3. Chili Con Carne",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$17.25"
                }
            ],
            Desc: "Tender chunks of beef in a delicious red chile sauce. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "4. Pork Tips in Green Chili Sauce",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$16.99"
                }
            ],
            Desc: "Tender chunks of pork in a mild sauce made with green tomatoes, green peppers and onion. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "5. Steak Fajitas",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Steak cooked in green peppers, onions, tomatoes and jalapenos. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "6. Chicken Fajitas",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Chicken cooked in green peppers, onions, tomatoes and jalapenos. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "7. Carnitas",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Specially prepared pork chunks, served with guacamole. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "8. Chicken Enchiladas in Mole Sauce",
            Category: "Jalisco Specialties",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Two corn tortillas filled with shredded chicken covered in traditional mole sauce, topped with cheese, sesame seeds and sour cream. Served with refried beans and Spanish-style rice."
        },
        {
            Name: "Carne Asada from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$18.99"
                }
            ],
            Desc: "Marinated and charbroiled steak served with refried beans, Spanish rice and guacamole."
        },
        {
            Name: "Pollo Asado from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$18.99"
                }
            ],
            Desc: "Marinated and charbroiled chicken served with refried beans, Spanish rice and guacamole."
        },
        {
            Name: "Enchiladas from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Two enchiladas stuffed and served with refried beans, Spanish rice and guacamole."
        },
        {
            Name: "Carne Asada Burritos from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Two flour tortillas stuffed with charbroiled steak, topped with lettuce, tomatoes, cheese and guacamole. Served with refried beans and Spanish rice."
        },
        {
            Name: "Steak Sandwich from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$14.99"
                }
            ],
            Desc: "Open face sandwich with charbroiled steak, lettuce and tomatoes served with French fries or refried beans."
        },
        {
            Name: "Tacos from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$17.99"
                }
            ],
            Desc: "Two soft corn tortillas filled and served with guacamole, refried beans and Spanish rice."
        },
        {
            Name: "T-Bone Steak from the Charbroiler",
            Category: "From the Charbroiler",
            Prices: [
                {
                    Option: "",
                    Price: "$19.99"
                }
            ],
            Desc: "Charbroiled T-bone steak with small salad, refried beans and Spanish rice."
        },
        {
            Name: "Refried Beans",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Spanish Style Rice",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Sour Cream",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$2.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Guacamole",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Tortillas Corn",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$2.25"
                }
            ],
            Desc: "Three corn tortillas."
        },
        {
            Name: "Tortillas Flour",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$2.25"
                }
            ],
            Desc: "Two corn tortillas."
        },
        {
            Name: "Salsa Fresca pint",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$7.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Beans",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Rice",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Salsa fresca quart",
            Category: "Sides",
            Prices: [
                {
                    Option: "",
                    Price: "$9.45"
                }
            ],
            Desc: ""
        },
        {
            Name: "Child's Beef Taco",
            Category: "Ninos and Seniors",
            Prices: [
                {
                    Option: "",
                    Price: "$8.45"
                }
            ],
            Desc: "A crisp taco with shredded beef, served with refried beans and rice."
        },
        {
            Name: "Child's Chicken Taco",
            Category: "Ninos and Seniors",
            Prices: [
                {
                    Option: "",
                    Price: "$8.45"
                }
            ],
            Desc: "A crisp taco with shredded chicken, with refried beans and rice."
        },
        {
            Name: "Child's 2 Taquitos",
            Category: "Ninos and Seniors",
            Prices: [
                {
                    Option: "",
                    Price: "$8.45"
                }
            ],
            Desc: "Crisp rolled tacos with shredded beef or chicken, with refried beans and rice."
        },
        {
            Name: "Child's Bean Burrito",
            Category: "Ninos and Seniors",
            Prices: [
                {
                    Option: "",
                    Price: "$7.45"
                }
            ],
            Desc: "A soft flour tortilla filled with refried beans and cheese, served with rice."
        },
        {
            Name: "Child's Quesadilla",
            Category: "Ninos and Seniors",
            Prices: [
                {
                    Option: "",
                    Price: "$8.45"
                }
            ],
            Desc: "A lightly grilled flour tortilla filled with melted cheese, with refried beans and rice."
        },
        {
            Name: "Child's Cheese Enchilada",
            Category: "Ninos and Seniors",
            Prices: [
                {
                    Option: "",
                    Price: "$8.45"
                }
            ],
            Desc: "Corn tortilla filled with melted cheese and covered with red chile sauce, with refried beans and rice."
        },
        {
            Name: "Bunuelo",
            Category: "Desserts",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Homemade Flan",
            Category: "Desserts",
            Prices: [
                {
                    Option: "",
                    Price: "$4.25"
                }
            ],
            Desc: ""
        },
        {
            Name: "Bunuelitos",
            Category: "Desserts",
            Prices: [
                {
                    Option: "",
                    Price: "$4.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Churros",
            Category: "Desserts",
            Prices: [
                {
                    Option: "",
                    Price: "$2.99"
                }
            ],
            Desc: ""
        },
        {
            Name: "Beer",
            Category: "Beer (Must be 21 to purchase)",
            Prices: [
                {
                    Option: "",
                    Price: "$3.99"
                }
            ],
            Desc: "Ice cold beer chooses from corona, pacifico, Negra Modelo, bud, xx dark and xx green, bud light, Tecate, Indio, Modelo Clara. Must be 21 to purchase."
        },

        // COMBINATION PLATES
        // Add more items here...
    ];
    // #endregion
    // #region PARTNERS 1
    partners1_pageTitle = `Our Partnerships`;
    partners1_caption = `We collaborate with trusted partners to bring you the finest ingredients and authentic Mexican flavors.`;
    partners1_partners = [
        {
            Partner: "Local Farms",
            Desc: "We source fresh produce and ingredients from local farms, supporting the community and ensuring quality.",
            Link: "https://localfarms.com",
            Img: partnerImg1
        },
        {
            Partner: "Tortilla Factory",
            Desc: "Our tortillas are made fresh daily at a local tortilla factory, guaranteeing the best taste and texture.",
            Link: "https://tortillafactory.com",
            Img: partnerImg1
        },
        {
            Partner: "Tequila Distillery",
            Desc: "We proudly serve tequila from a renowned local distillery, known for its premium quality and craftsmanship.",
            Link: "https://tequiladistillery.com",
            Img: partnerImg1
        },
        {
            Partner: "Local Farms",
            Desc: "We source fresh produce and ingredients from local farms, supporting the community and ensuring quality.",
            Link: "https://localfarms.com",
            Img: partnerImg1
        },
        // Add more partners here...
    ];
    // #endregion
    // #region PORTFOLIO 1
    portfolio1_pageTitle = `Our Portfolio`;
    portfolio1_caption = `Explore our curated collection of Mexican dishes and beverages that showcase our culinary excellence.`;
    portfolio1_projects = [
        {
            Project: "Taco Extravaganza",
            Desc: "Discover our mouthwatering taco creations, each bursting with flavor.",
            Link: "https://portfolio.com/tacoextravaganza",
            Image: portfolioImg1
        },
        {
            Project: "Enchilada Fiesta",
            Desc: "Experience the richness of our enchiladas, a fiesta of authentic Mexican taste.",
            Link: "https://portfolio.com/enchiladafiesta",
            Image: portfolioImg1
        },
        {
            Project: "Margarita Magic",
            Desc: "Sip on the magic of our margaritas, available in a variety of tantalizing flavors.",
            Link: "https://portfolio.com/margaritamagic",
            Image: portfolioImg1
        },
        {
            Project: "Taco Extravaganza",
            Desc: "Discover our mouthwatering taco creations, each bursting with flavor.",
            Link: "https://portfolio.com/tacoextravaganza",
            Image: portfolioImg1
        },
        // Add more projects here...
    ];
    // #endregion
    // #region PRICING 1
    pricing1_pageTitle = `Pricing`;
    pricing1_caption = `Choose from our pricing packages and savor the best Mexican dishes and beverages.`;
    pricing1_prices = [
        {
            Price: "$12.00",
            Desc: "Taco Trio with Guacamole & Chips"
        },
        {
            Price: "$18.00",
            Desc: "Enchilada Combo with a Margarita"
        },
        {
            Price: "$8.00",
            Desc: "Churros & Horchata Duo"
        },
        {
            Price: "$25.00",
            Desc: "Burrito Bowl with Salsa Trio"
        },
        // Add more packages here...
    ];
    // #endregion
    // #region PRODUCTS 1
    products1_pageTitle = `Products`;
    products1_caption = `Enhance your Mexican culinary experience with premium ingredients and merchandise.`;
    products1_products = [
        {
            Name: "Authentic Mexican Spices",
            Desc: "Add depth and flavor to your home-cooked Mexican dishes with our authentic spice blends.",
            Price: "$10.00",
            Image: productsImg1
        },
        {
            Name: "Tortilla Press",
            Desc: "Make your own tortillas at home with our high-quality tortilla press.",
            Price: "$30.00",
            Image: productsImg2
        },
        {
            Name: "Tequila Selection",
            Desc: "Explore our curated selection of tequilas, perfect for sipping or mixing in cocktails.",
            Price: "$40.00",
            Image: productsImg3
        },
        {
            Name: "Authentic Mexican Spices",
            Desc: "Add depth and flavor to your home-cooked Mexican dishes with our authentic spice blends.",
            Price: "$10.00",
            Image: productsImg4
        },
        // Add more products here...
    ];
    // #endregion
    // #region QUOTE 1
    quote1_pageTitle = `Get a Quote`;
    quote1_caption = `Let us create a memorable Mexican dining experience tailored to your preferences.`;
    quote1_heading1 = `Select an Option`;
    quote1_heading2 = `Tell Us Your Preferences`;
    quote1_services = [
        `Taco Bar`,
        `Enchilada Buffet`,
        `Margarita Mixology`,
        `Custom Mexican Feast`,
    ];
    // #endregion
    // #region REVIEWS 1
    reviews1_pageTitle = `Customer Reviews`;
    reviews1_reviews = [
        {
            Reviewer: "Juan Rodriguez",
            Review: "The tacos here are the best I've had outside of Mexico. The flavors are truly authentic.",
            Rating: "5.0",
            Date: "08/10/2023"
        },
        {
            Reviewer: "Maria Gonzalez",
            Review: "I love their enchiladas, and the margaritas are superb. A great place for Mexican cuisine.",
            Rating: "4.8",
            Date: "07/25/2023"
        },
        {
            Reviewer: "Carlos Ramirez",
            Review: "The churros are so crispy and delicious. This place brings back memories of my grandma's cooking.",
            Rating: "4.9",
            Date: "09/05/2023"
        },
        {
            Reviewer: "Elena Martinez",
            Review: "The burrito bowl was fantastic, and the salsas had the perfect amount of heat. Highly recommended!",
            Rating: "5.0",
            Date: "10/01/2023"
        }
    ];
    // #endregion
    // #region SCHEDULE 1
    schedule1_pageTitle = `Schedule`;
    schedule1_heading1 = `Categories`;
    schedule1_heading2 = `Services`;
    schedule1_heading3 = `Select a Date`;
    schedule1_buttonText = `Get Schedule`;
    schedule1_formHeading = `Finalize Your Reservation`;
    schedule1_formCaption = `You've chosen the following time slot for your Mexican dining experience:`;
    // #endregion
    // #region SERVICES 1
    services1_pageTitle = `Our Services`;
    services1_caption = `Experience our top-tier services that bring the flavors of Mexico to your table.`;
    services1_services = [
        {
            Name: "Taco Catering",
            Desc: "Let us cater your event with a taco bar featuring a variety of fillings and toppings.",
            Image: servicesImg1
        },
        {
            Name: "Enchilada Buffet",
            Desc: "Host a feast with our enchilada buffet, complete with a selection of sauces and sides.",
            Image: servicesImg2
        },
        {
            Name: "Margarita Mixology",
            Desc: "Add a fun twist to your event with our margarita mixology service, including a range of flavors.",
            Image: servicesImg3
        },
        {
            Name: "Custom Mexican Feast",
            Desc: "Work with our chefs to create a custom Mexican feast tailored to your preferences and dietary needs.",
            Image: servicesImg1
        },
        // Add more services here...
    ];
    // #endregion
    // #region TEAM 1
    team1_pageTitle = `Meet Our Team`;
    team1_caption = `Meet the passionate chefs and mixologists behind the Mexican flavors that delight your palate.`;
    team1_teamMembers = [
        {
            Name: "Chef Carlos",
            Position: "Head Chef",
            Image: teamImg1
        },
        {
            Name: "Margarita Maria",
            Position: "Mixologist",
            Image: teamImg2
        },
        {
            Name: "Taco Teresa",
            Position: "Taco Specialist",
            Image: teamImg3
        },
        {
            Name: "Chef Carlos",
            Position: "Head Chef",
            Image: teamImg1
        },
        // Add more team members here...
    ];
    // #endregion
}



