import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Divider, Grid, Icon, Image, Layer, RadioButtons, DatePicker, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Spacer, Text, TextArea, TextField, Underline } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Map } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_routes, c_textFont } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'
import { reviews1_pageTitle, reviews1_reviews } from '../../SprintConstants'

export default function Reviews1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    useEffect(() => {
        window.scrollTo(0, 0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}
            <Box width="80vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                <Heading1 text={`${reviews1_pageTitle}`} classes={`${c_accentFont} color1`} />
            </Box>
            <Spacer height={`4vh`} />

            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes={``}>
                            {
                                reviews1_reviews.map((review, i) => {
                                    return (
                                        <div key={i}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                <Text text={`${review.Date}`} fontSize="0.8em" weight="400" color="gray" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Rating}`} fontSize="14vw" weight="600" color="" spacing="-0.1em" lineHeight="" classes={`${c_accentFont}`} />
                                                <Text text={`${review.Review}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Reviewer}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            </Box>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes={``}>
                            {
                                reviews1_reviews.map((review, i) => {
                                    return (
                                        <div key={i}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                <Text text={`${review.Date}`} fontSize="0.8em" weight="400" color="gray" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Rating}`} fontSize="14vw" weight="600" color="" spacing="-0.1em" lineHeight="" classes={`${c_accentFont}`} />
                                                <Text text={`${review.Review}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Reviewer}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            </Box>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr 1fr" gap="1em" classes={``}>
                            {
                                reviews1_reviews.map((review, i) => {
                                    return (
                                        <div key={i}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                <Text text={`${review.Date}`} fontSize="1em" weight="400" color="gray" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Rating}`} fontSize="10vw" weight="600" color="" spacing="-0.1em" lineHeight="" classes={`${c_accentFont}`} />
                                                <Text text={`${review.Review}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Reviewer}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            </Box>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="90vw" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes="center">
                        <Grid orientation="column" template="1fr 1fr 1fr 1fr" gap="1em" classes={``}>
                            {
                                reviews1_reviews.map((review, i) => {
                                    return (
                                        <div key={i}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                <Text text={`${review.Date}`} fontSize="1em" weight="400" color="gray" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Rating}`} fontSize="8vw" weight="600" color="" spacing="-0.1em" lineHeight="" classes={`${c_accentFont}`} />
                                                <Text text={`${review.Review}`} fontSize="1em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                <Text text={`${review.Reviewer}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            </Box>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>
            <Spacer height={`6vh`} />
            {c_footer()}
        </div>
    )
}