import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Text, TextArea, TextField, Underline, Spacer, Divider } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_textFont } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
// 
import img1 from '../../PHOTOS/stock2.jpg'
import { about1_heading1, about1_heading2, about1_pageTitle, about1_text1, about1_text2 } from '../../SprintConstants'

export default function About1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";

    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}
            <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">

                <ResponsiveWrapper>
                    <PhoneScreen>
                        <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                            <Image src={img1} alt="" radius="" classes=" fill" />
                            <Layer position="absolute" top="-10px" left="0" bottom="" right="" classes="">
                                <Text text={`${about1_pageTitle}`} fontSize="12vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`${c_accentFont} color1`} />
                            </Layer>
                        </Box>
                        <Spacer height={`4vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Text text={`${about1_heading1}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                            <Spacer height={`2vh`} />
                            <Text text={`${about1_text1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                        </Box>
                        <Spacer height={`2vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Text text={`${about1_heading2}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                            <Spacer height={`2vh`} />
                            <Text text={`${about1_text2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                        </Box>
                    </PhoneScreen>
                    <TabletScreen>
                        <Box width="" height="50vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                            <Image src={img1} alt="" radius="" classes=" fill" />
                            <Layer position="absolute" top="-10px" left="0" bottom="" right="" classes="">
                                <Text text={`${about1_pageTitle}`} fontSize="12vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`${c_accentFont} color1`} />
                            </Layer>
                        </Box>
                        <Spacer height={`4vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Text text={`${about1_heading1}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                            <Spacer height={`2vh`} />
                            <Text text={`${about1_text1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                        </Box>
                        <Spacer height={`2vh`} />
                        <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                            <Text text={`${about1_heading2}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                            <Spacer height={`2vh`} />
                            <Text text={`${about1_text2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                        </Box>
                    </TabletScreen>
                    <LaptopScreen>
                        <Box width="90vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                            <Box width="" height="55vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                                <Image src={img1} alt="" radius="" classes=" fill" />
                                <Layer position="absolute" top="-10px" left="0" bottom="" right="" classes="">
                                    <Text text={`${about1_pageTitle}`} fontSize="10vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`${c_accentFont} color1`} />
                                </Layer>
                            </Box>
                            <Spacer height={`2vh`} />
                            <Grid orientation="column" template="1fr 1fr" gap="1em" classes="">
                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                    <Text text={`${about1_heading1}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                    <Spacer height={`2vh`} />
                                    <Text text={`${about1_text1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                </Box>
                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                    <Text text={`${about1_heading2}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                    <Spacer height={`2vh`} />
                                    <Text text={`${about1_text2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                </Box>
                            </Grid>
                        </Box>
                    </LaptopScreen>
                    <DesktopScreen>
                        <Box width="80vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                            <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                                <Image src={img1} alt="" radius="" classes=" fill" />
                                <Layer position="absolute" top="-10px" left="0" bottom="" right="" classes="">
                                    <Text text={`${about1_pageTitle}`} fontSize="8vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`${c_accentFont} color1`} />
                                </Layer>
                            </Box>
                            <Spacer height={`2vh`} />
                            <Grid orientation="column" template="1fr 1fr" gap="1em" classes="">
                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                    <Text text={`${about1_heading1}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                    <Spacer height={`2vh`} />
                                    <Text text={`${about1_text1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                </Box>
                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                    <Text text={`${about1_heading2}`} fontSize="1.8em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont} color1`} />
                                    <Spacer height={`2vh`} />
                                    <Text text={`${about1_text2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                </Box>
                            </Grid>
                        </Box>
                    </DesktopScreen>
                </ResponsiveWrapper>

            </Box>
            <Spacer height={`4vh`} />
            {c_footer()}
        </div>
    )
}