// 
// #region IMPORTS
import React from 'react'
import { useNavigate } from "react-router"
import Test from './COMPONENTS/PAGES/Test'
import About1 from './COMPONENTS/PAGES/About1'
import Services1 from './COMPONENTS/PAGES/Services1'
import Contact1 from './COMPONENTS/PAGES/Contact1'
import Gallery1 from './COMPONENTS/PAGES/Gallery1'
import History1 from './COMPONENTS/PAGES/History1'
import Careers1 from './COMPONENTS/PAGES/Careers1'
import Partners1 from './COMPONENTS/PAGES/Partners1'
import Bio1 from './COMPONENTS/PAGES/Bio1'
import Pricing1 from './COMPONENTS/PAGES/Pricing1'
import Features1 from './COMPONENTS/PAGES/Features1'
import Portfolio1 from './COMPONENTS/PAGES/Portfolio1'
import Awards1 from './COMPONENTS/PAGES/Awards1'
import Coupons1 from './COMPONENTS/PAGES/Coupons1'
import MailingList1 from './COMPONENTS/PAGES/MailingList1'
import Team1 from './COMPONENTS/PAGES/Team1'
import Locations1 from './COMPONENTS/PAGES/Locations1'
import Quote1 from './COMPONENTS/PAGES/Quote1'
import Wedding1 from './COMPONENTS/PAGES/EVENTS/Wedding1'
import Blog1 from './COMPONENTS/PAGES/Blog1'
import Schedule1 from './COMPONENTS/PAGES/Schedule1'
import Menu1 from './COMPONENTS/PAGES/Menu1'
import Events1 from './COMPONENTS/PAGES/Events1'
import Reviews1 from './COMPONENTS/PAGES/Reviews1'
import Products1 from './COMPONENTS/PAGES/Products1'
import Home2 from './COMPONENTS/PAGES/Home2'
import Home3 from './COMPONENTS/PAGES/Home3'
import Navigation1 from './COMPONENTS/PAGES/UTILITIES/Navigation1'
import Footer1 from './COMPONENTS/PAGES/UTILITIES/Footer1'
import Home4 from './COMPONENTS/PAGES/Home4'
import Home5 from './COMPONENTS/PAGES/Home5'
import Navigation3 from './COMPONENTS/PAGES/UTILITIES/Navigation3'
// #endregion

// BUSINESS INFO
export const c_businessName = "Jalisco Cafe"
export const c_businessAddress = "1669 Palm Ave San Diego, CA 92154"
export const c_businessPhone = "(619) 575-4955"
export const c_businessEmail = ""
export const c_businessDesc = `¡Bienvenidos a ${c_businessName}! Sumérgete en una experiencia gastronómica auténtica llena de sabores y tradiciones. Nuestros platos te llevarán en un viaje culinario a través de México, donde cada bocado es una celebración de la cocina mexicana. Te invitamos a disfrutar de nuestras deliciosas especialidades, preparadas con ingredientes frescos y auténticos. En ${c_businessName}, encontrarás la perfecta combinación de sabor, calidad y ambiente acogedor. ¡Ven y descubre la verdadera pasión por la comida mexicana con nosotros!`;
export const c_businessType = "Restaurant"

export const c_instagram = ""
export const c_facebook = ""
export const c_yelp = ""
export const c_twitter = ""

// META
export const c_metaTitleSuffix = "| Mexican Food San Diego"

// STYLE
export const c_accentFont = "skran"
export const c_textFont = "roboto"
export const c_buttonColor = "white"

// APP INFO
// {"route" : "description"}

// Home
// About
// Services
// Contact
// Gallery
// History
// Careers
// Partners
// Bio
// Pricing
// Features
// Portfolio
// Awards
// Coupons
// Mailing List
// Team
// Locations
// Quote
// Blog
// Schedule
// Menu
// Events
// Reviews
// Products
// Wedding

export const c_routes = [
    // {
    //     Route: "test",
    //     Title: `Test ${c_metaTitleSuffix}`,
    //     Element: <Test />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Test"
    // },
    {
        Route: "",
        Title: `Home2 ${c_metaTitleSuffix}`,
        Element: <Home2 />,
        Desc: "",
        FooterPlacement: "",
        InHiddenNav: false,
        InTopNav: false,
        Name: "Home"
    },
    // {
    //     Route: "3",
    //     Title: `Home3 ${c_metaTitleSuffix}`,
    //     Element: <Home3 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Home",
    // },
    // {
    //     Route: "4",
    //     Title: `Home4 ${c_metaTitleSuffix}`,
    //     Element: <Home4 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Home",
    // },
    // {
    //     Route: "5",
    //     Title: `Home5 ${c_metaTitleSuffix}`,
    //     Element: <Home5 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Home",
    // },
    {
        Route: "about",
        Title: `About ${c_metaTitleSuffix}`,
        Element: <About1 />,
        Desc: "",
        FooterPlacement: "left",
        InHiddenNav: true,
        InTopNav: false,
        Name: "About"
    },
    // {
    //     Route: "services",
    //     Title: `Services ${c_metaTitleSuffix}`,
    //     Element: <Services1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Services"
    // },
    {
        Route: "gallery",
        Title: `Gallery ${c_metaTitleSuffix}`,
        Element: <Gallery1 />,
        Desc: "",
        FooterPlacement: "left",
        InHiddenNav: true,
        InTopNav: false,
        Name: "Gallery"
    },
    // {
    //     Route: "blog",
    //     Title: `Blog ${c_metaTitleSuffix}`,
    //     Element: <Blog1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Blog"
    // },
    // {
    //     Route: "schedule",
    //     Title: `Schedule ${c_metaTitleSuffix}`,
    //     Element: <Schedule1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Schedule"
    // },
    {
        Route: "menu",
        Title: `Menu ${c_metaTitleSuffix}`,
        Element: <Menu1 />,
        Desc: "",
        FooterPlacement: "right",
        InHiddenNav: true,
        InTopNav: true,
        Name: "Menu"
    },
    // {
    //     Route: "events",
    //     Title: `Events ${c_metaTitleSuffix}`,
    //     Element: <Events1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Events"
    // },
    {
        Route: "locations",
        Title: `Locations ${c_metaTitleSuffix}`,
        Element: <Locations1 />,
        Desc: "",
        FooterPlacement: "right",
        InHiddenNav: true,
        InTopNav: true,
        Name: "Locations"
    },
    {
        Route: "reviews",
        Title: `Reviews ${c_metaTitleSuffix}`,
        Element: <Reviews1 />,
        Desc: "",
        FooterPlacement: "right",
        InHiddenNav: false,
        InTopNav: false,
        Name: "Reviews"
    },
    // {
    //     Route: "mailinglist",
    //     Title: `Mailing List ${c_metaTitleSuffix}`,
    //     Element: <MailingList1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Mailing List"
    // },
    // {
    //     Route: "team",
    //     Title: `Team ${c_metaTitleSuffix}`,
    //     Element: <Team1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Team"
    // },
    // {
    //     Route: "history",
    //     Title: `History ${c_metaTitleSuffix}`,
    //     Element: <History1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "History"
    // },
    // {
    //     Route: "products",
    //     Title: `Products ${c_metaTitleSuffix}`,
    //     Element: <Products1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Products"
    // },
    // {
    //     Route: "careers",
    //     Title: `Careers ${c_metaTitleSuffix}`,
    //     Element: <Careers1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Careers"
    // },
    // {
    //     Route: "partners",
    //     Title: `Partners ${c_metaTitleSuffix}`,
    //     Element: <Partners1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Partners"
    // },
    // {
    //     Route: "bio",
    //     Title: `Bio ${c_metaTitleSuffix}`,
    //     Element: <Bio1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Bio"
    // },
    // {
    //     Route: "pricing",
    //     Title: `Pricing ${c_metaTitleSuffix}`,
    //     Element: <Pricing1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Pricing"
    // },
    // {
    //     Route: "features",
    //     Title: `Features ${c_metaTitleSuffix}`,
    //     Element: <Features1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Features"
    // },
    // {
    //     Route: "portfolio",
    //     Title: `Portfolio ${c_metaTitleSuffix}`,
    //     Element: <Portfolio1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Portfolio"
    // },
    // {
    //     Route: "awards",
    //     Title: `Awards ${c_metaTitleSuffix}`,
    //     Element: <Awards1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Awards"
    // },
    // {
    //     Route: "coupons",
    //     Title: `Coupons ${c_metaTitleSuffix}`,
    //     Element: <Coupons1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Coupons"
    // },
    // {
    //     Route: "quote",
    //     Title: `Quote ${c_metaTitleSuffix}`,
    //     Element: <Quote1 />,
    //     Desc: "",
    //     FooterPlacement: "",
    //     InHiddenNav: false,
    //     InTopNav: false,
    //     Name: "Quote"
    // },
    {
        Route: "contact",
        Title: `Contact Us ${c_metaTitleSuffix}`,
        Element: <Contact1 />,
        Desc: "",
        FooterPlacement: "left",
        InHiddenNav: true,
        InTopNav: false,
        Name: "Contact Us"
    },
    // {
    //     Route: "wedding",
    //     Title: `Wedding ${c_metaTitleSuffix}`,
    //     Element: <Wedding1 />,
    //     Desc: ""
    // }
]
// FOOTER LINKS
export const c_footerLeftRoutes = c_routes.filter(route => route.FooterPlacement === "left");
export const c_footerRightRoutes = c_routes.filter(route => route.FooterPlacement === "right");
// NAV LINKS
export const c_navigationLinks = c_routes.filter(route => route.InHiddenNav === true);
export const c_navigationTopLinks = c_routes.filter(route => route.InTopNav === true);
export const c_navigationActionLink = {
    Route: "contact",
    Name: "Contact Us"
}

export const c_nav = () => {
    return (<Navigation3 />)
}
export const c_footer = () => {
    return <Footer1 />
}

export const c_mainURL = "https://wearehappycode.com"

// CONSTANTS

// FIREBASE
export const firebase_configObj = {
    apiKey: "AIzaSyCX47_4wtf0qCTaL24m0bNmIYn1Zl21Dz4",
    authDomain: "happy-template-v2.firebaseapp.com",
    projectId: "happy-template-v2",
    storageBucket: "happy-template-v2.appspot.com",
    messagingSenderId: "397904119722",
    appId: "1:397904119722:web:9d7f378498234864710dd3",
    measurementId: "G-HJHPCZ7500"
};

// EMAILJS
export const c_serviceID = "bagel_service"
export const c_publicKey = "eaOYb8X6nqSrLTHBS"
export const c_scheduleTemplateID = "user-schedule"
export const c_biz_scheduleTempalteID = "biz_schedule"

