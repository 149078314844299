import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Text, TextArea, TextField, Underline, Spacer } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Map } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessAddress, c_businessEmail, c_businessName, c_businessPhone, c_buttonColor, c_facebook, c_footer, c_instagram, c_nav, c_routes, c_textFont, c_twitter } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { contact1_mapSearchString, contact1_pageTitle } from '../../SprintConstants'

export default function Contact1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    const sendContactForm = () => {

    }
    
    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}
            <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">
                <Spacer height={`4vh`} />

                <ResponsiveWrapper>
                    <PhoneScreen>
                        <Heading1 text={`${contact1_pageTitle}`} classes={`${c_accentFont} color1`} />
                        <Spacer height={`4vh`} />
                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                            <Text text={`${c_businessEmail}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                            <Text text={`${c_businessPhone}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                            <Text text={`${c_businessAddress}`} fontSize="1.4em" weight="500" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont}`} />
                            <Spacer height={`2vh`} />
                            {/* Socials */}
                            <div className='horizontal gap'>
                                <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_instagram}`, "_blank") }} classes="">
                                    <Icon size="1.8em" color="#D0407B">
                                        <BsInstagram />
                                    </Icon>
                                </Button>
                                <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_facebook}`, "_blank") }} classes="">
                                    <Icon size="1.8em" color="#1082E5">
                                        <BsFacebook />
                                    </Icon>
                                </Button>
                                <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_twitter}`, "_blank") }} classes="">
                                    <Icon size="1.8em" color="#1D99E5">
                                        <BsTwitter />
                                    </Icon>
                                </Button>
                            </div>
                            <Spacer height={`2vh`} />
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Map search={`${contact1_mapSearchString}`} />
                            </Box>
                        </Box>

                        {/* FORM */}
                        <Box width="" height="" radius="" paddingV="1em" paddingH="0" classes="vertical gap">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Name`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <TextField placeholder="Full Name" fontSize="1em" radius="" padding="0.8em" id="tbName" classes="" />
                                    </Box>
                                </Border>
                            </Box>

                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Email`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <TextField placeholder="Email" fontSize="1em" radius="" padding="0.8em" id="tbEmail" classes="" />
                                    </Box>
                                </Border>
                            </Box>

                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Subject`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <DropDown options={["Message", "Question", "Concern"]} fontSize="1em" padding="0.8em" radius="" classes="" id="ddSubject" />
                                    </Box>
                                </Border>
                            </Box>

                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Message`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <TextArea placeholder="Type message here..." fontSize="1em" radius="" padding="0.8em" id="taMessage" minHeight="125px" classes="roboto" />
                                    </Box>
                                </Border>
                            </Box>
                            <Button color="" backgroundColor="" radius="" func={() => { sendContactForm() }} classes="bg1">
                                <Box width="100%" height="" radius="" paddingV="0.8em" paddingH="2em" classes="">
                                    <Text text={`Submit`} fontSize="1em" weight="400" color={`${c_buttonColor}`} spacing="" lineHeight="" classes={`${c_textFont} text-center`} />
                                </Box>
                            </Button>
                        </Box>
                    </PhoneScreen>
                    <TabletScreen>
                        <Heading1 text={`${contact1_pageTitle}`} classes={`${c_accentFont} color1`} />
                        <Spacer height={`4vh`} />
                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                            <Text text={`${c_businessEmail}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                            <Text text={`${c_businessPhone}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                            <Text text={`${c_businessAddress}`} fontSize="1.4em" weight="500" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont}`} />
                            <Spacer height={`2vh`} />
                            {/* Socials */}
                            <div className='horizontal gap'>
                                <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_instagram}`, "_blank") }} classes="">
                                    <Icon size="1.8em" color="#D0407B">
                                        <BsInstagram />
                                    </Icon>
                                </Button>
                                <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_facebook}`, "_blank") }} classes="">
                                    <Icon size="1.8em" color="#1082E5">
                                        <BsFacebook />
                                    </Icon>
                                </Button>
                                <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_twitter}`, "_blank") }} classes="">
                                    <Icon size="1.8em" color="#1D99E5">
                                        <BsTwitter />
                                    </Icon>
                                </Button>
                            </div>
                            <Spacer height={`2vh`} />
                            <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                <Map search={`${contact1_mapSearchString}`} />
                            </Box>
                        </Box>
                        <Spacer height={`4vh`} />
                        {/* FORM */}
                        <Box width="fit-content" height="" radius="" paddingV="1em" paddingH="0" classes="vertical gap center">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Name`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="400px" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <TextField placeholder="Full Name" fontSize="1em" radius="" padding="0.8em" id="tbName" classes="" />
                                    </Box>
                                </Border>
                            </Box>

                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Email`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="400px" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <TextField placeholder="Email" fontSize="1em" radius="" padding="0.8em" id="tbEmail" classes="" />
                                    </Box>
                                </Border>
                            </Box>

                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Subject`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="400px" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <DropDown options={["Message", "Question", "Concern"]} fontSize="1em" padding="0.8em" radius="" classes="" id="ddSubject" />
                                    </Box>
                                </Border>
                            </Box>

                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`Message`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                <Border size="1px" color="" radius="">
                                    <Box width="400px" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                        <TextArea placeholder="Type message here..." fontSize="1em" radius="" padding="0.8em" id="taMessage" minHeight="125px" classes="roboto" />
                                    </Box>
                                </Border>
                            </Box>
                            <Button color="" backgroundColor="" radius="" func={() => { sendContactForm() }} classes="">
                                <Box width="400px" height="" radius="" paddingV="0.8em" paddingH="2em" classes="bg1">
                                    <Text text={`Submit`} fontSize="1em" weight="400" color={`${c_buttonColor}`} spacing="" lineHeight="" classes={`${c_accentFont} text-center`} />
                                </Box>
                            </Button>
                        </Box>
                    </TabletScreen>
                    <LaptopScreen>
                        <Heading1 text={`${contact1_pageTitle}`} classes={`${c_accentFont} color1`} />
                        <Spacer height={`4vh`} />
                        <Grid orientation="column" template="1fr 1fr" gap="1em" classes="padding">
                            <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                <Text text={`${c_businessEmail}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                <Text text={`${c_businessPhone}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                <Text text={`${c_businessAddress}`} fontSize="1.4em" weight="500" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont}`} />
                                <Spacer height={`2vh`} />
                                {/* Socials */}
                                <div className='horizontal gap'>
                                    <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_instagram}`, "_blank") }} classes="">
                                        <Icon size="1.8em" color="#D0407B">
                                            <BsInstagram />
                                        </Icon>
                                    </Button>
                                    <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_facebook}`, "_blank") }} classes="">
                                        <Icon size="1.8em" color="#1082E5">
                                            <BsFacebook />
                                        </Icon>
                                    </Button>
                                    <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_twitter}`, "_blank") }} classes="">
                                        <Icon size="1.8em" color="#1D99E5">
                                            <BsTwitter />
                                        </Icon>
                                    </Button>
                                </div>
                                <Spacer height={`2vh`} />
                                <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                    <Map search={`${contact1_mapSearchString}`} />
                                </Box>
                            </Box>

                            {/* FORM */}
                            <Box width="" height="" radius="" paddingV="1em" paddingH="0" classes="vertical gap">
                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                    <Text text={`Name`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                    <Border size="1px" color="" radius="">
                                        <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                            <TextField placeholder="Full Name" fontSize="1em" radius="" padding="0.8em" id="tbName" classes="" />
                                        </Box>
                                    </Border>
                                </Box>

                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                    <Text text={`Email`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                    <Border size="1px" color="" radius="">
                                        <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                            <TextField placeholder="Email" fontSize="1em" radius="" padding="0.8em" id="tbEmail" classes="" />
                                        </Box>
                                    </Border>
                                </Box>

                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                    <Text text={`Subject`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                    <Border size="1px" color="" radius="">
                                        <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                            <DropDown options={["Message", "Question", "Concern"]} fontSize="1em" padding="0.8em" radius="" classes="" id="ddSubject" />
                                        </Box>
                                    </Border>
                                </Box>

                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                    <Text text={`Message`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                    <Border size="1px" color="" radius="">
                                        <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                            <TextArea placeholder="Type message here..." fontSize="1em" radius="" padding="0.8em" id="taMessage" minHeight="125px" classes="roboto" />
                                        </Box>
                                    </Border>
                                </Box>
                                <Button color="" backgroundColor="" radius="" func={() => { sendContactForm() }} classes="bg1">
                                    <Box width="" height="" radius="" paddingV="0.8em" paddingH="" classes="">
                                        <Text text={`Submit`} fontSize="1em" weight="400" color={`${c_buttonColor}`} spacing="" lineHeight="" classes={`${c_accentFont} text-center`} />
                                    </Box>
                                </Button>
                            </Box>
                        </Grid>
                    </LaptopScreen>
                    <DesktopScreen>
                        <Heading1 text={`${contact1_pageTitle}`} classes={`${c_accentFont} text-center color1`} />
                        <Spacer height={`4vh`} />
                        <Box width="80vw" height="" radius="" paddingV="" paddingH="" classes="center">
                            <Grid orientation="column" template="1fr 1fr" gap="1em" classes="padding">
                                <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                    <Text text={`${c_businessEmail}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                    <Text text={`${c_businessPhone}`} fontSize="1.4em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                    <Text text={`${c_businessAddress}`} fontSize="1.4em" weight="500" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont}`} />
                                    <Box width="" height="2vh" radius="" paddingV="" paddingH="" classes="">

                                    </Box>
                                    {/* Socials */}
                                    <Spacer height={`2vh`} />
                                    <div className='horizontal gap'>
                                        <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_instagram}`, "_blank") }} classes="">
                                            <Icon size="1.8em" color="#D0407B">
                                                <BsInstagram />
                                            </Icon>
                                        </Button>
                                        <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_facebook}`, "_blank") }} classes="">
                                            <Icon size="1.8em" color="#1082E5">
                                                <BsFacebook />
                                            </Icon>
                                        </Button>
                                        <Button color="" backgroundColor="" radius="" func={() => { window.open(`${c_twitter}`, "_blank") }} classes="">
                                            <Icon size="1.8em" color="#1D99E5">
                                                <BsTwitter />
                                            </Icon>
                                        </Button>
                                    </div>
                                    <Spacer height={`2vh`} />
                                    <Box width="" height="40vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="">
                                        <Map search={`${contact1_mapSearchString}`} />
                                    </Box>
                                </Box>

                                {/* FORM */}
                                <Box width="" height="" radius="" paddingV="1em" paddingH="0" classes="vertical gap">
                                    <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                        <Text text={`Name`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        <Border size="1px" color="" radius="">
                                            <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                                <TextField placeholder="Full Name" fontSize="1em" radius="" padding="0.8em" id="tbName" classes="" />
                                            </Box>
                                        </Border>
                                    </Box>

                                    <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                        <Text text={`Email`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        <Border size="1px" color="" radius="">
                                            <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                                <TextField placeholder="Email" fontSize="1em" radius="" padding="0.8em" id="tbEmail" classes="" />
                                            </Box>
                                        </Border>
                                    </Box>

                                    <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                        <Text text={`Subject`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        <Border size="1px" color="" radius="">
                                            <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                                <DropDown options={["Message", "Question", "Concern"]} fontSize="1em" padding="0.8em" radius="" classes="" id="ddSubject" />
                                            </Box>
                                        </Border>
                                    </Box>

                                    <Box width="" height="" radius="" paddingV="" paddingH="" classes="">
                                        <Text text={`Message`} fontSize="1.1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                        <Border size="1px" color="" radius="">
                                            <Box width="40vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" classes="">
                                                <TextArea placeholder="Type message here..." fontSize="1em" radius="" padding="0.8em" id="taMessage" minHeight="125px" classes="roboto" />
                                            </Box>
                                        </Border>
                                    </Box>
                                    <Button color="" backgroundColor="" radius="" func={() => { sendContactForm() }} classes="bg1">
                                        <Box width="40vw" height="" radius="" paddingV="0.8em" paddingH="2em" classes="">
                                            <Text text={`Submit`} fontSize="1em" weight="400" color={`${c_buttonColor}`} spacing="" lineHeight="" classes={`${c_accentFont} text-center`} />
                                        </Box>
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </DesktopScreen>
                </ResponsiveWrapper>

            </Box>

            {c_footer()}
        </div>
    )
}