import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Spacer, Text, TextArea, TextField, Underline } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Map } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_routes, c_textFont } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'
import { locations1_locations, locations1_pageTitle } from '../../SprintConstants'

export default function Locations1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}
            <Box width="" height="" radius="" paddingV="1em" paddingH="1em" classes="">

                <Box width="90vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="center">
                    <Heading1 text={`${locations1_pageTitle}`} classes={`${c_accentFont} color1`} />
                    <Spacer height={`4vh`} />
                </Box>

                <Box width="" height="" radius="" paddingV="1em" paddingH="0" classes="">
                    <Grid orientation="column" template="1fr" gap="2em" classes="">
                        {locations1_locations.map((loc, i) => {
                            return (
                                <ResponsiveWrapper>
                                    <PhoneScreen>
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                            <Text text={`${loc.Location}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                            <Text text={`${loc.Hours}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            <Box width="" height="40vh" radius="" paddingV="" paddingH="" classes="">
                                                <Map search={`${loc.Address}`} />
                                            </Box>
                                        </Box>
                                    </PhoneScreen>
                                    <TabletScreen>
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                            <Text text={`${loc.Location}`} fontSize="1.6em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                            <Text text={`${loc.Hours}`} fontSize="1.3em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            <Box width="" height="50vh" radius="" paddingV="" paddingH="" classes="">
                                                <Map search={`${loc.Address}`} />
                                            </Box>
                                        </Box>
                                    </TabletScreen>
                                    <LaptopScreen>
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                            <Text text={`${loc.Location}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                            <Text text={`${loc.Hours}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                            <Box width="" height="60vh" radius="" paddingV="" paddingH="" classes="">
                                                <Map search={`${loc.Address}`} />
                                            </Box>
                                        </Box>
                                    </LaptopScreen>
                                    <DesktopScreen>
                                        <Box width="" height="" radius="" paddingV="" paddingH="" classes="vertical gap">
                                            <div className='horizontal gap'>
                                                <Box width="45%" height="" radius="" paddingV="" paddingH="" classes="">
                                                    <Text text={`${loc.Location}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                    <Text text={`${loc.Hours}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                </Box>
                                                <Box width="100%" height="60vh" radius="" paddingV="" paddingH="" classes="">
                                                    <Map search={`${loc.Address}`} />
                                                </Box>
                                            </div>
                                        </Box>
                                    </DesktopScreen>
                                </ResponsiveWrapper>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
            <Spacer height={`6vh`} />
            {c_footer()}
        </div>
    )
}