import React, { useEffect, useState } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Grid, Icon, Image, Layer, RadioButtons, DatePicker, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Spacer, Text, TextArea, TextField, Underline, Divider } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_routes, c_textFont } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'
// 
import img1 from '../../PHOTOS/stock1.jpg'
import { removeDupes, removeDuplicates } from '../../Global'
import { BsPlus } from 'react-icons/bs'
import { menu1_menu, menu1_pageTitle } from '../../SprintConstants'

export default function Menu1() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname
    // 
    const [categories, setCategories] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [chosenCategory, setChosenCategory] = useState("")

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }


    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
        var tempArr = []
        for (var i in menu1_menu) {
            const item = menu1_menu[i]
            tempArr.push(item.Category)
        }
        var tempArr2 = removeDupes(tempArr)
        setAllCategories(tempArr2)
        setCategories(tempArr2)
    }, [])

    return (
        <div className='roboto fade-in'>
            {c_nav()}
            {/* TOP OF MENU */}
            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="50vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                        <Image src={img1} alt="" radius="" classes=" fill" />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="" height="50vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="v-separate">
                                <div></div>
                                <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Text text={`${menu1_pageTitle}`} fontSize="18vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`text-center ${c_accentFont}`} />
                                </Box>
                                <div></div>
                            </Box>
                        </Layer>
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                        <Image src={img1} alt="" radius="" classes=" fill" />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="v-separate">
                                <div></div>
                                <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Text text={`${menu1_pageTitle}`} fontSize="16vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`text-center ${c_accentFont}`} />
                                </Box>
                                <div></div>
                            </Box>
                        </Layer>
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                        <Image src={img1} alt="" radius="" classes=" fill" />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="" height="60vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="v-separate">
                                <div></div>
                                <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Text text={`${menu1_pageTitle}`} fontSize="14vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`text-center ${c_accentFont}`} />
                                </Box>
                                <div></div>
                            </Box>
                        </Layer>
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="" height="65vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="relative">
                        <Image src={img1} alt="" radius="" classes=" fill" />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="" height="65vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="v-separate">
                                <div></div>
                                <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Text text={`${menu1_pageTitle}`} fontSize="10vw" weight="600" color="white" spacing="-2px" lineHeight="" classes={`text-center ${c_accentFont}`} />
                                </Box>
                                <div></div>
                            </Box>
                        </Layer>
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>

            {/* ACTUAL MENU */}

            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="1em" id="" classes={``}>
                        {
                            categories.map((category, i) => {
                                return (
                                    <div key={i}>
                                        <Button color="" backgroundColor="" radius="" func={() => {
                                            chosenCategory != category ? setChosenCategory(category) : setChosenCategory("")
                                        }} classes={``}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="0.5em" paddingH="0" id="" classes="separate">
                                                <Text text={`${category}`} fontSize="2em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                <Icon size="2.5em" color="">
                                                    <BsPlus />
                                                </Icon>
                                            </Box>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                {
                                                    chosenCategory == category ?
                                                        <div>
                                                            <Grid orientation="column" template="1fr" gap="1.2em" classes={``}>
                                                                {
                                                                    menu1_menu.filter(item => item.Category === category).map((item, j) => {
                                                                        return (
                                                                            <div key={j} className='separate'>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                                                    <Text text={`${item.Name}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                    <Text text={`${item.Desc}`} fontSize="0.8em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                </Box>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="horizontal gap">
                                                                                    {
                                                                                        item.Prices.map((price, k) => {
                                                                                            return (
                                                                                                <div key={k} className='vertical text-center'>
                                                                                                    <Text text={`${price.Option}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                                                                                    <Text text={`${price.Price}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={``} />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Box>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            <Spacer height={`4vh`} />
                                                        </div> : <div></div>
                                                }
                                            </Box>
                                            <Divider size={`1px`} color={``} />
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="1em" id="" classes={``}>
                        {
                            categories.map((category, i) => {
                                return (
                                    <div key={i}>
                                        <Button color="" backgroundColor="" radius="" func={() => {
                                            chosenCategory != category ? setChosenCategory(category) : setChosenCategory("")
                                        }} classes={``}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="0.5em" paddingH="0" id="" classes="separate">
                                                <Text text={`${category}`} fontSize="2em" weight="500" color="" spacing="" lineHeight="" classes={`${c_accentFont}`} />
                                                <Icon size="2.5em" color="">
                                                    <BsPlus />
                                                </Icon>
                                            </Box>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                {
                                                    chosenCategory == category ?
                                                        <div>
                                                            <Grid orientation="column" template="1fr" gap="1.2em" classes={``}>
                                                                {
                                                                    menu1_menu.filter(item => item.Category === category).map((item, j) => {
                                                                        return (
                                                                            <div key={j} className='separate'>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                                                    <Text text={`${item.Name}`} fontSize="1.3em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                    <Text text={`${item.Desc}`} fontSize="0.9em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                </Box>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="horizontal gap">
                                                                                    {
                                                                                        item.Prices.map((price, k) => {
                                                                                            return (
                                                                                                <div key={k} className='vertical text-center'>
                                                                                                    <Text text={`${price.Option}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                                                                                    <Text text={`${price.Price}`} fontSize="1.2em" weight="600" color="" spacing="" lineHeight="" classes={``} />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Box>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            <Spacer height={`4vh`} />
                                                        </div> : <div></div>
                                                }
                                            </Box>
                                            <Divider size={`1px`} color={``} />
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="80vw" height="" radius="" backgroundColor="" paddingV="2em" paddingH="1em" id="" classes="center">
                        {
                            categories.map((category, i) => {
                                return (
                                    <div key={i}>
                                        <Button color="" backgroundColor="" radius="" func={() => {
                                            chosenCategory != category ? setChosenCategory(category) : setChosenCategory("")
                                        }} classes={``}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="0.5em" paddingH="0" id="" classes="separate">
                                                <Text text={`${category}`} fontSize="2.2em" weight="500" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont}`} />
                                                <Icon size="2.5em" color="">
                                                    <BsPlus />
                                                </Icon>
                                            </Box>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                {
                                                    chosenCategory == category ?
                                                        <div>
                                                            <Grid orientation="column" template="1fr" gap="1.2em" classes={``}>
                                                                {
                                                                    menu1_menu.filter(item => item.Category === category).map((item, j) => {
                                                                        return (
                                                                            <div key={j} className='separate'>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                                                    <Text text={`${item.Name}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                    <Text text={`${item.Desc}`} fontSize="1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                </Box>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="horizontal gap">
                                                                                    {
                                                                                        item.Prices.map((price, k) => {
                                                                                            return (
                                                                                                <div key={k} className='vertical text-center'>
                                                                                                    <Text text={`${price.Option}`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                                                                                    <Text text={`${price.Price}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={``} />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Box>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            <Spacer height={`4vh`} />
                                                        </div> : <div></div>
                                                }
                                            </Box>
                                            <Divider size={`1px`} color={``} />
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="60vw" height="" radius="" backgroundColor="" paddingV="2em" paddingH="1em" id="" classes="center">
                        {
                            categories.map((category, i) => {
                                return (
                                    <div key={i}>
                                        <Button color="" backgroundColor="" radius="" func={() => {
                                            chosenCategory != category ? setChosenCategory(category) : setChosenCategory("")
                                        }} classes={``}>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="0.5em" paddingH="0" id="" classes="separate">
                                                <Text text={`${category}`} fontSize="2.2em" weight="500" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont}`} />
                                                <Icon size="2.5em" color="">
                                                    <BsPlus />
                                                </Icon>
                                            </Box>
                                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                {
                                                    chosenCategory == category ?
                                                        <div>
                                                            <Grid orientation="column" template="1fr" gap="1.2em" classes={``}>
                                                                {
                                                                    menu1_menu.filter(item => item.Category === category).map((item, j) => {
                                                                        return (
                                                                            <div key={j} className='separate'>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                                                                    <Text text={`${item.Name}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                    <Text text={`${item.Desc}`} fontSize="1em" weight="400" color="" spacing="" lineHeight="" classes={`${c_textFont}`} />
                                                                                </Box>
                                                                                <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes="horizontal gap">
                                                                                    {
                                                                                        item.Prices.map((price, k) => {
                                                                                            return (
                                                                                                <div key={k} className='vertical text-center'>
                                                                                                    <Text text={`${price.Option}`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                                                                                    <Text text={`${price.Price}`} fontSize="1.4em" weight="600" color="" spacing="" lineHeight="" classes={``} />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Box>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            <Spacer height={`4vh`} />

                                                        </div> : <div></div>
                                                }
                                                <Divider size={`1px`} color={``} />
                                            </Box>
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>
            <Spacer height={`6vh`} />
            {c_footer()}
        </div>
    )
}