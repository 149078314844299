import React, { useEffect } from 'react'
import { Accordion, Border, Box, Button, Checkbox, DropDown, Divider, Grid, Icon, Image, Layer, RadioButtons, DatePicker, ResponsiveWrapper, PhoneScreen, TabletScreen, LaptopScreen, DesktopScreen, Spacer, Text, TextArea, TextField, Underline } from '../Elements'
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Map } from '../Components'
import { FiMenu } from 'react-icons/fi'
import { c_accentFont, c_businessName, c_footer, c_nav, c_routes } from '../../Constants'
import { useLocation, useNavigate } from 'react-router'
import Navigation1 from './UTILITIES/Navigation1'
import Footer1 from './UTILITIES/Footer1'

// 
import img1 from '../../PHOTOS/stock2.jpg'
import { home2_buttonNav1, home2_buttonNav2, home2_buttonText1, home2_buttonText2, home2_caption, home2_heading1, home2_heading2, home2_text1, home2_text2 } from '../../SprintConstants'

export default function Home2() {
    const navigate = useNavigate()
    const location = useLocation()
    const currentLocation = location.pathname

    // function openNav() {
    //     if (window.innerWidth < 600) {
    //         document.querySelector(".nav-body").style.width = "100vw";
    //     } else if (window.innerWidth < 800) {
    //         document.querySelector(".nav-body").style.width = "50vw";
    //     } else if (window.innerWidth < 1000) {
    //         document.querySelector(".nav-body").style.width = "40vw";
    //     } else if (window.innerWidth < 1200) {
    //         document.querySelector(".nav-body").style.width = "35vw";
    //     } else {
    //         document.querySelector(".nav-body").style.width = "20vw";
    //     }
    // }

    // function closeNav() {
    //     document.querySelector(".nav-body").style.width = "0";
    // }

   
    useEffect(() => {
        window.scrollTo(0,0)
        // closeNav()
    }, [])

    return (
        <div className='roboto fade-in'>
            {/* TOP */}
            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="70vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`relative`}>
                        <Image src={img1} alt="" radius="" classes={` fill`} />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`white`}>
                                {c_nav()}
                            </Box>
                            <Spacer height={`18vh`} />
                            <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                                <Text text={`${c_businessName}`} fontSize="16vw" weight="600" color="white" spacing="-1px" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Text text={`${home2_caption}`} fontSize="1.8em" weight="300" color="white" spacing="" lineHeight="" classes={``} />
                            </Box>
                        </Layer>
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="74vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`relative`}>
                        <Image src={img1} alt="" radius="" classes={` fill`} />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`white`}>
                                {c_nav()}
                            </Box>
                            <Spacer height={`20vh`} />
                            <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                                <Text text={`${c_businessName}`} fontSize="14vw" weight="600" color="white" spacing="-1px" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Text text={`${home2_caption}`} fontSize="1.8em" weight="300" color="white" spacing="" lineHeight="" classes={``} />
                            </Box>
                        </Layer>
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="78vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`relative`}>
                        <Image src={img1} alt="" radius="" classes={` fill`} />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`white`}>
                                {c_nav()}
                            </Box>
                            <Spacer height={`24vh`} />
                            <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                                <Text text={`${c_businessName}`} fontSize="12vw" weight="600" color="white" spacing="-1px" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home2_caption}`} fontSize="1.8em" weight="300" color="white" spacing="" lineHeight="" classes={``} />
                            </Box>
                        </Layer>
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="" height="82vh" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`relative`}>
                        <Image src={img1} alt="" radius="" classes={` fill`} />
                        <Layer position="absolute" top="0" left="0" bottom="" right="" classes={``}>
                            <Box width="100vw" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={`white`}>
                               {c_nav()}
                            </Box>
                            <Spacer height={`24vh`} />
                            <Box width="" height="" radius="" backgroundColor="" paddingV="1em" paddingH="" id="" classes={``}>
                                <Text text={`${c_businessName}`} fontSize="10vw" weight="600" color="white" spacing="-1px" lineHeight="1em" classes={`${c_accentFont} color1`} />
                                <Spacer height={`2vh`} />
                                <Text text={`${home2_caption}`} fontSize="1.6em" weight="300" color="white" spacing="" lineHeight="" classes={``} />
                            </Box>
                        </Layer>
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>
            {/* BODY */}
            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="" id="" classes={``}>
                        <Text text={`${home2_heading1}`} fontSize="8vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                        <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Spacer height={`4vh`} />
                            <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav1}`) }} classes={`dark`}>
                                <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1em" id="" classes={``}>
                                    <Text text={`${home2_buttonText1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                </Box>
                            </Button>
                        </Box>
                        <Spacer height={`4vh`} />
                        <Text text={`${home2_text1}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="" id="" classes={``}>
                        <Text text={`${home2_heading1}`} fontSize="6vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                        <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Spacer height={`4vh`} />
                            <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav1}`) }} classes={`dark`}>
                                <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1em" id="" classes={``}>
                                    <Text text={`${home2_buttonText1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                </Box>
                            </Button>
                        </Box>
                        <Spacer height={`4vh`} />
                        <Text text={`${home2_text1}`} fontSize="1.4em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="4em" paddingH="2em" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr" gap="2em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Text text={`${home2_heading1}`} fontSize="5vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                                <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Spacer height={`4vh`} />
                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav1}`) }} classes={`dark`}>
                                        <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1em" id="" classes={``}>
                                            <Text text={`${home2_buttonText1}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                            <Text text={`${home2_text1}`} fontSize="1.6em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                        </Grid>
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="90vw" height="" radius="" backgroundColor="" paddingV="6em" paddingH="2em" id="" classes={`center`}>
                        <Grid orientation="column" template="1fr 1fr" gap="2em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Text text={`${home2_heading1}`} fontSize="3vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                                <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Spacer height={`4vh`} />
                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav1}`) }} classes={`dark`}>
                                        <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1.8em" id="" classes={``}>
                                            <Text text={`${home2_buttonText1}`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                            <Text text={`${home2_text1}`} fontSize="1.6em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                        </Grid>
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>
            <ResponsiveWrapper>
                <PhoneScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="" id="" classes={``}>
                        <Text text={`${home2_heading2}`} fontSize="8vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                        <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Spacer height={`4vh`} />
                            <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav2}`) }} classes={`dark`}>
                                <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1em" id="" classes={``}>
                                    <Text text={`${home2_buttonText2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                </Box>
                            </Button>
                        </Box>
                        <Spacer height={`4vh`} />
                        <Text text={`${home2_text2}`} fontSize="1.2em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                    </Box>
                </PhoneScreen>
                <TabletScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="2em" paddingH="" id="" classes={``}>
                        <Text text={`${home2_heading2}`} fontSize="6vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                        <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                            <Spacer height={`4vh`} />
                            <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav2}`) }} classes={`dark`}>
                                <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1em" id="" classes={``}>
                                    <Text text={`${home2_buttonText2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                </Box>
                            </Button>
                        </Box>
                        <Spacer height={`4vh`} />
                        <Text text={`${home2_text2}`} fontSize="1.4em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                    </Box>
                </TabletScreen>
                <LaptopScreen>
                    <Box width="" height="" radius="" backgroundColor="" paddingV="4em" paddingH="2em" id="" classes={``}>
                        <Grid orientation="column" template="1fr 1fr" gap="2em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Text text={`${home2_heading2}`} fontSize="5vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                                <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Spacer height={`4vh`} />
                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav2}`) }} classes={`dark`}>
                                        <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1em" id="" classes={``}>
                                            <Text text={`${home2_buttonText2}`} fontSize="1em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                            <Text text={`${home2_text2}`} fontSize="1.6em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                        </Grid>
                    </Box>
                </LaptopScreen>
                <DesktopScreen>
                    <Box width="90vw" height="" radius="" backgroundColor="" paddingV="6em" paddingH="2em" id="" classes={`center`}>
                        <Grid orientation="column" template="1fr 1fr" gap="2em" classes={``}>
                            <Box width="" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                <Text text={`${home2_heading2}`} fontSize="3vw" weight="600" color="" spacing="-1px" lineHeight="" classes={`${c_accentFont} color1`} />
                                <Box width="fit-content" height="" radius="" backgroundColor="" paddingV="" paddingH="" id="" classes={``}>
                                    <Spacer height={`4vh`} />
                                    <Button color="" backgroundColor="" radius="" func={() => { navigate(`/${home2_buttonNav2}`) }} classes={`dark`}>
                                        <Box width="" height="" radius="" backgroundColor="" paddingV="0.8em" paddingH="1.8em" id="" classes={``}>
                                            <Text text={`${home2_buttonText2}`} fontSize="1.2em" weight="" color="" spacing="" lineHeight="" classes={``} />
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                            <Text text={`${home2_text2}`} fontSize="1.6em" weight="300" color="" spacing="" lineHeight="" classes={``} />
                        </Grid>
                    </Box>
                </DesktopScreen>
            </ResponsiveWrapper>

            {c_footer()}
        </div>
    )
}